/* eslint-disable no-nested-ternary */
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  useTheme,
} from '@mui/material'
import { Alert } from 'nocapp-types'
import { formatAlertDate } from '../../../utils/alertsFormater'

interface AlertInfoDialogProps {
  open: boolean
  setOpen: Function
  alert: Alert
}

const AlertInfoDialog = ({ open, setOpen, alert }: AlertInfoDialogProps) => {
  const theme = useTheme()

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle style={{ fontWeight: 'bold' }}>
        {alert.message} - {alert.site} ({alert.id_site})
      </DialogTitle>
      <DialogContent>
        <AlertDataContainer>
          <AlertInfoTable>
            <tbody>
              <tr>
                <InfoTitle>Customer</InfoTitle>
                <InfoBody>
                  {alert.project} - {alert.country}
                </InfoBody>
              </tr>
              <tr>
                <InfoTitle>Created at</InfoTitle>
                <InfoBody>{formatAlertDate(alert.created_at_date!)}</InfoBody>
              </tr>
              <tr>
                <InfoTitle>Closed at</InfoTitle>
                <InfoBody>
                  {alert.updated_at_date !== ''
                    ? formatAlertDate(alert.updated_at_date!)
                    : 'Not closed'}
                </InfoBody>
              </tr>
              <tr>
                <InfoTitle>Duration</InfoTitle>
                <InfoBody>{alert.alert_duration}</InfoBody>
              </tr>
              <tr>
                <InfoTitle>Owner</InfoTitle>
                <InfoBody>{alert.owner}</InfoBody>
              </tr>
            </tbody>
          </AlertInfoTable>
          <AlertInfoTable>
            <tbody>
              <tr>
                <InfoTitle>Alert Message</InfoTitle>
                <InfoBody
                  style={{
                    borderRadius: 10,
                    background:
                      alert.severity === 'MAJOR'
                        ? theme.palette.warning.main
                        : alert.severity === 'CRITICAL'
                        ? theme.palette.error.main
                        : theme.palette.warning.light,
                  }}
                >
                  {alert.message!.replaceAll('_', ' ')}
                </InfoBody>
              </tr>
              <tr>
                <InfoTitle>Priority</InfoTitle>
                <InfoBody
                  style={{
                    borderRadius: 10,
                    background:
                      alert.priority === 'MID'
                        ? theme.palette.warning.main
                        : alert.priority === 'HIGH'
                        ? theme.palette.error.main
                        : theme.palette.warning.light,
                  }}
                >
                  {alert.priority}
                </InfoBody>
              </tr>
              <tr>
                <InfoTitle>Shift</InfoTitle>
                <InfoBody>{alert.shift}</InfoBody>
              </tr>
              <tr>
                <InfoTitle>Teams</InfoTitle>
                <InfoBody>{alert.teams!.replaceAll('_', ' ')}</InfoBody>
              </tr>
              <tr>
                <InfoTitle>Count</InfoTitle>
                <InfoBody>{alert.count}</InfoBody>
              </tr>
            </tbody>
          </AlertInfoTable>
        </AlertDataContainer>
        {alert ? (
          <>
            <h2>Alert info</h2>
            <AlertDataContainer>
              <AlertInfoTable>
                <tbody>
                  <tr>
                    <InfoTitle>Possible Root-Cause</InfoTitle>
                    <InfoBody style={{ textAlign: 'justify' }}>
                      {alert.prc}
                    </InfoBody>
                  </tr>
                  <tr>
                    <InfoTitle>Troubleshooting</InfoTitle>
                    <InfoBody style={{ textAlign: 'justify' }}>
                      {alert.troubles}
                    </InfoBody>
                  </tr>
                </tbody>
              </AlertInfoTable>
              <AlertInfoTable>
                <tbody>
                  <tr>
                    <InfoTitle>NOC (proposal)</InfoTitle>
                    <InfoBody style={{ textAlign: 'justify' }}>
                      {alert.proposal}
                    </InfoBody>
                  </tr>
                  <tr>
                    <InfoTitle>Field</InfoTitle>
                    <InfoBody style={{ textAlign: 'justify' }}>
                      {alert.field}
                    </InfoBody>
                  </tr>
                </tbody>
              </AlertInfoTable>
            </AlertDataContainer>
          </>
        ) : (
          <Box
            style={{
              marginTop: 20,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

const AlertDataContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
const AlertInfoTable = styled('table')(({ theme }) => ({
  width: '50%',
  marginRight: 10,
  fontSize: 14,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
const InfoTitle = styled('td')(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: 6,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 5,
}))
const InfoBody = styled('td')(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  background: theme.palette.background.default,
  borderRadius: 6,
  padding: 5,
}))

export default AlertInfoDialog
