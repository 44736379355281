import { styled, useTheme } from '@mui/material'
import { Visit } from 'nocapp-types'

interface TodayVisitsListItemProps {
  visit: Visit
}

const TodayVisitsListItem = ({ visit }: TodayVisitsListItemProps) => {
  const theme = useTheme()

  const getVisitIdColor = (value: Visit) => {
    if (value.actual_end_datetime) {
      return theme.palette.success.light
    }
    if (value.actual_start_datetime) {
      return theme.palette.warning.light
    }
    return ''
  }

  const getTime = (string: string) => {
    const date = new Date(string)
    return `${date.getHours()}:${date.getMinutes()}`
  }

  return (
    <Container>
      <VisitIdContainer>
        <VisitIdAndActivityBox>
          <VisitId
            style={{
              backgroundColor: getVisitIdColor(visit),
            }}
          >
            {visit.id}
          </VisitId>
          <VisitWork>{visit.work}</VisitWork>
        </VisitIdAndActivityBox>
        <SiteInfoBox>
          {visit.site_id} - {visit.country} - Requested by: {visit.name}
        </SiteInfoBox>
      </VisitIdContainer>
      <VisitHour>Scheduled at {getTime(visit.start_datetime)}</VisitHour>
    </Container>
  )
}

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: 60,
  padding: 10,
  borderBottom: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 14,
  fontWeight: 'bold',
}))
const VisitIdContainer = styled('div')(() => ({
  flex: 3,
  display: 'flex',
  flexDirection: 'column',
}))
const VisitHour = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))
const VisitIdAndActivityBox = styled('div')({
  flex: 3,
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})
const SiteInfoBox = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'flex-end',
  color: theme.palette.text.secondary,
  fontSize: 12,
  marginTop: 5,
}))
const VisitId = styled('span')(({ theme }) => ({
  minWidth: 30,
  minHeight: 30,
  padding: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 100,
  background: theme.palette.background.default,
}))
const VisitWork = styled('span')({
  fontWeight: 'bold',
  fontSize: 16,
})

export default TodayVisitsListItem
