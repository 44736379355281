import { useAuth0 } from '@auth0/auth0-react'
import { Route, Routes } from 'react-router-dom'
import LoadingPage from '../../components/PageComponents/LoadingPage'
import NoNocappAccessPage from '../../components/PageComponents/NoNocappAccessPage'
import { useNocAppAuth } from '../auth/NocAppAuthContext'
import ProtectedRoute from './ProtectedRoute'
import { views } from './views'

const Router = () => {
  const { hasNocappAccess, loadingNocappUser } = useNocAppAuth()
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

  if (loadingNocappUser || isLoading) {
    return <LoadingPage />
  }

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`,
      },
    })
  }

  return (
    <Routes>
      {hasNocappAccess ? (
        views.map((route, i) =>
          route.access.length === 0 ? (
            <Route key={i} path={route.path} element={route.page} />
          ) : (
            <Route
              key={i}
              path={route.path}
              element={
                <ProtectedRoute restrictions={route.access} Page={route.page} />
              }
            />
          )
        )
      ) : (
        <Route path="*" element={<NoNocappAccessPage />} />
      )}
    </Routes>
  )
}

export default Router
