import { styled } from '@mui/material'
import { Link } from 'react-router-dom'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import GridCard from '../../../components/PageComponents/GridCard'
import PageComponent from '../../../components/PageComponents/PageComponent'

const IncidentsMenuPage = () => {
  // Menu options
  const options = [
    {
      name: 'Create incident',
      link: '/incidents/create',
    },
    {
      name: 'Join incident',
      link: '/incidents/join',
    },
    {
      name: 'View closed incidents',
      link: '/incidents/list',
    },
  ]

  return (
    <PageComponent
      title="Incidents menu"
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        gap: GLOBAL_PAGE_GAP,
      }}
    >
      {options.map((value, i) => (
        <Link key={i} to={value.link}>
          <SelectorContainer hoveranimation>{value.name}</SelectorContainer>
        </Link>
      ))}
    </PageComponent>
  )
}

const SelectorContainer = styled(GridCard)(({ theme }) => ({
  width: 300,
  height: 150,
  backgroundColor: theme.palette.background.paper,
  padding: 20,
  borderRadius: 20,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 20,
}))

export default IncidentsMenuPage
