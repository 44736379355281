import { useAuth0 } from '@auth0/auth0-react'
import { NocAppAuthProps, NocAppAuthUser } from 'nocapp-types'
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ApiHandler from '../api/apiHandler'
import { CONTACTS_DB } from '../api/databases'

const NocAppAuthContext = createContext<Partial<NocAppAuthProps>>({})

export const NocAppAuthProvider: FC = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth0()
  const [nocappUser, setNocappUser] =
    useState<NocAppAuthProps['nocappUser']>(null)
  const [hasNocappAccess, setHasNocappAccess] = useState(false)
  const [loadingNocappUser, setLoadingNocappUser] = useState(false)

  const removeUserHandler = async () => {
    localStorage.removeItem('user')
    setNocappUser(null)
  }

  const getUserHandler = useCallback(async () => {
    setLoadingNocappUser(true)
    const apiHandler = new ApiHandler()

    // Getting nocapp info of user
    if (isAuthenticated && !isLoading) {
      const nocappUserInfo = await apiHandler.getObject(
        `SELECT * FROM ${CONTACTS_DB} WHERE email = '${user!.email}'`
      )

      // Handling if user has access to nocapp
      if (nocappUserInfo !== '') {
        setHasNocappAccess(true)
      } else {
        setHasNocappAccess(false)
      }
      setNocappUser(nocappUserInfo)
    } else {
      setNocappUser(null)
    }
    setLoadingNocappUser(false)
  }, [isAuthenticated, isLoading, user])

  useEffect(() => {
    getUserHandler()
  }, [getUserHandler])

  const setUserHandler = (passedUser: NocAppAuthUser) => {
    setNocappUser(passedUser)
    localStorage.setItem('user', JSON.stringify(passedUser))
  }

  const nocappContextValuesMemo = useMemo(
    () => ({
      nocappUser,
      setUserHandler,
      removeUserHandler,
      hasNocappAccess,
      loadingNocappUser,
    }),
    [nocappUser, hasNocappAccess, loadingNocappUser]
  )

  return (
    <NocAppAuthContext.Provider value={nocappContextValuesMemo}>
      {children}
    </NocAppAuthContext.Provider>
  )
}

export const useNocAppAuth = () => useContext(NocAppAuthContext)
