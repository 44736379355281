import { useEffect } from 'react'

export const useKeyPress = (callback: Function, keyCode: number) =>
  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === keyCode) {
        callback()
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [callback, keyCode])
