import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createTheme, PaletteMode } from '@mui/material'
import { getDesignTokens } from './theme'
import { NocAppAuthProvider } from './services/auth/NocAppAuthContext'
import Router from './services/router/Router'
import ThemeModeContext from './theme/ThemeMode'

const App = () => {
  const [themeMode, setThemeMode] = useState<PaletteMode>('dark')

  // Theme mode handlers
  const getDevicePreferedThemeMode = useCallback(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)')
    if (darkThemeMq.matches) {
      setThemeMode('dark')
    } else {
      setThemeMode('light')
    }
  }, [])

  useEffect(() => {
    getDevicePreferedThemeMode()
  }, [getDevicePreferedThemeMode])

  const changeThemeMode = () =>
    setThemeMode((prev) => (prev === 'light' ? 'dark' : 'light'))

  const theme = useMemo(
    () => createTheme(getDesignTokens(themeMode)),
    [themeMode]
  )

  const themeModeMemo = useMemo(
    () => ({ mode: themeMode, changeThemeMode }),
    [themeMode]
  )

  // Let people know in what enviroment is nocapp running
  // eslint-disable-next-line no-console
  console.log(`You're running nocapp in ${process.env.NODE_ENV}`)

  return (
    <ThemeModeContext.Provider value={themeModeMemo}>
      <ThemeProvider theme={theme}>
        <NocAppAuthProvider>
          <CssBaseline />
          <Router />
        </NocAppAuthProvider>
      </ThemeProvider>
    </ThemeModeContext.Provider>
  )
}

export default App
