/* eslint-disable no-restricted-syntax */
import { Button, InputAdornment, styled, TextField } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { User } from 'nocapp-types'
import ContactInfo from '../../components/Contacts/ContactsInfo'
import { getContactsQuery } from './ContactsPage.queries'
import ApiHandler from '../../services/api/apiHandler'
import PageComponent from '../../components/PageComponents/PageComponent'
import AddNewUser from '../../components/Contacts/AddNewUser'
import { useNocAppAuth } from '../../services/auth/NocAppAuthContext'

const ContactsPage = () => {
  const { nocappUser } = useNocAppAuth()
  const [contacts, setContacts] = useState<User[]>([])
  const [filteredContacts, setFilteredContacts] = useState<User[]>([])
  const [searchInput, setSearchInput] = useState('')
  const [showContactInfo, setShowContactInfo] = useState(false)
  const [selectedContact, setSelectedContact] = useState<User | null>(null)
  const [loading, setLoading] = useState(false)
  const [showAddNewUser, setShowAddNewUser] = useState(false)

  const getData = useCallback(async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Contacts query
    const contactsQuery = getContactsQuery()
    // Get Contacts
    const data = await apiHandler.getList(contactsQuery)
    setContacts(data)
    setFilteredContacts(data)
    setLoading(false)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  // Search filter handler
  const filterContactsHandler = useCallback(
    (value: string) => {
      function compareObjects(o1: any, o2: any) {
        let k = ''
        for (k in o1) if (o1[k] !== o2[k]) return false
        for (k in o2) if (o1[k] !== o2[k]) return false
        return true
      }

      function itemExists(haystack: any, needle: any) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < haystack.length; i++)
          if (compareObjects(haystack[i], needle)) return true
        return false
      }

      const results: User[] = []
      contacts.forEach((contact) => {
        for (const key in contact) {
          if (Object.prototype.hasOwnProperty.call(contact, key)) {
            // @ts-ignore
            const element: string =
              // @ts-ignore
              contact[key] == null ? '' : contact[key].toString()
            const formatedElement = element
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll('_', '')
              .replaceAll('-', '')
            const formatedValue = value
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll('_', '')
              .replaceAll('-', '')
            if (formatedElement.search(formatedValue) !== -1) {
              if (!itemExists(results, contact)) {
                results.push(contact)
              }
            }
          }
        }
      })
      setSearchInput(value)
      setFilteredContacts(results)
    },
    [contacts]
  )

  return (
    <PageComponent
      title="Contacts/Users"
      loading={loading}
      scrollable
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ContactsContainer>
        <FilterContainer>
          <SearchBarContainer>
            <TextField
              style={{ width: '100%' }}
              size="small"
              label="Search contact"
              placeholder="ID, name, country, dates, etc."
              value={searchInput}
              onChange={(e) => filterContactsHandler(e.target.value)}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: 10,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </SearchBarContainer>
          {nocappUser?.profile === 'admin' && (
            <Button size="large" onClick={() => setShowAddNewUser(true)}>
              Add new user
            </Button>
          )}
        </FilterContainer>
      </ContactsContainer>
      <ContactsContainer
        style={{
          marginTop: 20,
        }}
      >
        <ContactsTableContainer>
          <ContactsTable>
            <div style={{ display: 'table-row' }}>
              <ContactsTh>Name</ContactsTh>
              <ContactsTh>Company</ContactsTh>
              <ContactsTh>Country</ContactsTh>
              <ContactsTh>Position</ContactsTh>
              <ContactsTh>Escalation</ContactsTh>
              <ContactsTh>Mobile</ContactsTh>
              <ContactsTh>Comments</ContactsTh>
            </div>
            {filteredContacts.map((contact, i) => (
              <ContactsTr
                key={i}
                onClick={() => {
                  setShowContactInfo(true)
                  setSelectedContact(contact)
                }}
              >
                <ContactsTd>{contact.name}</ContactsTd>
                <ContactsTd>{contact.company}</ContactsTd>
                <ContactsTd>{contact.country}</ContactsTd>
                <ContactsTd>{contact.position}</ContactsTd>
                <ContactsTd>{contact.escalation}</ContactsTd>
                <ContactsTd>{contact.mobile}</ContactsTd>
                <ContactsTd>{contact.commentaries}</ContactsTd>
              </ContactsTr>
            ))}
          </ContactsTable>
        </ContactsTableContainer>
      </ContactsContainer>
      {selectedContact && (
        <ContactInfo
          getList={getData}
          open={showContactInfo}
          setOpen={setShowContactInfo}
          contact={selectedContact!}
        />
      )}
      <AddNewUser
        open={showAddNewUser}
        setOpen={setShowAddNewUser}
        getUsers={getData}
      />
    </PageComponent>
  )
}

const ContactsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const ContactsBoxContainers = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  width: '60%',
  borderRadius: 15,
  padding: 10,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down(780)]: {
    width: '100%',
  },
}))
const FilterContainer = styled(ContactsBoxContainers)(({ theme }) => ({
  marginTop: 15,
  paddingTop: 15,
  paddingBottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 10,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down(780)]: {
    width: '100%',
  },
}))
const SearchBarContainer = styled('div')({
  flex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
})
const ContactsTableContainer = styled(ContactsBoxContainers)(({ theme }) => ({
  marginTop: -20,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down(780)]: {
    width: '100%',
  },
}))
const ContactsTable = styled('div')(({ theme }) => ({
  display: 'table',
  textAlign: 'center',
  width: '100%',
  borderCollapse: 'collapse',
  fontSize: 14,
  [theme.breakpoints.down(475)]: {
    fontSize: 12,
  },
}))
const ContactsTr = styled('div')(({ theme }) => ({
  display: 'table-row',
  cursor: 'pointer',
  ':hover': {
    background: theme.palette.background.default,
  },
}))
const ContactsTd = styled('div')(({ theme }) => ({
  maxWidth: 120,
  display: 'table-cell',
  verticalAlign: 'center',
  padding: 8,
  borderBottom: `1px solid ${theme.palette.background.paper}`,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 90,
    padding: 5,
    textAlign: 'center',
  },
}))
const ContactsTh = styled(ContactsTd)(({ theme }) => ({
  fontWeight: 'bold',
  borderBottom: 0,
  [theme.breakpoints.down('sm')]: {
    padding: 6,
  },
}))

export default ContactsPage
