import { IconButton, InputAdornment, styled, TextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  Search as SearchIcon,
  CloseRounded as CloseIcon,
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { SITES_DB } from '../../../services/api/databases'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import { GLOBAL_PAGE_GAP } from '../../PageComponents/constants'
import { useKeyPress } from '../../../hooks/useKeyPress'
import ApiHandler from '../../../services/api/apiHandler'

interface SitesSearchResult {
  caban_id: string
  country: string
  customer: string
  modem_id: number
  site_name: string
}

const SitesSearchBar = () => {
  const { nocappUser } = useNocAppAuth()
  const [searchValue, setSearchValue] = useState('')
  const searchInput = useRef<HTMLInputElement>(null)
  const [sitesResults, setSitesResults] = useState<SitesSearchResult[]>([])
  const [showResults, setShowResults] = useState(false)
  const [canSearchSite, setCanSearchSite] = useState(true)

  useKeyPress(() => {
    setShowResults(false)
    setSearchValue('')
    setSitesResults([])
  }, 27)

  useEffect(() => {
    if (nocappUser!.profile !== 'admin' && nocappUser!.profile !== 'noc') {
      setCanSearchSite(false)
    }
  }, [nocappUser])

  const handleSearchResult = async (value: string) => {
    setSearchValue(value)
    const apiHandler = new ApiHandler()
    const searchQuery = `SELECT
      site_name,
      caban_id,
      modem_id,
      country
    FROM ${SITES_DB} WHERE
      REPLACE(REPLACE(REPLACE(LOWER(caban_id), '-', ''), '_', ''), ' ', '') LIKE '%${value
        .toLowerCase()
        .replaceAll('-', '')
        .replaceAll('_', '')
        .replaceAll(' ', '')}%'
      OR REPLACE(REPLACE(REPLACE(LOWER(modem_id::TEXT), '-', ''), '_', ''), ' ', '') LIKE '%${value
        .toLowerCase()
        .replaceAll('-', '')
        .replaceAll('_', '')
        .replaceAll(' ', '')}%'
      OR REPLACE(REPLACE(REPLACE(LOWER(country), '-', ''), '_', ''), ' ', '') LIKE '%${value
        .toLowerCase()
        .replaceAll('-', '')
        .replaceAll('_', '')
        .replaceAll(' ', '')}%'
      OR REPLACE(REPLACE(REPLACE(LOWER(site_name), '-', ''), '_', ''), ' ', '') LIKE '%${value
        .toLowerCase()
        .replaceAll('-', '')
        .replaceAll('_', '')
        .replaceAll(' ', '')}%'
    LIMIT 35`
    const res = await apiHandler.getList(searchQuery)
    setSitesResults(res)
    setShowResults(true)
  }

  if (!canSearchSite) {
    return <div />
  }

  return (
    <SearchContainer>
      <TextField
        fullWidth
        ref={searchInput}
        value={searchValue}
        onChange={(e) => handleSearchResult(e.target.value)}
        label="Search for a site"
        placeholder="Device ID, Caban ID, name, dates, etc."
        InputProps={{
          disableUnderline: true,
          style: {
            borderRadius: 10,
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearchValue('')
                  setShowResults(false)
                  setSitesResults([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showResults && (
        <SearchResults>
          {sitesResults.map((site, i) => (
            <SearchResultRow to={`/sites/info/${site.caban_id}`} key={i}>
              <SearchResultItem>{site.site_name}</SearchResultItem>
              <SearchResultItem>{site.caban_id}</SearchResultItem>
              <SearchResultItem>{site.modem_id}</SearchResultItem>
              <SearchResultItem>{site.country}</SearchResultItem>
              <SearchResultItem>{site.customer}</SearchResultItem>
            </SearchResultRow>
          ))}
        </SearchResults>
      )}
    </SearchContainer>
  )
}

const SearchContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
const SearchResults = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  maxHeight: 300,
  top: 60,
  borderRadius: 15,
  padding: 10,
  position: 'absolute',
  zIndex: 5,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '-1px 6px 15px -7px rgba(0,0,0,0.47)',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
}))
const SearchResultRow = styled(Link)(({ theme }) => ({
  width: '100%',
  height: 50,
  paddingLeft: 15,
  backgroundColor: theme.palette.background.default,
  gap: GLOBAL_PAGE_GAP,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  flexShrink: 0,
  marginBottom: 5,
  borderRadius: 10,
  fontSize: 14,
  transition: '100ms',
  ':hover': {
    backgroundColor: '#e6e6e6',
  },
}))
const SearchResultItem = styled('span')(({ theme }) => ({
  padding: 5,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 5,
}))

export default SitesSearchBar
