import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Visit } from 'nocapp-types'
import { VISITS_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'
import SnackBar, { SnackBarSeverity } from '../../SnackBar'
import { MONTHS_LIST } from '../../../utils/datesHandle'

interface VisitInfoProps {
  open: boolean
  setOpen: Function
  visitInfo: Visit
  updateList: Function
  canEdit: boolean
}

const VisitInfo = ({
  open,
  setOpen,
  visitInfo,
  updateList,
  canEdit,
}: VisitInfoProps) => {
  const [comments, setComments] = useState('')
  const [disableStart, setDisableStart] = useState(false)
  const [disableEnd, setDisableEnd] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [customStartDate, setCustomStartDate] = useState('')
  const [customEndDate, setCustomEndDate] = useState('')
  const [loadingStartDate, setLoadingStartDate] = useState(false)
  const [loadingEndDate, setLoadingEndDate] = useState(false)
  const [loadingComments, setLoadingComments] = useState(false)
  const [loadingDeleteVisit, setLoadingDeleteVisit] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  useEffect(() => {
    setComments(visitInfo.comments ? visitInfo.comments : '')
    setDisableStart(!!visitInfo.actual_start_datetime)
    setDisableEnd(!!visitInfo.actual_end_datetime)
    setStartDate(
      visitInfo.actual_start_datetime ? visitInfo.actual_start_datetime : ''
    )
    setCustomStartDate(
      visitInfo.actual_start_datetime ? visitInfo.actual_start_datetime : ''
    )
    setEndDate(
      visitInfo.actual_end_datetime ? visitInfo.actual_end_datetime : ''
    )
    setCustomEndDate(
      visitInfo.actual_end_datetime ? visitInfo.actual_end_datetime : ''
    )
  }, [visitInfo, canEdit])

  const setStartDateHandler = async () => {
    setLoadingStartDate(true)
    const date = new Date()
    let start: string
    if (customStartDate) {
      start = customStartDate
    } else {
      start = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    }
    const apiHandler = new ApiHandler()
    //
    await apiHandler.postItem(
      `UPDATE ${VISITS_DB} SET actual_start_datetime='${start}' WHERE id = '${visitInfo.id}'`
    )
    //
    setStartDate(start)
    updateList()
    setDisableStart(true)
    setLoadingStartDate(false)
  }

  const setEndDateHandler = async () => {
    setLoadingEndDate(true)
    const date = new Date()
    let end: string
    if (customEndDate) {
      end = customEndDate
    } else {
      end = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    }
    const apiHandler = new ApiHandler()
    //
    await apiHandler.postItem(
      `UPDATE ${VISITS_DB} SET actual_end_datetime='${end}' WHERE id = '${visitInfo.id}'`
    )
    //
    setEndDate(end)
    updateList()
    setDisableEnd(true)
    setLoadingEndDate(false)
  }

  const addCommentsHandler = async () => {
    setLoadingComments(true)
    const apiHandler = new ApiHandler()
    //
    await apiHandler.postItem(
      `UPDATE ${VISITS_DB} SET comments='${comments}' WHERE id = '${visitInfo.id}'`
    )
    //
    updateList()
    setLoadingComments(false)
  }

  const getMonthDayYearDateWithTime = (string: string) => {
    const date = new Date(string)
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${date.getHours()}:${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }`
  }

  const deleteVisitHandle = async () => {
    setLoadingDeleteVisit(true)
    try {
      const apiHandler = new ApiHandler()
      //
      await apiHandler.postItem(
        `DELETE FROM ${VISITS_DB} WHERE id = '${visitInfo.id}'`
      )
      setSeverity('success')
      setMessage('Visit has been deleted')
      setOpenSnackbar(true)
      setOpen(false)
      updateList()
    } catch (error) {
      setSeverity('error')
      setMessage("There's been an error deleting this visit")
      setOpenSnackbar(true)
    }
    setLoadingDeleteVisit(false)
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={() => setOpen(false)}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 28 }}>
        {visitInfo.site_id} Visit Info.
      </DialogTitle>
      <DialogContent>
        {canEdit && (
          <>
            <SetDatesContainer>
              <SetDateButtonContainer>
                <TextField
                  type="datetime-local"
                  label="Set start date (Optional)"
                  value={customStartDate}
                  onChange={(e) => setCustomStartDate(e.target.value)}
                  style={{ width: '95%' }}
                  focused
                />
                <SetDateButton
                  variant="contained"
                  loading={loadingStartDate}
                  onClick={setStartDateHandler}
                  disabled={
                    customStartDate === startDate ? disableStart : false
                  }
                >
                  Set start date
                </SetDateButton>
                {startDate !== '' && (
                  <ActualDates>
                    {getMonthDayYearDateWithTime(startDate)}
                  </ActualDates>
                )}
              </SetDateButtonContainer>
              <SetDateButtonContainer>
                <TextField
                  type="datetime-local"
                  label="Set end date (Optional)"
                  value={customEndDate}
                  onChange={(e) => setCustomEndDate(e.target.value)}
                  style={{ width: '95%' }}
                  focused
                />
                <SetDateButton
                  variant="contained"
                  loading={loadingEndDate}
                  onClick={setEndDateHandler}
                  disabled={customEndDate === endDate ? disableEnd : false}
                >
                  Set end date
                </SetDateButton>
                {endDate !== '' && (
                  <ActualDates>
                    {getMonthDayYearDateWithTime(endDate)}
                  </ActualDates>
                )}
              </SetDateButtonContainer>
            </SetDatesContainer>
            <TextField
              style={{ width: '100%' }}
              value={comments}
              label="Comments"
              placeholder="Add comments here"
              onChange={(e) => setComments(e.target.value)}
              multiline
              rows={5}
            />
            <LoadingButton
              style={{ width: '100%', marginTop: 10, marginBottom: 20 }}
              variant="contained"
              loading={loadingComments}
              onClick={addCommentsHandler}
            >
              Add comments
            </LoadingButton>
          </>
        )}
        <InfoTitle>Visit info.</InfoTitle>
        <InfoTable>
          <Tr>
            <Td>
              Caban ID:{' '}
              <span style={{ fontWeight: 'bold' }}>{visitInfo.site_id}</span>
            </Td>
            <Td>
              Country:{' '}
              <span style={{ fontWeight: 'bold' }}>{visitInfo.country}</span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Site name:{' '}
              <span style={{ fontWeight: 'bold' }}>{visitInfo.site_name}</span>
            </Td>
            <Td>
              Modem ID:{' '}
              <span style={{ fontWeight: 'bold' }}>{visitInfo.modem_id}</span>
            </Td>
          </Tr>
          <br />
          <Tr>
            <Td>
              Company:{' '}
              <span style={{ fontWeight: 'bold' }}>{visitInfo.company}</span>
            </Td>
            <Td>
              Supervisor:{' '}
              <span style={{ fontWeight: 'bold' }}>{visitInfo.supervisor}</span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Supervisor phone:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {visitInfo.supervisor_phone}
              </span>
            </Td>
            <Td>
              Supervisor ID:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {visitInfo.supervisor_id}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Username:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {visitInfo.form_from_user}
              </span>
            </Td>
            <Td>
              Form from:{' '}
              <span style={{ fontWeight: 'bold' }}>{visitInfo.name}</span>
            </Td>
          </Tr>
          <br />
          <Tr>
            <Td>Work type:</Td>
            <Td>
              <span style={{ fontWeight: 'bold' }}>{visitInfo.work}</span>
            </Td>
          </Tr>
          <Tr>
            <Td>Activity details:</Td>
            <Td>
              <span style={{ fontWeight: 'bold' }}>
                {visitInfo.activity_detail}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Planned start date:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {getMonthDayYearDateWithTime(visitInfo.start_datetime)}
              </span>
            </Td>
            <Td>
              Planned end date:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {getMonthDayYearDateWithTime(visitInfo.end_datetime)}
              </span>
            </Td>
          </Tr>
          <br />
          {visitInfo.technician_1 !== '' && (
            <Tr>
              <Td>
                Technician:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {visitInfo.technician_1}
                </span>
              </Td>
              <Td>
                Technician ID:{' '}
                <span style={{ fontWeight: 'bold' }}>{visitInfo.id_1}</span>
              </Td>
            </Tr>
          )}
          {visitInfo.technician_2 !== '' && (
            <Tr>
              <Td>
                Technician:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {visitInfo.technician_2}
                </span>
              </Td>
              <Td>
                Technician ID:{' '}
                <span style={{ fontWeight: 'bold' }}>{visitInfo.id_2}</span>
              </Td>
            </Tr>
          )}
          {visitInfo.technician_3 !== '' && (
            <Tr>
              <Td>
                Technician:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {visitInfo.technician_3}
                </span>
              </Td>
              <Td>
                Technician ID:{' '}
                <span style={{ fontWeight: 'bold' }}>{visitInfo.id_3}</span>
              </Td>
            </Tr>
          )}
          {visitInfo.technician_4 !== '' && (
            <Tr>
              <Td>
                Technician:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {visitInfo.technician_4}
                </span>
              </Td>
              <Td>
                Technician ID:{' '}
                <span style={{ fontWeight: 'bold' }}>{visitInfo.id_4}</span>
              </Td>
            </Tr>
          )}
          {visitInfo.technician_5 !== '' && (
            <Tr>
              <Td>
                Technician:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {visitInfo.technician_5}
                </span>
              </Td>
              <Td>
                Technician ID:{' '}
                <span style={{ fontWeight: 'bold' }}>{visitInfo.id_5}</span>
              </Td>
            </Tr>
          )}
        </InfoTable>
        {canEdit && (
          <LoadingButton
            color="error"
            style={{
              width: '100%',
              marginTop: 30,
            }}
            loading={loadingDeleteVisit}
            variant="contained"
            onClick={deleteVisitHandle}
          >
            Delete visit
          </LoadingButton>
        )}
      </DialogContent>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </Dialog>
  )
}

const SetDatesContainer = styled('div')({
  width: '100%',
  display: 'flex',
  marginBottom: 10,
})
const SetDateButtonContainer = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})
const SetDateButton = styled(LoadingButton)({
  width: '95%',
})
const ActualDates = styled('span')(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
}))
const InfoTitle = styled('span')({
  fontSize: 22,
  marginTop: 10,
  fontWeight: 'bold',
})
const InfoTable = styled('div')({
  display: 'table',
  marginTop: 15,
  width: '100%',
})
const Tr = styled('div')({
  display: 'table-row',
})
const Td = styled('div')(({ theme }) => ({
  maxWidth: 150,
  display: 'table-cell',
  textAlign: 'center',
  padding: 5,
  borderBottom: `1px solid ${theme.palette.background.paper}`,
}))

export default VisitInfo
