import { BrowserRouter, useNavigate } from 'react-router-dom'
import React, { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react'
import { Chart, registerables } from 'chart.js'
import reportWebVitals from './reportWebVitals'
import App from './App'
import './theme/css/global.css'

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate()

  /**
   * Callback function for when the redirect fires from Auth0.
   * @param {object} appState
   */
  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname)
  }

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_NOCAPP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_NOCAPP_AUTH0_CLIENT_ID!}
        audience={process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE!}
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
Chart.register(...registerables)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
