import { styled } from '@mui/material'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/InfoRounded'
import MessageIcon from '@mui/icons-material/MessageRounded'
import { Incident, Site } from 'nocapp-types'
import MessagesContainer from '../MessagesContainer'
import IncidentInfo from '../IncidentInfo'

interface IncidentsBodyProps {
  incident: Incident
  site: Site
  updateData: Function
}

const IncidentsBody = ({ incident, site, updateData }: IncidentsBodyProps) => {
  const [displayIndex, setDisplayIndex] = useState(0)

  const sidebarOptions = [
    {
      title: 'Incident info',
      onClick: () => setDisplayIndex(0),
      icon: <InfoIcon />,
    },
    {
      title: 'Messages',
      onClick: () => setDisplayIndex(1),
      icon: <MessageIcon />,
    },
  ]

  return (
    <Container>
      <SideBarContainer>
        {sidebarOptions
          .slice(0, incident.start_datetime ? 3 : 1)
          .map((option, i) => (
            <SidebarItem key={i} onClick={option.onClick}>
              <IconContainer>{option.icon}</IconContainer>
              <SidebarText>{option.title}</SidebarText>
            </SidebarItem>
          ))}
      </SideBarContainer>
      <DisplayContainer>
        {/* Show incident info if page is in messages */}
        {displayIndex === 1 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
            }}
          >
            <span>
              Incident #{incident.id} / {incident.caban_id}
            </span>
          </div>
        )}
        {displayIndex === 0 && (
          <IncidentInfo
            incident={incident}
            site={site}
            updateData={updateData}
          />
        )}
        {displayIndex === 1 && <MessagesContainer incident={incident} />}
        {displayIndex === 2 && <div>Activity</div>}
      </DisplayContainer>
    </Container>
  )
}

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  padding: 20,
  borderRadius: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  },
}))
const SideBarContainer = styled('div')(({ theme }) => ({
  width: 300,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 60,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
const DisplayContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  paddingLeft: 10,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '90%',
  },
}))
const SidebarItem = styled('div')(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  padding: 20,
  borderRadius: 20,
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    borderRadius: 100,
    width: 50,
    height: 50,
    justifyContent: 'center',
  },
}))
const IconContainer = styled('div')(({ theme }) => ({
  marginRight: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
  },
}))
const SidebarText = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export default IncidentsBody
