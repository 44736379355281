import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
  styled,
  CircularProgress,
} from '@mui/material'
import { ReactElement, useContext, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CloseRounded as CloseIcon,
  MenuRounded as MenuIcon,
  LogoutRounded as LogoutIcon,
} from '@mui/icons-material'
import { useAuth0 } from '@auth0/auth0-react'
import { ThemeModeSwitch } from '../SwitchComponent'
import { GLOBAL_PAGE_GAP } from '../constants'
import logo from '../../../assets/img/logo.svg'
import ThemeModeContext from '../../../theme/ThemeMode'
import { useKeyPress } from '../../../hooks/useKeyPress'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'

interface NavBarProps {
  loading?: boolean
  centerComponent?: ReactElement
  title?: string
  // For sidebar
  menuForSidebar?: boolean
  openSidebar?: boolean
  setOpenSidebar?: Function
}

const USER_CHIP_SIZE = 45

const NavBar = ({
  loading,
  centerComponent,
  title = 'NOC App',
  menuForSidebar,
  openSidebar,
  setOpenSidebar,
}: NavBarProps) => {
  const { removeUserHandler, nocappUser } = useNocAppAuth()
  const { logout, user } = useAuth0()
  const { mode, changeThemeMode } = useContext(ThemeModeContext)
  //
  const [openMenu, setOpenMenu] = useState(false)
  const userChip = useRef<HTMLImageElement>(null)

  const signOut = () => {
    removeUserHandler!()
    logout({ returnTo: window.location.origin })
  }

  useKeyPress(() => {
    setOpenMenu(false)
  }, 27)

  return (
    <Container>
      <AppName>
        {menuForSidebar && (
          <IconButton onClick={() => setOpenSidebar!(!openSidebar)}>
            {openSidebar ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        )}
        <Link to="/" style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <CabanLogo src={logo} alt="" />
          <NocAppName>{title}</NocAppName>
        </Link>
      </AppName>
      <CenterComponent>{centerComponent}</CenterComponent>
      <RightComponent>
        {loading ? (
          <CircularProgress style={{ width: 30, height: 30 }} />
        ) : (
          <>
            <UserChip
              src={user?.picture ? user!.picture : ''}
              ref={userChip}
              onClick={() => setOpenMenu(true)}
            />
            <Popper
              open={openMenu}
              anchorEl={userChip.current}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <UserCardContainer>
                    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                      <UserCardBox>
                        <UserNameEmailContainer>
                          <UserName>{nocappUser?.name}</UserName>
                          <UserEmail>{user?.email}</UserEmail>
                        </UserNameEmailContainer>
                        <BottomCardContainer>
                          <ThemeModeSwitch
                            checked={mode === 'dark'}
                            onChange={() => changeThemeMode!()}
                          />
                          <Button
                            color="error"
                            onClick={signOut}
                            startIcon={<LogoutIcon />}
                          >
                            Log out
                          </Button>
                        </BottomCardContainer>
                      </UserCardBox>
                    </ClickAwayListener>
                  </UserCardContainer>
                </Grow>
              )}
            </Popper>
          </>
        )}
      </RightComponent>
    </Container>
  )
}

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 2fr 1fr',
  background: theme.palette.background.paper,
  padding: 10,
  paddingLeft: 20,
  paddingRight: 20,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}))
const AppName = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 18,
  cursor: 'pointer',
  gap: 5,
}))
const CabanLogo = styled('img')({
  width: 35,
  height: 35,
})
const NocAppName = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
const CenterComponent = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    gridRowStart: 2,
  },
}))
const RightComponent = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))
const UserChip = styled('img')(({ theme }) => ({
  width: USER_CHIP_SIZE,
  height: USER_CHIP_SIZE,
  borderRadius: 100,
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  fontWeight: 'bolder',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const UserCardContainer = styled(Paper)(({ theme }) => ({
  width: 340,
  height: 'auto',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 5px 30px rgba(32, 52, 89, 0.08)',
  marginRight: GLOBAL_PAGE_GAP,
  marginTop: 5,
  padding: 15,
  borderRadius: 15,
  display: 'flex',
}))
const UserCardBox = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: GLOBAL_PAGE_GAP,
})
const UserNameEmailContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 10,
  borderRadius: 10,
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  fontWeight: 'bolder',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const UserName = styled('span')({
  fontSize: 18,
})
const UserEmail = styled('span')(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.text.secondary,
}))
const BottomCardContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export default NavBar
