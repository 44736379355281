import { styled } from '@mui/material'
import { CountryCount } from 'nocapp-types'
import { Link } from 'react-router-dom'

interface CountryDivisionsProps {
  countries: CountryCount[]
}

const CountryDivisions = ({ countries }: CountryDivisionsProps) => {
  return (
    <Container to="countries">
      <Title>
        <span>Count by country</span>
      </Title>
      <CountsListContainer>
        {countries.map((country, i) => (
          <CountryCountainer key={i}>
            <span>{country.country}</span>
            <span style={{ fontWeight: 'bold' }}>{country.count} sites</span>
          </CountryCountainer>
        ))}
      </CountsListContainer>
    </Container>
  )
}

const Container = styled(Link)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})
const Title = styled('div')(() => ({
  height: 40,
  fontWeight: 'bold',
  fontSize: 20,
  display: 'flex',
  alignItems: 'center',
}))
const CountsListContainer = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  paddingTop: 15,
  flexDirection: 'column',
  overflowY: 'auto',
}))
const CountryCountainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 50,
  flexShrink: 0,
  flexGrow: 0,
  backgroundColor: theme.palette.background.default,
  borderRadius: 15,
  marginBottom: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 10,
  paddingRight: 10,
}))

export default CountryDivisions
