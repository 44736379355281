import {
  InputAdornment,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { ChangeEvent, useEffect, useState } from 'react'
import { Visit } from 'nocapp-types'
import VisitInfo from '../VisitInfo'
import VisitTableRow from '../VisitTableRow'
import TableListPaginationActions from '../../PageComponents/TableListPaginationActions'

interface VisitsListProps {
  visits: Visit[]
  limit: number | 'all'
  setLimit: Function
  updateList: Function
  canEdit: boolean
}

const VisitsList = ({
  visits,
  limit,
  setLimit,
  updateList,
  canEdit,
}: VisitsListProps) => {
  const theme = useTheme()
  const [filteredVisits, setFilteredVisits] = useState<Visit[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [search, setSearch] = useState('')
  const [selectedVisit, setSelectedVisit] = useState<Visit | null>(null)
  const [openVisit, setOpenVisit] = useState(false)

  useEffect(() => {
    setFilteredVisits(visits)
  }, [visits, limit])

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const searchVisitHandler = (value: string) => {
    function compareObjects(o1: any, o2: any) {
      let k = ''
      for (k in o1) if (o1[k] !== o2[k]) return false
      for (k in o2) if (o1[k] !== o2[k]) return false
      return true
    }

    function itemExists(haystack: any, needle: any) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < haystack.length; i++)
        if (compareObjects(haystack[i], needle)) return true
      return false
    }

    const results: Visit[] = []
    visits.forEach((visit) => {
      for (const key in visit) {
        if (Object.prototype.hasOwnProperty.call(visit, key)) {
          // @ts-ignore
          const element: string =
            // @ts-ignore
            visit[key] == null ? '' : visit[key].toString()
          const formatedElement = element
            .toLowerCase()
            .replaceAll(' ', '')
            .replaceAll('_', '')
            .replaceAll('-', '')
          const formatedValue = value
            .toLowerCase()
            .replaceAll(' ', '')
            .replaceAll('_', '')
            .replaceAll('-', '')
          if (formatedElement.search(formatedValue) !== -1) {
            if (!itemExists(results, visit)) {
              results.push(visit)
            }
          }
        }
      }
    })
    setSearch(value)
    setFilteredVisits(results)
  }

  return (
    <Container>
      <SearchContainer>
        <TextField
          fullWidth
          label="Search visit"
          value={search}
          onChange={(e) => searchVisitHandler(e.target.value)}
          placeholder="ID, name, country, dates, etc."
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: {
              borderRadius: 10,
            },
          }}
        />
      </SearchContainer>
      <TableBoxContainer>
        <TableContainer
          style={{ width: '100%', height: '100%', display: 'flex' }}
        >
          <Table
            stickyHeader
            style={{ width: '100%', height: '100%' }}
            size="small"
          >
            <TableHead>
              <TableRow
                style={{ backgroundColor: theme.palette.background.paper }}
              >
                <TableTitleCell>Visit ID</TableTitleCell>
                <TableTitleCell>Site</TableTitleCell>
                <TableTitleCell>Country</TableTitleCell>
                <TableTitleCell>Customer</TableTitleCell>
                <TableTitleCell>Work</TableTitleCell>
                <TableTitleCell>Work details</TableTitleCell>
                <TableTitleCell>Planned start time</TableTitleCell>
                {canEdit && (
                  <>
                    <TableTitleCell />
                    <TableTitleCell />
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredVisits.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredVisits
              ).map((visit, i) => (
                <VisitTableRow
                  key={i}
                  onClick={() => {
                    setSelectedVisit(visit)
                    setOpenVisit(true)
                  }}
                  canEdit={canEdit}
                  visit={visit}
                  updateList={updateList}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableBoxContainer>
      <BottomTableContainer>
        <TextField
          select
          style={{ width: 120 }}
          label="Set a limit"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        >
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value="all">All visits</MenuItem>
        </TextField>
        <TablePagination
          rowsPerPageOptions={[15, 30, 100]}
          component="div"
          count={filteredVisits.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, index) => setPage(index)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TableListPaginationActions}
        />
      </BottomTableContainer>
      {selectedVisit && (
        <VisitInfo
          visitInfo={selectedVisit}
          open={openVisit}
          setOpen={setOpenVisit}
          canEdit={canEdit}
          updateList={updateList}
        />
      )}
    </Container>
  )
}

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})
const SearchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const TableBoxContainer = styled('div')({
  flex: 1,
  display: 'flex',
  overflow: 'auto',
  paddingTop: 10,
})
const BottomTableContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const TableTitleCell = styled(TableCell)({
  backgroundColor: 'inherit',
  fontWeight: 'bold',
})

export default VisitsList
