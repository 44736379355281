import {
  DEVICES_INFO_DB,
  INCIDENTS_DB,
  SITES_DB,
  SITES_DISTANCES_DB,
} from '../../../services/api/databases'

export const getIncidentInfoQuery = (incidentId: string) =>
  `SELECT * FROM ${INCIDENTS_DB} WHERE id = '${incidentId}'`

export const getSiteInfoQuery = (site: string) =>
  `SELECT
    *
  FROM
    ${SITES_DB}
  LEFT JOIN
    ${SITES_DISTANCES_DB}
  ON
    ${SITES_DB}.caban_id = ${SITES_DISTANCES_DB}.site_id
  LEFT JOIN
    ${DEVICES_INFO_DB}
  ON
    ${SITES_DB}.caban_id = ${DEVICES_INFO_DB}.site_id
  WHERE
    caban_id = '${site}'
   `
