import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material'
import { FC, FormEvent, useCallback, useEffect, useState } from 'react'
import ApiHandler from '../../../services/api/apiHandler'
import { FormButton, FormContainer, FormSection } from '../../FormComponents'
import SnackBar, { SnackBarSeverity } from '../../SnackBar'
import {
  createNewUserQuery,
  getAvailableCountriesAndProfiles,
} from './AddNewUser.queries'

interface Props {
  open: boolean
  setOpen: Function
  getUsers: Function
}

const AddNewUser: FC<Props> = ({ open, setOpen, getUsers }) => {
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState<any[]>([])
  const [profiles, setProfiles] = useState<any[]>([])
  // Form controllers
  const [country, setCountry] = useState('')
  const [profile, setProfile] = useState('')
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const getDataHandle = useCallback(async () => {
    const apiHandler = new ApiHandler()
    const queries = getAvailableCountriesAndProfiles()
    const [countriesData, profilesData] = await Promise.all([
      apiHandler.getList(queries.countries),
      apiHandler.getList(queries.profiles),
    ])
    setProfiles(profilesData)
    setCountries(countriesData)
  }, [])

  useEffect(() => {
    getDataHandle()
  }, [getDataHandle])

  const createUserHandle = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    const apiHandler = new ApiHandler()

    // Transform data
    const body = apiHandler.transformFormDataToJson(event.currentTarget)
    // eslint-disable-next-line prefer-destructuring
    body.username = `${body.email.split('@')[0]}'`
    body.password = '1234'

    // Getting query
    const query = createNewUserQuery(body)

    try {
      await apiHandler.postItem(query)
      getUsers()
      setOpen(false)
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 25 }}>
        Add new user
      </DialogTitle>
      <DialogContent>
        <FormContainer onSubmit={createUserHandle}>
          <FormSection
            title="User info"
            sections={[
              [
                <TextField
                  label="Email"
                  required
                  fullWidth
                  placeholder="example@email.com"
                  name="email"
                  type="email"
                />,
                <TextField
                  label="Name"
                  fullWidth
                  placeholder="Name that will be displayed to user"
                  name="name"
                />,
              ],
              [
                <TextField
                  label="Profile"
                  required
                  fullWidth
                  placeholder="Type of user"
                  name="profile"
                  value={profile}
                  onChange={(e) => setProfile(e.target.value)}
                  select
                >
                  <MenuItem value="" />
                  {profiles.map((value, i) => (
                    <MenuItem value={value.profile} key={i}>
                      {value.profile}
                    </MenuItem>
                  ))}
                </TextField>,
                <TextField
                  label="Country"
                  required
                  fullWidth
                  placeholder="User country"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  select
                >
                  <MenuItem value="" />
                  {countries.map((value, i) => (
                    <MenuItem value={value.country} key={i}>
                      {value.country}
                    </MenuItem>
                  ))}
                </TextField>,
              ],
              [
                <TextField
                  label="Company"
                  fullWidth
                  name="company"
                  type="text"
                />,
                <TextField
                  label="Position"
                  fullWidth
                  name="position"
                  type="text"
                />,
              ],
              [
                <TextField
                  label="Mobile"
                  fullWidth
                  name="mobile"
                  type="text"
                />,
              ],
            ]}
          />
          <FormButton loading={loading} title="Add new user" />
        </FormContainer>
        <SnackBar
          open={openSnackbar}
          message={message}
          setOpen={setOpenSnackbar}
          severity={severity}
        />
      </DialogContent>
    </Dialog>
  )
}

export default AddNewUser
