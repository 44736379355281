/* eslint-disable no-console */
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'
import { useState } from 'react'
import { Incident, Site } from 'nocapp-types'
import { INCIDENTS_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import { MONTHS_LIST } from '../../../utils/datesHandle'

interface IncidentInfoProps {
  incident: Incident
  site: Site
  updateData: Function
}

const IncidentInfo = ({ site, incident, updateData }: IncidentInfoProps) => {
  const { nocappUser } = useNocAppAuth()
  const [startLoadingIncident, setStartLoadingIncident] = useState(false)
  const [endLoadingIncident, setEndLoadingIncident] = useState(false)
  const [reopenLoading, setReopenLoading] = useState(false)

  const startIncidentsHandler = async () => {
    setStartLoadingIncident(true)
    const date = new Date()
    const startDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    const apiHandler = new ApiHandler()
    try {
      await apiHandler.postItem(
        `UPDATE ${INCIDENTS_DB} SET start_datetime = '${startDate}' WHERE id = '${
          incident!.id
        }'`
      )
      await updateData()
    } catch (error) {
      console.log(error)
    }
    setStartLoadingIncident(false)
  }

  const endIncidentsHandler = async () => {
    setEndLoadingIncident(true)
    const date = new Date()
    const endDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    const apiHandler = new ApiHandler()
    try {
      await apiHandler.postItem(
        `UPDATE ${INCIDENTS_DB} SET end_datetime = '${endDate}' WHERE id = '${
          incident!.id
        }'`
      )
      await updateData()
    } catch (error) {
      console.log(error)
    }
    setEndLoadingIncident(false)
  }

  const reopenIncidentsHandler = async () => {
    setReopenLoading(true)
    const apiHandler = new ApiHandler()
    try {
      await apiHandler.postItem(
        `UPDATE ${INCIDENTS_DB} SET end_datetime = NULL WHERE id = '${
          incident!.id
        }'`
      )
      await updateData()
    } catch (error) {
      console.log(error)
    }
    setReopenLoading(false)
  }

  const getMonthDayYearDateWithTime = (string: string) => {
    const date = new Date(string)
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${date.getHours()}:${
      (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    }`
  }

  return (
    <Container>
      <InfoContainer>
        <InfoBox>
          <InfoTitle>
            {incident.id}. {incident.type}
          </InfoTitle>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <InfoSubTitle>Customer: {incident.customer}</InfoSubTitle>-
            <InfoSubTitle>Assigned to: {incident.assigned_to}</InfoSubTitle>
          </div>
          <p>{incident.description}</p>
        </InfoBox>
        <InfoBox>
          <InfoTitle>
            {site.site_name} | {site.caban_id} | {site.country}
          </InfoTitle>
          <InfoSubTitle>
            Modem ID: {site.modem_id}, Distance to site: {site.distance} km,
            Time to site: {site.travel_time}
          </InfoSubTitle>
          <table style={{ textAlign: 'center', width: 300, marginTop: 20 }}>
            <tbody>
              <tr>
                <td>Panels</td>
                <th>{site.solar_panels}</th>
                <td>Structures</td>
                <th>{site.solar_structures}</th>
              </tr>
              <tr>
                <td>Packs</td>
                <th>{site.packs}</th>
                <td>MPPTs</td>
                <th>{site.mppts}</th>
              </tr>
            </tbody>
          </table>
        </InfoBox>
      </InfoContainer>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <StartEndIncidentContainer style={{ fontSize: 15 }}>
          {incident.start_datetime && (
            <span>
              Start timestamp:{' '}
              {getMonthDayYearDateWithTime(incident.start_datetime)}
            </span>
          )}
          {incident.end_datetime && (
            <span>
              End timestamp:{' '}
              {getMonthDayYearDateWithTime(incident.end_datetime)}
            </span>
          )}
        </StartEndIncidentContainer>
        <StartEndIncidentContainer>
          {incident.start_datetime ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 10,
              }}
            >
              <LoadingButton
                fullWidth
                variant="contained"
                color="error"
                disabled={!!incident.end_datetime}
                loading={endLoadingIncident}
                onClick={endIncidentsHandler}
              >
                End incident
              </LoadingButton>
              {(nocappUser?.profile === 'admin' ||
                nocappUser?.profile === 'noc') && (
                <LoadingButton
                  fullWidth
                  variant="contained"
                  disabled={!incident.end_datetime}
                  loading={reopenLoading}
                  onClick={reopenIncidentsHandler}
                >
                  Reopen incident
                </LoadingButton>
              )}
            </div>
          ) : (
            <LoadingButton
              fullWidth
              variant="contained"
              loading={startLoadingIncident}
              onClick={startIncidentsHandler}
            >
              Start incident
            </LoadingButton>
          )}
        </StartEndIncidentContainer>
      </div>
    </Container>
  )
}

const Container = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const StartEndIncidentContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  fontWeight: 'bold',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const InfoContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
}))
const InfoBox = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 20,
  borderRadius: 20,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  overflowY: 'auto',
  marginBottom: 15,
}))
const InfoTitle = styled('span')(() => ({
  fontSize: 22,
  fontWeight: 'bold',
}))
const InfoSubTitle = styled('span')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}))

export default IncidentInfo
