import { styled } from '@mui/material'
import { ActiveInactiveSitesByCountry } from 'nocapp-types'

interface CountriesActiveInactiveListProps {
  countries: ActiveInactiveSitesByCountry[]
}

const CountriesActiveInactiveList = ({
  countries,
}: CountriesActiveInactiveListProps) => {
  return (
    <Container>
      <TitleContainer>Active & Inactive sites by country</TitleContainer>
      <List>
        {countries.map((country, i) => (
          <CountryRow key={i}>
            <span>{country.country}</span>
            <div>
              <span>{country.active} active</span> |{' '}
              <span>{country.inactive} inactive</span>
            </div>
          </CountryRow>
        ))}
      </List>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const TitleContainer = styled('div')({
  width: '100%',
  height: 60,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 22,
})
const List = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
})
const CountryRow = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.default,
  padding: 15,
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 15,
  fontWeight: 'bold',
}))

export default CountriesActiveInactiveList
