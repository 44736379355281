import {
  DEVICES_IDS_DB,
  DEVICES_INFO_DB,
  SITES_DATES_DB,
  SITES_DB,
  SITES_DISTANCES_DB,
  SITES_INFO_DB,
  SITES_LINKS_DB,
  SITES_LOCATION_DB,
  SITES_SEVERITY_DB,
  SITES_UPDATES_DB,
} from '../../../services/api/databases'

export const updateSiteQueries = () => {
  return {
    sites: `UPDATE ${SITES_DB} SET
      caban_id=?,
      modem_id=?,
      site_name=?,
      country=?,
      active=?,
      commentaries=?
    WHERE
      caban_id = ?;`,
    //
    siteInfo: `UPDATE ${SITES_INFO_DB} SET
      site_id=?,
      contracted_load=?,
      customer=?,
      customer_id=?,
      owner=?,
      owner_id=?,
      type=?,
      supervisor=?
    WHERE
      site_id = ?;`,
    //
    location: `UPDATE ${SITES_LOCATION_DB} SET
      site_id=?,
      latitude=?,
      longitude=?,
      address=?,
      zone=?
    WHERE
      site_id = ?;`,
    //
    devicesInfo: `UPDATE ${DEVICES_INFO_DB} SET
      site_id=?,
      genset=?,
      grid=?,
      grid_percentage=?,
      solar_panels=?,
      solar_structures=?,
      packs=?,
      mppts=?,
      rectifiers=?
    WHERE
      site_id = ?;`,
    //
    devicesIds: `UPDATE ${DEVICES_IDS_DB} SET
      site_id=?,
      modem_id=?,
      cabinet_id=?,
      configuration=?,
      cabinet_type=?,
      battery_model=?
    WHERE
      site_id = ?;`,
    //
    dates: `UPDATE ${SITES_DATES_DB} SET
      site_id=?,
      turn_on_date=?,
      alerts_activation_date=?,
      migration_date=?,
      commencement_date=?
    WHERE
      site_id = ?;`,
    //
    links: `UPDATE ${SITES_LINKS_DB} SET
      site_id=?,
      report_link=?
    WHERE
      site_id = ?;`,
    //
    distancesUpdate: `UPDATE ${SITES_DISTANCES_DB} SET
      site_id=?,
      distance=?,
      travel_time=?
    WHERE
      site_id = ?;`,
    distancesInsert: `INSERT INTO ${SITES_DISTANCES_DB}(
      site_id,
      distance,
      travel_time
    ) VALUES (
      ?,
      ?,
      ?
    );`,
    //
    severityUpdate: `UPDATE ${SITES_SEVERITY_DB} SET
      site_id=?,
      severity=?,
      max_repair_time_total_loss=?,
      max_repair_time_partial_loss=?,
      max_repair_time_without_service_affection=?
    WHERE
      site_id = ?;`,
    severityInsert: `INSERT INTO ${SITES_SEVERITY_DB}(
      site_id,
      severity,
      max_repair_time_total_loss,
      max_repair_time_partial_loss,
      max_repair_time_without_service_affection
    ) VALUES (
      ?,
      ?,
      ?,
      ?,
      ?
    );`,
    //
    update: `INSERT INTO ${SITES_UPDATES_DB}(
      site_id,
      updated_by,
      username_update
    ) VALUES (
      ?,
      ?,
      ?
    );`,
  }
}
