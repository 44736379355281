import { useAuth0 } from '@auth0/auth0-react'
import { Procedure } from 'nocapp-types'
import { useCallback, useEffect, useState } from 'react'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import PageComponent from '../../../components/PageComponents/PageComponent'
import ProcedureBox from '../../../components/Procedures/ProcedureBox'
import ProcedureInfo from '../../../components/Procedures/ProcedureInfo/ProcedureInfo'
import ProceduresTopButtons from '../../../components/Procedures/ProceduresTopButtons'

const ProceduresPage = () => {
  // context
  const { getAccessTokenSilently } = useAuth0()

  // state
  const [procedures, setProcedures] = useState<Procedure[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedProcedure, setSelectedProcedure] = useState<
    Procedure | undefined
  >()
  const [openProcedure, setOpenProcedure] = useState(false)

  /**
   * Get list of all procedures in nocapp
   */
  const getProceduresHandle = useCallback(async () => {
    setLoading(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })

    const res = await fetch('api/procedures', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await res.json()
    setProcedures(data.message)
    setLoading(false)
  }, [getAccessTokenSilently])

  useEffect(() => {
    getProceduresHandle()
  }, [getProceduresHandle])

  /**
   * Handles opening procedure
   * @param procedure Procedure to open
   */
  const openProcedureHandle = (procedure: Procedure) => {
    setSelectedProcedure(procedure)
    setOpenProcedure(true)
  }

  /**
   * Updates the procedure state and updates the list with the new changes
   */
  const updateProcedureState = async (procedure: Procedure, state: boolean) => {
    setLoading(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })

    // eslint-disable-next-line no-param-reassign
    procedure.active = state

    await fetch(`/api/procedures/${procedure.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'put',
      body: JSON.stringify(procedure),
    })
    await getProceduresHandle()
    setLoading(false)
    setOpenProcedure(false)
  }

  /**
   * Deletes the procedure state and updates the list with the new changes
   */
  const deleteProcedure = async (procedure: Procedure) => {
    setLoading(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })

    await fetch(`/api/procedures/${procedure.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'delete',
    })
    await getProceduresHandle()
    setLoading(false)
    setOpenProcedure(false)
  }

  return (
    <PageComponent
      title="Procedures"
      loading={loading}
      centerComponent={<ProceduresTopButtons />}
      scrollable
      style={{
        flexWrap: 'wrap',
        gap: GLOBAL_PAGE_GAP,
      }}
    >
      {procedures.map((item, i) => (
        <ProcedureBox onClick={openProcedureHandle} procedure={item} key={i} />
      ))}
      {selectedProcedure && (
        <ProcedureInfo
          open={openProcedure}
          setOpen={setOpenProcedure}
          procedure={selectedProcedure}
          changeActiveState={updateProcedureState}
          deleteProcedure={deleteProcedure}
        />
      )}
    </PageComponent>
  )
}

export default ProceduresPage
