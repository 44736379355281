import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material'
import { Link } from 'react-router-dom'

interface BasicReportTemplatesProps {
  open: boolean
  setOpen: Function
  cabanId: string
  startDate: string
  endDate: string
}

const BasicReportTemplates = ({
  open,
  setOpen,
  cabanId,
  startDate,
  endDate,
}: BasicReportTemplatesProps) => {
  const templates = [
    {
      name: 'Basic template',
      link: `${cabanId}/template?from=${startDate}&to=${endDate}`,
    },
    {
      name: 'Tigo template',
      link: `${cabanId}/tigo-template?from=${startDate}&to=${endDate}`,
    },
    {
      name: 'Claro template',
      link: `${cabanId}/claro-template?from=${startDate}&to=${endDate}`,
    },
  ]

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Report templates</DialogTitle>
      <ReportsTemplatesButtonsWrap>
        {templates.map((template, i) => (
          <Link
            key={i}
            target="_blank"
            rel="noopener noreferrer"
            to={template.link}
          >
            <Button>{template.name}</Button>
          </Link>
        ))}
      </ReportsTemplatesButtonsWrap>
    </Dialog>
  )
}

const ReportsTemplatesButtonsWrap = styled(DialogContent)({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: 10,
})

export default BasicReportTemplates
