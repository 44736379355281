import { useCallback, useEffect, useState } from 'react'
import {
  CountryDivisionOfCustomers,
  CountriesCustomerCountResponse,
} from 'nocapp-types'
import CountriesBody from '../../../components/Sites/CountriesBody'
import { SITES_DB, SITES_INFO_DB } from '../../../services/api/databases'
import SitesSearchBar from '../../../components/Sites/SitesSearchBar'
import ApiHandler from '../../../services/api/apiHandler'
import PageComponent from '../../../components/PageComponents/PageComponent'

const SitesByCountryAndClientPage = () => {
  const [loading, setLoading] = useState(false)
  const [countriesDivisions, setCountriesDivisions] = useState<
    CountryDivisionOfCustomers[]
  >([])

  const formatCountryCustomerTable = (
    data: CountriesCustomerCountResponse[]
  ) => {
    let countries: any[] = []
    data.map((item) => {
      countries.push(item.country)
      return item
    })
    countries = countries.filter((item, pos) => countries.indexOf(item) === pos)
    countries = countries.map((country) => {
      return { country }
    })
    countries = countries.map((country) => {
      const companies: { customer: string; count: number }[] = []
      data.map((item) => {
        if (item.country === country.country) {
          companies.push({ customer: item.customer, count: item.count })
        }
        return item
      })
      // eslint-disable-next-line no-param-reassign
      country.customers = companies
      return country
    })
    setCountriesDivisions(countries)
  }

  const getCountriesData = useCallback(async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Get
    const customerCountriesData: CountriesCustomerCountResponse[] =
      await apiHandler.getList(
        `SELECT DISTINCT ${SITES_INFO_DB}.customer, ${SITES_DB}.country, COUNT(${SITES_DB}.country) as count FROM ${SITES_DB} LEFT JOIN ${SITES_INFO_DB} ON ${SITES_DB}.caban_id = ${SITES_INFO_DB}.site_id WHERE ${SITES_DB}.active = 1 GROUP BY ${SITES_INFO_DB}.customer, ${SITES_DB}.country ORDER BY ${SITES_INFO_DB}.customer`
      )
    formatCountryCustomerTable(customerCountriesData)
    setLoading(false)
  }, [])

  useEffect(() => {
    getCountriesData()
  }, [getCountriesData])

  return (
    <PageComponent
      title="Sites by country and client"
      centerComponent={<SitesSearchBar />}
      loading={loading}
    >
      <CountriesBody countriesDivisions={countriesDivisions} />
    </PageComponent>
  )
}

export default SitesByCountryAndClientPage
