import { styled } from '@mui/material/styles'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert } from 'nocapp-types'
import PageComponent from '../../../components/PageComponents/PageComponent'
import AlertsList from '../../../components/Alerts/AlertsList'
import ApiHandler from '../../../services/api/apiHandler'
import { getAlertsBySiteQuery } from './AlertsBySitePage.queries'

const AlertsBySitePage = () => {
  const { cabanId } = useParams<'cabanId'>()
  const [alerts, setAlerts] = useState<Alert[]>([])
  const [loading, setLoading] = useState(false)
  const [limit, setLimit] = useState<number | 'all'>(100)

  const getAlerts = useCallback(async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Get alerts site query
    const query = getAlertsBySiteQuery(limit, cabanId!)
    // Get alerts
    const data = await apiHandler.getList(query)
    setAlerts(data)
    setLoading(false)
  }, [cabanId, limit])

  useEffect(() => {
    getAlerts()
  }, [cabanId, getAlerts, limit])

  return (
    <PageComponent
      scrollable
      loading={loading}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Container>
        <AlertsList alerts={alerts} limit={limit} setLimit={setLimit} />
      </Container>
    </PageComponent>
  )
}

const Container = styled('div')({
  width: '100%',
  maxWidth: 700,
})

export default AlertsBySitePage
