import { styled } from '@mui/material'
import { Incident } from 'nocapp-types'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import PageComponent from '../../../components/PageComponents/PageComponent'
import ApiHandler from '../../../services/api/apiHandler'
import { endedIncidentsQuery } from './EndedIncidentsPage.query'

const EndedIncidentsPage = () => {
  const navigate = useNavigate()
  const [incidents, setIncidents] = useState<Incident[]>([])
  const [loading, setLoading] = useState(false)

  const getData = useCallback(async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    //
    const query = endedIncidentsQuery()
    const data = await apiHandler.getList(query)
    setIncidents(data)
    //
    setLoading(false)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <PageComponent
      title="Active incidents"
      loading={loading}
      scrollable
      style={{
        flexWrap: 'wrap',
        gap: GLOBAL_PAGE_GAP,
      }}
    >
      <IncidentsContainer
        style={{
          marginTop: 20,
        }}
      >
        <IncidentsTableContainer>
          <IncidentsTable>
            <div style={{ display: 'table-row' }}>
              <IncidentsTh>Number</IncidentsTh>
              <IncidentsTh>Caban ID</IncidentsTh>
              <IncidentsTh>Site Name</IncidentsTh>
              <IncidentsTh>Country</IncidentsTh>
              <IncidentsTh>Assigned To</IncidentsTh>
              <IncidentsTh>Title</IncidentsTh>
              <IncidentsTh>Open Date</IncidentsTh>
            </div>
            {incidents.map((incident, i) => (
              <IncidentsTr
                key={i}
                onClick={() => {
                  navigate(`/incidents/track/${incident.id}`)
                }}
              >
                <IncidentsTd>{incident.id}</IncidentsTd>
                <IncidentsTd>{incident.caban_id}</IncidentsTd>
                <IncidentsTd>{incident.site_name}</IncidentsTd>
                <IncidentsTd>{incident.country}</IncidentsTd>
                <IncidentsTd>{incident.assigned_to}</IncidentsTd>
                <IncidentsTd>{incident.type}</IncidentsTd>
                <IncidentsTd>{incident.created_at}</IncidentsTd>
              </IncidentsTr>
            ))}
          </IncidentsTable>
        </IncidentsTableContainer>
      </IncidentsContainer>
    </PageComponent>
  )
}

const IncidentsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const IncidentsBoxContainers = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  width: '60%',
  borderRadius: 15,
  padding: 10,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down(780)]: {
    width: '100%',
  },
}))
const IncidentsTableContainer = styled(IncidentsBoxContainers)(({ theme }) => ({
  marginTop: -20,
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down(780)]: {
    width: '100%',
  },
}))
const IncidentsTable = styled('div')(({ theme }) => ({
  display: 'table',
  textAlign: 'center',
  width: '100%',
  borderCollapse: 'collapse',
  fontSize: 14,
  [theme.breakpoints.down(475)]: {
    fontSize: 12,
  },
}))
const IncidentsTr = styled('div')(({ theme }) => ({
  display: 'table-row',
  cursor: 'pointer',
  ':hover': {
    background: theme.palette.background.default,
  },
}))
const IncidentsTd = styled('div')(({ theme }) => ({
  maxWidth: 120,
  display: 'table-cell',
  verticalAlign: 'center',
  padding: 8,
  borderBottom: `1px solid ${theme.palette.background.paper}`,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 90,
    padding: 5,
    textAlign: 'center',
  },
}))
const IncidentsTh = styled(IncidentsTd)(({ theme }) => ({
  fontWeight: 'bold',
  borderBottom: 0,
  [theme.breakpoints.down('sm')]: {
    padding: 6,
  },
}))

export default EndedIncidentsPage
