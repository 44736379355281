import { NocAppAppProfiles } from 'nocapp-types'
import { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { useNocAppAuth } from '../auth/NocAppAuthContext'

interface ProtectedRouteProps {
  Page: ReactElement
  restrictions: NocAppAppProfiles[]
}

const ProtectedRoute = ({ Page, restrictions }: ProtectedRouteProps) => {
  const { nocappUser } = useNocAppAuth()

  return restrictions.indexOf(nocappUser!.profile) >= 0 ? (
    Page
  ) : (
    <Navigate to="/" />
  )
}

export default ProtectedRoute
