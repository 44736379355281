import { Button, styled } from '@mui/material'
import { Procedure } from 'nocapp-types'
import GridCard from '../../PageComponents/GridCard'

interface Props {
  procedure: Procedure
  // eslint-disable-next-line no-unused-vars
  onClick: (procedure: Procedure) => void
}

const ProcedureBox = ({ procedure, onClick }: Props) => {
  return (
    <Container hoveranimation onClick={() => onClick(procedure)}>
      <span>{procedure.title}</span>
      {procedure.procedure_url && procedure.procedure_url !== '' && (
        <div>
          <Button onClick={() => window.open(procedure.procedure_url)}>
            Open Link
          </Button>
        </div>
      )}
      <ActiveRow>
        <ActiveMessage active={procedure.active === 1}>
          {procedure.active === 1 ? 'Active' : 'Inactive'}
        </ActiveMessage>
      </ActiveRow>
    </Container>
  )
}

const Container = styled(GridCard)({
  width: 300,
  minHeight: 130,
  cursor: 'pointer',
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})
const ActiveRow = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
})
const ActiveMessage = styled('span')<{ active: boolean }>(({ active }) => ({
  paddingLeft: 15,
  paddingRight: 15,
  background: active ? 'green' : '#de3731',
  fontSize: 14,
  borderRadius: 100,
  color: 'white',
}))

export default ProcedureBox
