import { styled } from '@mui/material'
import { SiteReport } from 'nocapp-types'
import { useCallback, useEffect, useState } from 'react'
import { Line as LineChart } from 'react-chartjs-2'
import { useParams, useSearchParams } from 'react-router-dom'
import PageComponent from '../../../../components/PageComponents/PageComponent'
import ApiHandler from '../../../../services/api/apiHandler'
import {
  acEnergy,
  batteryEnergy,
  CO2Emission,
  excessEnergy,
  loadPowerComsuption,
  solarEnergyFunction,
} from '../../../../utils/reportsFunctions'
import { getReportInfoFromSiteQuery } from '../../reportPagesQueries'

const SiteReportsCharts = () => {
  const { cabanId } = useParams<'cabanId'>()
  const [loadingData, setLoadingData] = useState(false)
  const [searchParams] = useSearchParams()
  const [reportData, setReportData] = useState<SiteReport[]>([])

  const getReportData = useCallback(async () => {
    const startDate = searchParams.get('from')
    const endDate = searchParams.get('to')
    setLoadingData(true)
    const apiHandler = new ApiHandler()
    // Queries
    const query = getReportInfoFromSiteQuery(cabanId!, startDate!, endDate!)
    //
    const data = await apiHandler.getList(query)
    setLoadingData(false)
    setReportData(data)
  }, [cabanId, searchParams])

  useEffect(() => {
    getReportData()
  }, [getReportData])

  return (
    <PageComponent scrollable loading={loadingData} title="LLA report charts">
      <Body>
        <SummaryChartContainer>
          <LineChart
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Grafana data',
                },
              },
            }}
            data={{
              labels: reportData.map((data) => `${data.date} ${data.time}`),
              datasets: [
                {
                  label: 'MPPT Voltage',
                  data: reportData.map((data) => data.core_mppt_output_voltage),
                  borderColor: '#845EC2',
                  backgroundColor: '#845EC2',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
                {
                  label: 'MPPT Current',
                  data: reportData.map((data) => data.core_mppt_output_current),
                  borderColor: '#D65DB1',
                  backgroundColor: '#D65DB1',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
                {
                  label: 'Rectifier Voltage',
                  data: reportData.map(
                    (data) => data.tel_status_rect_measured_voltage
                  ),
                  borderColor: '#FF6F91',
                  backgroundColor: '#FF6F91',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
                {
                  label: 'Rectifier Current',
                  data: reportData.map(
                    (data) => data.tel_status_rect_measured_current
                  ),
                  borderColor: '#FF9671',
                  backgroundColor: '#FF9671',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
                {
                  label: 'ESS Voltage',
                  data: reportData.map((data) => data.core_system_link_voltage),
                  borderColor: '#FFC75F',
                  backgroundColor: '#FFC75F',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
                {
                  label: 'ESS Current',
                  data: reportData.map((data) => data.core_ess_current),
                  borderColor: '#F9F871',
                  backgroundColor: '#F9F871',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
                {
                  label: 'Load Current',
                  data: reportData.map(
                    (data) => data.metric_load_current_estimate
                  ),
                  borderColor: '#00C9A7',
                  backgroundColor: '#00C9A7',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
                {
                  label: 'Battery temperature',
                  data: reportData.map((data) => data.core_ess_brick_max_temp),
                  borderColor: '#FF8066',
                  backgroundColor: '#FF8066',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
                {
                  label: 'System temperature',
                  data: reportData.map((data) => data.core_system_ambient_temp),
                  borderColor: '#3C8DA8',
                  backgroundColor: '#3C8DA8',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
                {
                  label: 'System temperature',
                  data: reportData.map((data) => data.core_system_ambient_temp),
                  borderColor: '#9B89B3',
                  backgroundColor: '#9B89B3',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
              ],
            }}
          />
        </SummaryChartContainer>
        <CardContainer>
          <LineChart
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Battery Energy Throughput',
                },
              },
            }}
            data={{
              labels: reportData.map((data) => `${data.date} ${data.time}`),
              datasets: [
                {
                  label: 'kWh',
                  data: reportData.map((data) =>
                    batteryEnergy(
                      data.core_system_link_voltage,
                      data.core_ess_current
                    )
                  ),
                  borderColor: '#845EC2',
                  backgroundColor: '#845EC2',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
              ],
            }}
          />
        </CardContainer>
        <CardContainer>
          <LineChart
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Grid Energy Provided',
                },
              },
            }}
            data={{
              labels: reportData.map((data) => `${data.date} ${data.time}`),
              datasets: [
                {
                  label: 'kWh',
                  data: reportData.map((data) =>
                    acEnergy(
                      data.tel_status_rect_measured_voltage,
                      data.tel_status_rect_measured_current
                    )
                  ),
                  borderColor: '#D65DB1',
                  backgroundColor: '#D65DB1',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
              ],
            }}
          />
        </CardContainer>
        <CardContainer>
          <LineChart
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Load Energy Consumption',
                },
              },
            }}
            data={{
              labels: reportData.map((data) => `${data.date} ${data.time}`),
              datasets: [
                {
                  label: 'kWh',
                  data: reportData.map((data) =>
                    loadPowerComsuption(
                      data.core_system_link_voltage,
                      data.metric_load_current_estimate
                    )
                  ),
                  borderColor: '#FF6F91',
                  backgroundColor: '#FF6F91',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
              ],
            }}
          />
        </CardContainer>
        <CardContainer>
          <LineChart
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Solar Energy Generation',
                },
              },
            }}
            data={{
              labels: reportData.map((data) => `${data.date} ${data.time}`),
              datasets: [
                {
                  label: 'kWh',
                  data: reportData.map((data) =>
                    solarEnergyFunction(
                      data.core_mppt_output_voltage,
                      data.core_mppt_output_current
                    )
                  ),
                  borderColor: '#FF9671',
                  backgroundColor: '#FF9671',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
              ],
            }}
          />
        </CardContainer>
        <CardContainer>
          <LineChart
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Excess Energy for Recharge',
                },
              },
            }}
            data={{
              labels: reportData.map((data) => `${data.date} ${data.time}`),
              datasets: [
                {
                  label: 'kWh',
                  data: reportData.map((data) =>
                    excessEnergy(
                      loadPowerComsuption(
                        data.core_system_link_voltage,
                        data.metric_load_current_estimate
                      ),
                      solarEnergyFunction(
                        data.core_mppt_output_voltage,
                        data.core_mppt_output_current
                      )
                    )
                  ),
                  borderColor: '#FFC75F',
                  backgroundColor: '#FFC75F',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
              ],
            }}
          />
        </CardContainer>
        <CardContainer>
          <LineChart
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'CO2eq Emissions',
                },
              },
            }}
            data={{
              labels: reportData.map((data) => `${data.date} ${data.time}`),
              datasets: [
                {
                  label: 'kWh',
                  data: reportData.map((data) =>
                    CO2Emission(
                      solarEnergyFunction(
                        data.core_mppt_output_voltage,
                        data.core_mppt_output_current
                      )
                    )
                  ),
                  borderColor: '#3C8DA8',
                  backgroundColor: '#3C8DA8',
                  cubicInterpolationMode: 'monotone',
                  tension: 0.4,
                },
              ],
            }}
          />
        </CardContainer>
      </Body>
    </PageComponent>
  )
}

const Body = styled('div')({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
})
const CardContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 15,
  padding: 10,
}))
const SummaryChartContainer = styled(CardContainer)({
  gridColumnStart: 1,
  gridColumnEnd: 3,
})

export default SiteReportsCharts
