import { styled } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  CampaignRounded as CampaignIcon,
  MapRounded as MapIcon,
  CardTravelRounded as CardTravelIcon,
  GroupRounded as GroupIcon,
  TrackChangesRounded as TrackIcon,
  InsightsRounded as InsightsIcon,
  SummarizeRounded as ReportsIcon,
  BugReportRounded as TaskTicketIcon,
  ArticleRounded as ProceduresIcon,
  WorkspacePremiumRounded as QualityControlIcon,
  BuildRounded as SparesRequestIcon,
} from '@mui/icons-material'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
// Icons
import { GLOBAL_PAGE_GAP } from '../../PageComponents/constants'

export const SIDEBAR_WIDTH = 300
export const SIDEBAR_BREAKPOINT = 700

const Sidebar = () => {
  const { nocappUser } = useNocAppAuth()

  const adminItems = [
    { name: 'Alerts', link: '/alerts', icon: CampaignIcon },
    { name: 'Sites', link: '/sites', icon: MapIcon },
    { name: 'Visits', link: '/visits', icon: CardTravelIcon },
    { name: 'Incidents', link: '/incidents/options', icon: TrackIcon },
    { name: 'Contacts', link: '/contacts', icon: GroupIcon },
    { name: 'Statistics', link: '/statistics/select-type', icon: InsightsIcon },
    { name: 'Reports', link: '/reports', icon: ReportsIcon },
    { name: 'Task tickets', link: '/task-tickets', icon: TaskTicketIcon },
    {
      name: 'Quality control',
      link: '/quality-reports',
      icon: QualityControlIcon,
    },
    { name: 'Procedures', link: '/procedures', icon: ProceduresIcon },
    {
      name: 'Spares request',
      link: '/spares-request',
      icon: SparesRequestIcon,
    },
  ]

  const nocSidebarItems = [
    { name: 'Alerts', link: '/alerts', icon: CampaignIcon },
    { name: 'Sites', link: '/sites', icon: MapIcon },
    { name: 'Visits', link: '/visits', icon: CardTravelIcon },
    { name: 'Incidents', link: '/incidents/options', icon: TrackIcon },
    { name: 'Contacts', link: '/contacts', icon: GroupIcon },
    { name: 'Statistics', link: '/statistics/select-type', icon: InsightsIcon },
    { name: 'Reports', link: '/reports', icon: ReportsIcon },
    { name: 'Task tickets', link: '/task-tickets', icon: TaskTicketIcon },
    { name: 'Procedures', link: '/procedures', icon: ProceduresIcon },
  ]

  const contractorIntSidebarItems = [
    { name: 'Sites', link: '/sites', icon: MapIcon },
    { name: 'Visits', link: '/visits', icon: CardTravelIcon },
    { name: 'Incidents', link: '/incidents/options', icon: TrackIcon },
  ]

  const financesSidebarItems = [
    { name: 'Sites', link: '/sites', icon: MapIcon },
    { name: 'Contacts', link: '/contacts', icon: GroupIcon },
    { name: 'Statistics', link: '/statistics/select-type', icon: InsightsIcon },
    { name: 'Reports', link: '/reports', icon: ReportsIcon },
  ]

  const oymSidebarItems = [
    { name: 'Alerts', link: '/alerts', icon: CampaignIcon },
    { name: 'Sites', link: '/sites', icon: MapIcon },
    { name: 'Visits', link: '/visits', icon: CardTravelIcon },
    { name: 'Incidents', link: '/incidents/options', icon: TrackIcon },
    { name: 'Contacts', link: '/contacts', icon: GroupIcon },
    { name: 'Statistics', link: '/statistics/select-type', icon: InsightsIcon },
    { name: 'Reports', link: '/reports', icon: ReportsIcon },
    { name: 'Task tickets', link: '/task-tickets', icon: TaskTicketIcon },
  ]

  return (
    <Container>
      {nocappUser!.profile === 'admin' &&
        adminItems.map((view, i) => (
          <Item to={view.link} key={i}>
            <view.icon fontSize="medium" />
            <span>{view.name}</span>
          </Item>
        ))}
      {nocappUser!.profile === 'noc' &&
        nocSidebarItems.map((view, i) => (
          <Item to={view.link} key={i}>
            <view.icon fontSize="medium" />
            <span>{view.name}</span>
          </Item>
        ))}
      {nocappUser!.profile === 'contractor-int' &&
        contractorIntSidebarItems.map((view, i) => (
          <Item to={view.link} key={i}>
            <view.icon fontSize="medium" />
            <span>{view.name}</span>
          </Item>
        ))}
      {nocappUser!.profile === 'finances' &&
        financesSidebarItems.map((view, i) => (
          <Item to={view.link} key={i}>
            <view.icon fontSize="medium" />
            <span>{view.name}</span>
          </Item>
        ))}
      {nocappUser!.profile === 'oym' &&
        oymSidebarItems.map((view, i) => (
          <Item to={view.link} key={i}>
            <view.icon fontSize="medium" />
            <span>{view.name}</span>
          </Item>
        ))}
    </Container>
  )
}

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  gap: 10,
})
const Item = styled(Link)(({ theme }) => ({
  width: '100%',
  height: 60,
  borderRadius: 10,
  flexShrink: 0,
  flexGrow: 0,
  padding: 10,
  paddingLeft: 20,
  background: theme.palette.background.default,
  transition: '200ms ease-in-out',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  gap: GLOBAL_PAGE_GAP,
  ':hover': {
    opacity: 0.7,
  },
}))

export default Sidebar
