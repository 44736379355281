import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'nocapp-types'
import { ALERTS_LOG_DB } from '../../../services/api/databases'
import PageComponent from '../../../components/PageComponents/PageComponent'
import GridCard from '../../../components/PageComponents/GridCard'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import ApiHandler from '../../../services/api/apiHandler'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'

const UploadAlertsPage = () => {
  const [lastAlert, setLastAlert] = useState<Alert>()
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const getLastAlert = useCallback(async () => {
    const apiHandler = new ApiHandler()
    const alert = await apiHandler.getList(
      `SELECT * FROM ${ALERTS_LOG_DB} ORDER BY created_at_date DESC LIMIT 1`
    )
    setLastAlert(alert[0])
  }, [])

  useEffect(() => {
    getLastAlert()
  }, [getLastAlert])

  const getFileSize = (fl: File) => {
    return `${(fl.size / 1024 / 1024).toFixed(2)} MiB`
  }

  const getFileHandler = (fl: File) => {
    setReady(true)
    setFile(fl)
  }

  const upload = async () => {
    if (!ready) {
      setOpenSnackbar(true)
      setSeverity('warning')
      setMessage('Please upload correct file')
      return
    }
    setLoading(true)
    const apiHandler = new ApiHandler()
    const body = new FormData()
    body.append('file', file!)
    try {
      await apiHandler.uploadFile(body, '/api/alerts/upload')
      setLoading(false)
      getLastAlert()
      setOpenSnackbar(true)
      setSeverity('success')
      setMessage('The alerts have been uploaded')
    } catch (error) {
      setOpenSnackbar(true)
      setSeverity('error')
      setMessage("There's been an error")
    }
  }

  return (
    <PageComponent
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: GLOBAL_PAGE_GAP,
      }}
    >
      <UploadFileContainer>
        <Title>Upload Alerts CSV</Title>
        <div style={{ marginBottom: 20 }}>
          <span>Make sure the file don&apos;t have any empty rows</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 15,
          }}
        >
          <input
            type="file"
            id="fileChooser"
            style={{ display: 'none' }}
            onChange={(e) => getFileHandler(e.target.files![0])}
          />
          <FileChooserButton htmlFor="fileChooser">
            Choose a file
          </FileChooserButton>
        </div>
        {file != null && (
          <FileDescriptionContainer>
            <div>{file.name}</div>
            <div>File size: {getFileSize(file!)}</div>
          </FileDescriptionContainer>
        )}
        <LoadingButton loading={loading} variant="contained" onClick={upload}>
          Submit
        </LoadingButton>
      </UploadFileContainer>
      {lastAlert && (
        <UploadFileContainer>
          <Title style={{ fontSize: 18 }}>Last alert uploaded</Title>
          <table
            style={{
              fontSize: 14,
              width: '100%',
              textAlign: 'center',
            }}
          >
            <tbody>
              <tr>
                <th>ID</th>
                <th>Site</th>
                <th>Message</th>
                <th>Date</th>
              </tr>
              <tr>
                <td>{lastAlert!.id}</td>
                <td>{lastAlert!.site}</td>
                <td>{lastAlert!.message}</td>
                <td>{lastAlert!.created_at_date}</td>
              </tr>
            </tbody>
          </table>
        </UploadFileContainer>
      )}
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

const UploadFileContainer = styled(GridCard)(({ theme }) => ({
  width: '40%',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
const Title = styled('span')({
  fontSize: 25,
  fontWeight: 'bolder',
})
const FileDescriptionContainer = styled('div')({
  width: '100%',
  padding: 10,
  border: '2px solid #d5d5d5',
  borderRadius: 10,
  marginBottom: 20,
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const FileChooserButton = styled('label')(({ theme }) => ({
  width: 200,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: 15,
  cursor: 'pointer',
}))

export default UploadAlertsPage
