import { AppViews } from 'nocapp-types'
import { DashboardPage, LoginPage } from '../pages'
// Views
import { sitesViews } from './sitesViews'
import { alertsViews } from './alertsViews'
import { visitsViews } from './visitsViews'
import { incidentsViews } from './incidentsViews'
import { statisticsViews } from './statisticsViews'
import { qualityControlViews } from './qualityControlViews'
import { reportsViews } from './reportsViews'
import { taskTicketsViews } from './taskTicketsViews'
import { proceduresViews } from './proceduresViews'
import { sparesRequestViews } from './sparesRequestViews'
import { contactsViews } from './contactsViews'

export const views: AppViews[] = [
  {
    path: '/',
    page: <DashboardPage />,
    access: [],
  },
  //
  ...sitesViews,
  ...alertsViews,
  ...visitsViews,
  ...contactsViews,
  ...incidentsViews,
  ...statisticsViews,
  ...reportsViews,
  ...qualityControlViews,
  ...taskTicketsViews,
  ...proceduresViews,
  ...sparesRequestViews,
]

export const loginViews: AppViews[] = [
  {
    path: '*',
    page: <LoginPage />,
    access: [],
  },
]
