import { SiteReport } from 'nocapp-types'
import {
  acEnergy,
  activeLoad,
  CO2Emission,
  downtimeHours,
  hoursOfGenset,
  loadPowerComsuption,
  solarEnergyFunction,
} from '../../../utils/reportsFunctions'

export const basicReportKpis = (
  reportData: SiteReport[],
  startDate: string,
  endDate: string
) => {
  //  hwduiqgyiedfgyufegyugefowq
  const firstDate = new Date(startDate!)
  const lastDate = new Date(endDate!)
  const diffDays = Math.round(
    Math.abs((lastDate.getTime() - firstDate.getTime()) / (1000 * 3600 * 24))
  )
  // Availability
  const sumatoryDowntownHours = reportData
    .map((data) => downtimeHours(data.metric_load_current_estimate))
    .reduce((a: number, b: number) => a + b, 0)
  const availabilityXValue = (sumatoryDowntownHours * 100) / 744
  const availability = (100 - availabilityXValue) / 100
  // Solar energy /AVG
  const solarEnergyTotal = reportData
    .map((data) =>
      solarEnergyFunction(
        data.core_mppt_output_voltage,
        data.core_mppt_output_current
      )
    )
    .reduce((a: number, b: number) => a + b, 0)
  const solarEnergyAvgPerDay = solarEnergyTotal / diffDays
  // Load total / AVG
  const loadEnergyValues = reportData.map((data) =>
    activeLoad(
      +(
        loadPowerComsuption(
          data.core_system_link_voltage,
          data.metric_load_current_estimate
        ) / 1000
      ).toFixed(2)
    )
  )
  const loadEnergyTotal = reportData
    .map((data) =>
      activeLoad(
        +(
          loadPowerComsuption(
            data.core_system_link_voltage,
            data.metric_load_current_estimate
          ) / 1000
        ).toFixed(2)
      )
    )
    .reduce((a: number, b: number) => a + b, 0)
  const loadEnergyAvg = loadEnergyTotal / reportData.length
  const maxLoad = Math.max(...loadEnergyValues)
  // AC total / AVG
  const acTotal = reportData
    .map(
      (data) =>
        acEnergy(
          data.tel_status_rect_measured_current,
          data.tel_status_rect_measured_voltage
        ) / 1000
    )
    .reduce((a: number, b: number) => a + b, 0)
  const acEnergyGenerationAvgPerDay = acTotal / diffDays
  const acEnergyGenerationAvg = acTotal / reportData.length
  // Energy reduction of grid
  const utilityEnergyUsage =
    reportData
      .map(
        (data) =>
          data.tel_status_rect_measured_voltage *
          data.tel_status_rect_measured_current
      )
      .reduce((a: number, b: number) => a + b, 0) / 1000
  const loadConsumption =
    reportData
      .map(
        (data) =>
          data.metric_load_current_estimate * data.core_system_link_voltage
      )
      .reduce((a: number, b: number) => a + b, 0) / 1000
  const energyReduction = 1 - utilityEnergyUsage / loadConsumption
  // Battery energy throught
  const batteryEnergyDemanded =
    reportData
      .map((data) =>
        data.core_ess_current > 0
          ? data.core_ess_current * data.core_system_link_voltage
          : 0
      )
      .reduce((a: number, b: number) => a + b, 0) / 1000
  const batteryEnergySupplied =
    reportData
      .map((data) =>
        data.core_ess_current < 0
          ? data.core_ess_current * data.core_system_link_voltage * -1
          : 0
      )
      .reduce((a: number, b: number) => a + b, 0) / 1000
  const batteryEnergy = batteryEnergyDemanded + batteryEnergySupplied
  // GPA
  const gpa =
    reportData
      .map((data) =>
        data.core_system_link_voltage < 46.6 &&
        data.metric_load_current_estimate <= 0
          ? 0
          : 1
      )
      .reduce((a: number, b: number) => a + b, 0) / reportData.length
  // CO2
  // Total emition per month
  const totalEmitionPerMonth = CO2Emission(solarEnergyTotal)
  //

  return {
    firstDate,
    lastDate,
    diffDays,
    sumatoryDowntownHours,
    availabilityXValue,
    availability,
    solarEnergyTotal,
    solarEnergyAvgPerDay,
    loadEnergyTotal,
    loadEnergyAvg,
    acTotal,
    acEnergyGenerationAvgPerDay,
    acEnergyGenerationAvg,
    energyReduction,
    gpa,
    totalEmitionPerMonth,
    batteryEnergy,
    maxLoad,
  }
}

export const basicReportDataForExport = (
  data: SiteReport,
  PRECISION_LENGHT: number
) => [
  data.date,
  data.time,
  data.metric_load_current_estimate,
  data.core_ess_current,
  data.tel_status_rect_measured_current,
  data.core_mppt_output_current,
  data.core_system_link_voltage,
  data.tel_status_rect_measured_voltage,
  data.core_mppt_output_voltage,
  solarEnergyFunction(
    data.core_mppt_output_voltage,
    data.core_mppt_output_current
  ).toFixed(PRECISION_LENGHT),
  (
    loadPowerComsuption(
      data.core_system_link_voltage,
      data.metric_load_current_estimate
    ) / 1000
  ).toFixed(PRECISION_LENGHT),
  (
    acEnergy(
      data.tel_status_rect_measured_current,
      data.tel_status_rect_measured_voltage
    ) / 1000
  ).toFixed(PRECISION_LENGHT),
  downtimeHours(data.metric_load_current_estimate),
  activeLoad(
    +(
      loadPowerComsuption(
        data.core_system_link_voltage,
        data.metric_load_current_estimate
      ) / 1000
    ).toFixed(PRECISION_LENGHT)
  ),
  hoursOfGenset(data.tel_status_rect_measured_current),
]
