import {
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  TextField,
} from '@mui/material'

interface QualityControlFormItemProps {
  id: number
  action: string
  // eslint-disable-next-line react/no-unused-prop-types
  comment: string
  formId: string
  photos?: number[]
  value: 'yes' | 'no' | 'not_apply' | null
}
// Local image
// http://localhost/caban/api/quality-control/img/kml-map-pin%20(1).png
const QualityControlFormItem = ({
  id,
  action,
  comment,
  formId,
  value,
  photos,
}: QualityControlFormItemProps) => {
  return (
    <ItemContainer>
      <IdContainer>
        <IdSpan>{id}</IdSpan>
      </IdContainer>
      <ActionContainer>{action}</ActionContainer>
      <CommentContainer>{comment}</CommentContainer>
      <RadiosContainer>
        <RadioGroup name={formId} row>
          <FormControlLabel
            value="yes"
            control={
              value ? (
                <Radio
                  required
                  checked={value === 'yes'}
                  value="yes"
                  name={formId}
                />
              ) : (
                <Radio required value="yes" name={formId} />
              )
            }
            label="Yes"
          />
          <FormControlLabel
            value="no"
            control={
              value ? (
                <Radio
                  required
                  checked={value === 'no'}
                  value="no"
                  name={formId}
                />
              ) : (
                <Radio required value="no" name={formId} />
              )
            }
            label="No"
          />
          <FormControlLabel
            value="not_apply"
            control={
              value ? (
                <Radio
                  required
                  checked={value === 'not_apply'}
                  value="not_apply"
                  name={formId}
                />
              ) : (
                <Radio required value="not_apply" name={formId} />
              )
            }
            label="Not apply"
          />
        </RadioGroup>
      </RadiosContainer>
      {photos &&
        photos.length !== 0 &&
        photos.map((item, i) => (
          <input key={i} type="file" name={`item${id}_photo${item}`} id="" />
        ))}
    </ItemContainer>
  )
}

export const QualityControlFormItemInput = ({
  id,
  action,
  formId,
  value,
  photos,
}: QualityControlFormItemProps) => {
  return (
    <ItemContainer>
      <IdContainer>
        <IdSpan>{id}</IdSpan>
      </IdContainer>
      <ActionContainer>{action}</ActionContainer>
      <RadiosContainer>
        {value ? (
          <TextField fullWidth label="Value" value={value} name={formId} />
        ) : (
          <TextField fullWidth label="Value" name={formId} />
        )}
      </RadiosContainer>
      {photos &&
        photos.length !== 0 &&
        photos.map((item, i) => (
          <input key={i} type="file" name={`item${id}_photo${item}`} id="" />
        ))}
    </ItemContainer>
  )
}

const ItemContainer = styled('div')(({ theme }) => ({
  width: 600,
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 15,
  padding: 20,
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
const IdContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
})
const IdSpan = styled('span')(({ theme }) => ({
  width: 35,
  height: 35,
  fontSize: 14,
  borderRadius: 100,
  padding: 5,
  background: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
}))
const ActionContainer = styled('span')(() => ({
  width: '100%',
  fontWeight: 'bold',
  textAlign: 'justify',
  marginTop: 7,
}))
const CommentContainer = styled('span')(({ theme }) => ({
  width: '100%',
  fontSize: 14,
  fontWeight: 'bold',
  textAlign: 'justify',
  marginTop: 3,
  color: theme.palette.text.secondary,
}))
const RadiosContainer = styled('span')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginTop: 7,
  justifyContent: 'space-between',
}))

export default QualityControlFormItem
