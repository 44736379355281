import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  styled,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { AlertpediaItem } from 'nocapp-types'
import { ALERTS_INFO_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'

interface AlertpediaProps {
  open: boolean
  setOpen: Function
}

const Alertpedia = ({ open, setOpen }: AlertpediaProps) => {
  const [alertpedia, setAlerpedia] = useState<AlertpediaItem[]>([])
  const [selectedAlert, setSelectedAlert] = useState<AlertpediaItem>({
    alert: '',
    field: '',
    id: 0,
    prc: '',
    priority: '',
    proposal: '',
    severity: '',
    troubles: '',
  })

  useEffect(() => {
    const get = async () => {
      const apiHandler = new ApiHandler()
      const data: AlertpediaItem[] = await apiHandler.getList(
        `SELECT * FROM ${ALERTS_INFO_DB} ORDER BY alert`
      )
      setAlerpedia(data)
      setSelectedAlert(data[0])
    }

    get()
  }, [])

  const changeAlertHandler = (message: string) => {
    let newAlert = {}
    alertpedia.map((alert) => {
      if (message === alert.alert) {
        newAlert = alert
        return ''
      }
      return ''
    })
    // @ts-ignore
    setSelectedAlert(newAlert)
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 28 }}>
        Alertpedia
      </DialogTitle>
      <DialogContent>
        <TextField
          select
          style={{ width: '100%', marginTop: 10 }}
          label="Select an alert"
          value={selectedAlert!.alert}
          placeholder="Select an alert"
          onChange={(e) => changeAlertHandler(e.target.value)}
        >
          {alertpedia.map((alert, i) => (
            <MenuItem key={i} value={alert.alert!}>
              {alert.alert!.replaceAll('_', ' ')}
            </MenuItem>
          ))}
        </TextField>
        <AlertTable>
          <tbody>
            <tr>
              <Td>Message</Td>
              <Td style={{ textAlign: 'center', fontWeight: 600 }}>
                {selectedAlert.alert!.replaceAll('_', ' ')}
              </Td>
            </tr>
            <tr>
              <Td style={{ fontWeight: 600 }}>
                Priority: {selectedAlert.priority}
              </Td>
              <Td style={{ textAlign: 'center', fontWeight: 600 }}>
                Severity: {selectedAlert.severity}
              </Td>
            </tr>
            <tr>
              <Td>Possible Root-Cause</Td>
              <Td style={{ textAlign: 'center', fontWeight: 600 }}>
                {selectedAlert.prc}
              </Td>
            </tr>
            <tr>
              <Td>Troubleshooting</Td>
              <Td style={{ textAlign: 'justify', fontWeight: 600 }}>
                {selectedAlert.troubles}
              </Td>
            </tr>
            <tr>
              <Td>NOC (proposal)</Td>
              <Td style={{ textAlign: 'justify', fontWeight: 600 }}>
                {selectedAlert.proposal}
              </Td>
            </tr>
            <tr>
              <Td>Field</Td>
              <Td style={{ textAlign: 'justify', fontWeight: 600 }}>
                {selectedAlert.field}
              </Td>
            </tr>
          </tbody>
        </AlertTable>
      </DialogContent>
    </Dialog>
  )
}

const AlertTable = styled('table')({
  marginTop: 20,
  width: '100%',
})
const Td = styled('td')(({ theme }) => ({
  padding: 5,
  borderRadius: 5,
  maxWidth: '40%',
  verticalAlign: 'center',
  background: theme.palette.background.default,
}))

export default Alertpedia
