import { styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { SpareRequest } from 'nocapp-types'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import GridCard from '../../../components/PageComponents/GridCard'
import PageComponent from '../../../components/PageComponents/PageComponent'
import { SPARES_REQUEST_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'

const RequestListPage = () => {
  const [loading, setLoading] = useState(false)
  const [requests, setRequests] = useState<SpareRequest[]>([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const apiHandler = new ApiHandler()
      const data = await apiHandler.getList(
        `SELECT * FROM ${SPARES_REQUEST_DB} ORDER BY created_at DESC`
      )
      setRequests(data)
      setLoading(false)
    }

    getData()
  }, [])

  return (
    <PageComponent
      title="Requests list"
      loading={loading}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: GLOBAL_PAGE_GAP,
      }}
    >
      {requests.map((request, i) => (
        <div key={i}>
          <RequestBox>XDdghequigfewyiguwefo</RequestBox>
        </div>
      ))}
    </PageComponent>
  )
}

const RequestBox = styled(GridCard)({
  width: 400,
  height: 160,
})

export default RequestListPage
