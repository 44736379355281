import { QUALITY_CONTROL_CONCRETE_BASE_DB } from '../../../services/api/databases'

export const getConcreteBaseInsertQuery = (
  photos: any,
  body: any,
  formId: string
) =>
  `INSERT INTO ${QUALITY_CONTROL_CONCRETE_BASE_DB}(
    form_id,
    item1,
    item1_photos,
    item2,
    item2_photos,
    item3,
    item3_photos,
    item4,
    item4_photos,
    item5,
    item5_photos,
    item6,
    item6_photos,
    item7,
    item7_photos,
    item8,
    item8_photos,
    item9,
    item9_photos,
    item10,
    item10_photos,
    item11,
    item11_photos,
    item12,
    item12_photos,
    item13,
    item13_photos,
    item14,
    item14_photos,
    item15,
    item15_photos,
    item16,
    item16_photos,
    item17,
    item17_photos
  ) VALUES (
    '${formId}',
    '${body.item1}',
    '${JSON.stringify(photos.item1.map((item: File) => item.name))}',
    '${body.item2}',
    '${JSON.stringify(photos.item2.map((item: File) => item.name))}',
    '${body.item3}',
    '${JSON.stringify(photos.item3.map((item: File) => item.name))}',
    '${body.item4}',
    '${JSON.stringify(photos.item4.map((item: File) => item.name))}',
    '${body.item5}',
    '${JSON.stringify(photos.item5.map((item: File) => item.name))}',
    '${body.item6}',
    '${JSON.stringify(photos.item6.map((item: File) => item.name))}',
    '${body.item7}',
    '${JSON.stringify(photos.item7.map((item: File) => item.name))}',
    '${body.item8}',
    '${JSON.stringify(photos.item8.map((item: File) => item.name))}',
    '${body.item9}',
    '${JSON.stringify(photos.item9.map((item: File) => item.name))}',
    '${body.item10}',
    '${JSON.stringify(photos.item10.map((item: File) => item.name))}',
    '${body.item11}',
    '${JSON.stringify(photos.item11.map((item: File) => item.name))}',
    '${body.item12}',
    '${JSON.stringify(photos.item12.map((item: File) => item.name))}',
    '${body.item13}',
    '${JSON.stringify(photos.item13.map((item: File) => item.name))}',
    '${body.item14}',
    '${JSON.stringify(photos.item14.map((item: File) => item.name))}',
    '${body.item15}',
    '${JSON.stringify(photos.item15.map((item: File) => item.name))}',
    '${body.item16}',
    '${JSON.stringify(photos.item16.map((item: File) => item.name))}',
    '${body.item17}',
    '${JSON.stringify(photos.item17.map((item: File) => item.name))}'
  )`
