import { LoadingButton } from '@mui/lab'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@mui/material'
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import QualityControlFormItem, {
  QualityControlFormItemInput,
} from '../../../components/QualityControl/QualityFormItem'
import { qualityControlEarthingSystem } from '../../../utils/qualityControlFormsData'
import { QUALITY_CONTROL_EARTHING_SYSTEM_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'
import { getEarthingSystemInsertQuery } from './EarthingSystemFormPage.queries'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'

const EarthingSystemFormPage = () => {
  const navigate = useNavigate()
  const { formId } = useParams<'formId'>()
  const [formData, setFormData] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingProps, setLoadingProps] = useState(false)
  const [openButtonMenu, setOpenButtonMenu] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const buttonGroupRef = useRef<HTMLDivElement>(null)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const getFormData = useCallback(async () => {
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    const info = await apiHandler.getObject(
      `SELECT * FROM ${QUALITY_CONTROL_EARTHING_SYSTEM_DB} WHERE form_id = '${formId}'`
    )
    setFormData(info)
    setLoadingProps(true)
  }, [formId])

  useEffect(() => {
    getFormData()
  }, [getFormData])

  const returnToQualityDashboard = () => {
    navigate(`/quality-reports?id=${formId}`)
  }

  const sendForm = async (
    goHome: boolean,
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Transform data
    const formValues = new FormData(event.currentTarget)
    const body: any = {}
    const photos: any = {}
    formValues.forEach((value, key) => {
      const splitted = key.split('_')
      if (splitted[1] !== undefined) {
        if (Array.isArray(photos[splitted[0]])) {
          photos[splitted[0]].push(value)
        } else {
          photos[splitted[0]] = [value]
        }
      } else {
        body[key] = value
      }
    })
    try {
      const insertBody = getEarthingSystemInsertQuery(photos, body, formId!)
      // Prepate to upload fotos form
      const photosForm = new FormData()
      for (const key in photos) {
        if (Object.prototype.hasOwnProperty.call(photos, key)) {
          const element: File[] = photos[key]
          element.map((item, i) => {
            photosForm.append(`photo${i}`, item, item.name)
            return item
          })
        }
      }
      photosForm.append('guide', JSON.stringify(photos))
      // Upload data
      await Promise.all([
        apiHandler.postItem(insertBody),
        apiHandler.uploadFile(photosForm, 'api/quality-control/'),
      ])
      setSeverity('success')
      setMessage('Earthing system sended')
      setOpenSnackbar(true)
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
    setLoading(false)
    if (goHome) {
      returnToQualityDashboard()
    } else {
      navigate(`/quality-reports/${formId}/equipments`)
    }
  }

  const buttonOptions = [
    'Send & go to Equipments',
    'Send & go to Quality form dashboard',
  ]

  return (
    <PageComponent
      title="QC / Earthing System"
      loading={loadingProps}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormContainer
        onSubmit={!formData && ((e) => sendForm(selectedIndex !== 0, e))}
      >
        {qualityControlEarthingSystem.map((item, i) =>
          item.input ? (
            <QualityControlFormItemInput
              key={i}
              action={item.action}
              comment={item.comments}
              formId={`item${item.id}`}
              value={formData ? formData[`item${item.id}`] : null}
              photos={item.photos}
              id={item.id}
            />
          ) : (
            <QualityControlFormItem
              key={i}
              action={item.action}
              comment={item.comments}
              formId={`item${item.id}`}
              value={formData ? formData[`item${item.id}`] : null}
              photos={item.photos}
              id={item.id}
            />
          )
        )}
        {!formData && (
          <ButtonContainer>
            <ButtonGroup
              style={{ width: '100%' }}
              variant="contained"
              disableElevation
              ref={buttonGroupRef}
            >
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
              >
                {buttonOptions[selectedIndex]}
              </LoadingButton>
              <Button onClick={() => setOpenButtonMenu(true)} size="small">
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={openButtonMenu}
              anchorEl={buttonGroupRef.current}
              transition
              style={{ zIndex: 3 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => setOpenButtonMenu(false)}
                    >
                      <MenuList id="split-button-menu">
                        {buttonOptions.map((option, i) => (
                          <MenuItem
                            key={i}
                            selected={i === selectedIndex}
                            onClick={() => {
                              setSelectedIndex(i)
                              setOpenButtonMenu(false)
                            }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ButtonContainer>
        )}
      </FormContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

const FormContainer = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: GLOBAL_PAGE_GAP,
})
const ButtonContainer = styled('div')(({ theme }) => ({
  width: 600,
  height: 'auto',
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export default EarthingSystemFormPage
