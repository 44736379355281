import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import LoadingPage from '../LoadingPage'

const NoNocappAccessPage = () => {
  const { user } = useAuth0()
  const [showNoAccess, setShowNoAccess] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNoAccess(true)
    }, 10000)

    return () => clearTimeout(timer)
  }, [])

  return showNoAccess ? (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <h1>Oh no!!</h1>
      <p>
        {user?.email} doesn&apos;t have access to Nocapp 😢
        <br />
        Request access and refresh this page
      </p>
    </div>
  ) : (
    <LoadingPage />
  )
}

export default NoNocappAccessPage
