import { Incident, Site } from 'nocapp-types'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import IncidentsBody from '../../../components/Incidents/IncidentsBody'
import LoadingPage from '../../../components/PageComponents/LoadingPage'
import PageComponent from '../../../components/PageComponents/PageComponent'
import { useInterval } from '../../../hooks/useInterval'
import ApiHandler from '../../../services/api/apiHandler'
import {
  getIncidentInfoQuery,
  getSiteInfoQuery,
} from './IncidentTrackerPage.queries'

const IncidentTrackerPage = () => {
  const { id } = useParams<'id'>()
  const [loadingProps, setLoadingProps] = useState(true)
  const [incidentInfo, setIncidentInfo] = useState<Incident | null>(null)
  const [siteInfo, setSiteInfo] = useState<Site | null>(null)

  const getParams = useCallback(async () => {
    const apiHandler = new ApiHandler()
    // Get incident
    const incidentQuery = getIncidentInfoQuery(id!)
    const incident: Incident = await apiHandler.getObject(incidentQuery)
    // Get site
    const siteQuery = getSiteInfoQuery(incident.caban_id)
    const site: Site = await apiHandler.getObject(siteQuery)
    // Set values
    setSiteInfo(site)
    setIncidentInfo(incident)
    setLoadingProps(false)
  }, [id])

  useEffect(() => {
    getParams()
  }, [getParams])

  useInterval(() => {
    getParams()
  }, 7000)

  if (loadingProps) {
    return <LoadingPage />
  }

  return (
    <PageComponent>
      <IncidentsBody
        incident={incidentInfo!}
        site={siteInfo!}
        updateData={getParams}
      />
    </PageComponent>
  )
}

export default IncidentTrackerPage
