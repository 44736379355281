import { styled } from '@mui/material'
import { TaskTicket } from 'nocapp-types'
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded'
import PendingIcon from '@mui/icons-material/HelpRounded'
import { useState } from 'react'
import TicketInfo from '../TicketInfo'
import { MONTHS_LIST } from '../../../utils/datesHandle'

const TaskTicketBox = ({
  ticket,
  updateTicketsList,
}: {
  ticket: TaskTicket
  updateTicketsList: Function
}) => {
  const [openTicket, setOpenTicket] = useState(false)

  const getMonthDayYearDate = (string: string) => {
    const date = new Date(string)
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`
  }

  return (
    <>
      <TicketContainer onClick={() => setOpenTicket(true)}>
        <TicketInfoBox>
          <TicketNameContainer>
            <TicketId>{ticket.id}</TicketId>
            <span>{ticket.subject}</span>
          </TicketNameContainer>
          <TicketDates>
            <span>Created at: {getMonthDayYearDate(ticket.created_at)}</span>
            <span>-</span>
            {ticket.approved_at !== null ? (
              <span>Done at: {getMonthDayYearDate(ticket.end_timestamp)}</span>
            ) : (
              <span>Due to: {getMonthDayYearDate(ticket.due_timestamp)}</span>
            )}
            <span>-</span>
            <span>Created by: {ticket.creator_name}</span>
            <span>-</span>
            <span>Assigned to: {ticket.assigned_name}</span>
          </TicketDates>
        </TicketInfoBox>
        <TicketStatus>
          {/* eslint-disable-next-line no-nested-ternary */}
          {ticket.approved_at !== null ? (
            <>
              <span>Done</span>
              <CheckCircleIcon color="success" />
            </>
          ) : ticket.end_timestamp === null ? (
            <>
              <span>Pending</span>
              <PendingIcon color="warning" />
            </>
          ) : (
            <>
              <span>Waiting for approval</span>
              <PendingIcon color="warning" />
            </>
          )}
        </TicketStatus>
      </TicketContainer>
      <TicketInfo
        open={openTicket}
        setOpen={setOpenTicket}
        ticket={ticket}
        updateTicketsList={updateTicketsList}
      />
    </>
  )
}

const TicketContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 10,
  borderRadius: 10,
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'row',
  fontWeight: 'bold',
  cursor: 'pointer',
}))
const TicketInfoBox = styled('div')({
  flex: 2,
  display: 'flex',
  flexDirection: 'column',
})
const TicketDates = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.secondary,
  fontSize: 12,
  gap: 5,
  marginTop: 10,
}))
const TicketNameContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})
const TicketId = styled('span')(({ theme }) => ({
  width: 25,
  height: 25,
  padding: 5,
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 100,
  background: theme.palette.background.paper,
}))
const TicketStatus = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  fontSize: 14,
  color: theme.palette.text.secondary,
}))

export default TaskTicketBox
