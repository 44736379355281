import { AppViews } from 'nocapp-types'
import {
  QualityControlConcreteBaseFormPage,
  QualityControlDashboard,
  QualityControlEarthingSystemFormPage,
  QualityControlElectricalInstallationFormPage,
  QualityControlEquipmentsFormPage,
  QualityControlSolarStructureFormPage,
} from '../pages'

export const qualityControlViews: AppViews[] = [
  {
    path: 'quality-reports',
    page: <QualityControlDashboard />,
    access: ['admin'],
  },
  {
    path: 'quality-reports/:formId/concrete-base',
    page: <QualityControlConcreteBaseFormPage />,
    access: ['admin'],
  },
  {
    path: 'quality-reports/:formId/solar-structure',
    page: <QualityControlSolarStructureFormPage />,
    access: ['admin'],
  },
  {
    path: 'quality-reports/:formId/earthing-system',
    page: <QualityControlEarthingSystemFormPage />,
    access: ['admin'],
  },
  {
    path: 'quality-reports/:formId/equipments',
    page: <QualityControlEquipmentsFormPage />,
    access: ['admin'],
  },
  {
    path: 'quality-reports/:formId/electrical-installation',
    page: <QualityControlElectricalInstallationFormPage />,
    access: ['admin'],
  },
]
