import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  styled,
} from '@mui/material'
import { Procedure } from 'nocapp-types'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import { Android12Switch } from '../../PageComponents/SwitchComponent'

interface Props {
  open: boolean
  setOpen: Function
  procedure: Procedure
  changeActiveState: Function
  deleteProcedure: Function
}

const ProcedureInfo = ({
  open,
  setOpen,
  procedure,
  changeActiveState,
  deleteProcedure,
}: Props) => {
  const { nocappUser } = useNocAppAuth()

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: -25 }}>
        <DialogTitle style={{ fontWeight: 'bold', fontSize: 25 }}>
          {procedure.title}
        </DialogTitle>
        <ActiveMessage active={procedure.active === 1}>
          {procedure.active === 1 ? 'Active' : 'Inactive'}
        </ActiveMessage>
      </div>
      <DialogContent>
        <p>{procedure.description}</p>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {nocappUser?.profile === 'admin' && (
            <Button onClick={() => deleteProcedure(procedure)} color="error">
              Delete
            </Button>
          )}
        </div>
        <div>
          {procedure.procedure_url && (
            <a
              href={procedure.procedure_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Open link</Button>
            </a>
          )}
          {nocappUser?.profile === 'admin' && (
            <FormControlLabel
              style={{ marginLeft: 10 }}
              control={
                <Android12Switch
                  defaultChecked
                  checked={procedure.active === 1}
                  onChange={(e) =>
                    changeActiveState(procedure, e.target.checked)
                  }
                />
              }
              label={procedure.active === 1 ? 'Active' : 'Inactive'}
            />
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}

const ActiveMessage = styled('span')<{ active: boolean }>(({ active }) => ({
  paddingLeft: 15,
  paddingRight: 15,
  background: active ? 'green' : '#de3731',
  fontSize: 14,
  borderRadius: 100,
  color: 'white',
}))

export default ProcedureInfo
