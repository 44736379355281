import { styled } from '@mui/material'
import { Link } from 'react-router-dom'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import GridCard from '../../../components/PageComponents/GridCard'
import PageComponent from '../../../components/PageComponents/PageComponent'

const SparesRequestMenuPage = () => {
  const options = [
    {
      name: 'Spares request form',
      link: 'form',
    },
    {
      name: 'List of request',
      link: 'requests-list',
    },
  ]

  return (
    <PageComponent
      title="Spares request menu"
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        gap: GLOBAL_PAGE_GAP,
      }}
    >
      {options.map((item, i) => (
        <Link to={item.link} key={i}>
          <MenuItem>{item.name}</MenuItem>
        </Link>
      ))}
    </PageComponent>
  )
}

const MenuItem = styled(GridCard)({
  width: 300,
  height: 150,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 20,
})

export default SparesRequestMenuPage
