import { AppViews } from 'nocapp-types'
import {
  ActiveAndInactiveSitesPage,
  SitesByCountryAndClientPage,
  CreateSitePage,
  UpdateSitePage,
  AlertsBySitePage,
  SiteInfoPage,
  SitesIndexPage,
  SitesKmlGeneratorPage,
} from '../pages'

export const sitesViews: AppViews[] = [
  {
    path: 'sites',
    page: <SitesIndexPage />,
    access: ['noc', 'admin', 'contractor-int', 'finances', 'oym'],
  },
  {
    path: 'sites/create-site',
    page: <CreateSitePage />,
    access: ['noc', 'admin'],
  },
  {
    path: 'sites/countries',
    page: <SitesByCountryAndClientPage />,
    access: ['noc', 'admin', 'contractor-int', 'finances', 'oym'],
  },
  {
    path: 'sites/info/:cabanId',
    page: <SiteInfoPage />,
    access: ['noc', 'admin', 'contractor-int', 'finances', 'oym'],
  },
  {
    path: 'sites/info/:cabanId/alerts',
    page: <AlertsBySitePage />,
    access: ['noc', 'admin', 'contractor-int', 'finances', 'oym'],
  },
  {
    path: 'sites/info/:cabanId/update',
    page: <UpdateSitePage />,
    access: ['noc', 'admin'],
  },
  {
    path: 'sites/all',
    page: <ActiveAndInactiveSitesPage />,
    access: ['noc', 'admin', 'contractor-int', 'finances', 'oym'],
  },
  {
    path: 'sites/generate-kml',
    page: <SitesKmlGeneratorPage />,
    access: ['noc', 'admin'],
  },
]
