import { styled } from '@mui/material'
import { Visit } from 'nocapp-types'
import { MONTHS_LIST } from '../../../utils/datesHandle'

interface TodayVisitsProps {
  visits: (Visit | null)[]
}

const TodayVisits = ({ visits }: TodayVisitsProps) => {
  const getWorkInitialLetters = (work: string) => {
    switch (work) {
      case 'Preventive Maintenance':
        return 'PM'
      case 'Implementation / Dissassembly / Change':
        return 'IDC'
      case 'Survey / Audit':
        return 'SA'
      case 'Corrective Maintenace':
        return 'CM'
      default:
        return ''
    }
  }

  const getMonthDayYearDateWithTime = (string: string) => {
    const date = new Date(string)
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`
  }

  return (
    <Container>
      <Title>Today visits</Title>
      <VisitsContainer>
        {visits.length === 0 ? (
          <NoneVisits>No visits planned today</NoneVisits>
        ) : (
          visits.map((visit, i) => (
            <VisitBox key={i}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <VisitItem>{visit!.id}</VisitItem>
                <VisitItem>{visit!.site_id}</VisitItem>
                <VisitItem>{getWorkInitialLetters(visit!.work)}</VisitItem>
                <VisitItem>
                  Planned at:{' '}
                  {getMonthDayYearDateWithTime(visit!.start_datetime)}
                </VisitItem>
              </div>
            </VisitBox>
          ))
        )}
      </VisitsContainer>
    </Container>
  )
}

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})
const Title = styled('div')({
  width: '100%',
  fontSize: 22,
  fontWeight: 'bold',
  marginBottom: 10,
})
const VisitsContainer = styled('div')({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})
const VisitBox = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 15,
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 14,
  borderRadius: 10,
}))
const VisitItem = styled('div')(({ theme }) => ({
  padding: 6,
  borderRadius: 6,
  background: theme.palette.background.paper,
  fontWeight: 'bold',
}))
const NoneVisits = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}))

export default TodayVisits
