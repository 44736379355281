import { styled } from '@mui/material'
import { CountryDivisionOfCustomers } from 'nocapp-types'
import { GLOBAL_PAGE_GAP } from '../../PageComponents/constants'
// import CountriesChart from '../CountriesChart'
import CountryDivisionsTable from '../CountryDivisionsTable'

interface CountriesBodyProps {
  countriesDivisions: CountryDivisionOfCustomers[]
}

const CountriesBody = ({ countriesDivisions }: CountriesBodyProps) => {
  return (
    <Container>
      <CountryDivisionsContainer>
        <CountryDivisionsTable countriesDivisions={countriesDivisions} />
      </CountryDivisionsContainer>
      <SiteTotalContainer>
        <div
          style={{
            flex: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontWeight: 'bold', fontSize: 40 }}>
            {countriesDivisions
              .map((country) =>
                country.customers
                  .map((item) => item.count)
                  .reduce((a, b) => a + b, 0)
              )
              .reduce((a, b) => a + b, 0)}
          </span>
        </div>
        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
          <span style={{ fontWeight: 'bold' }}>Total of sites</span>
        </div>
      </SiteTotalContainer>
      <span />
      {/*  */}
      <CountriesChartContainer>
        {/* <CountriesChart
          countries={countriesDivisions.map((country) => country.country)}
          counts={countriesDivisions.map((country) => country.customers.map((item) => item.count).reduce((a, b) => a+b, 0))}
        /> */}
      </CountriesChartContainer>
      <span />
      {/*  */}
      <span />
      {/*  */}
      <span />
      <span />
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'repeat(4, 1fr)',
  gap: GLOBAL_PAGE_GAP,
})
const CardContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 15,
  padding: 10,
}))
const CountryDivisionsContainer = styled(CardContainer)(() => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 1,
  gridRowEnd: 5,
}))
const SiteTotalContainer = styled(CardContainer)(({ theme }) => ({
  widht: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    height: '100%',
  },
}))
const CountriesChartContainer = styled(CardContainer)(() => ({
  gridRowStart: 2,
  gridRowEnd: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export default CountriesBody
