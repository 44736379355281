import { styled } from '@mui/material'
import { QualityControl } from 'nocapp-types'

interface LastFormsContainerProps {
  forms: QualityControl[]
  selectForm: Function
}

const LastFormsContainer = ({ forms, selectForm }: LastFormsContainerProps) => {
  return (
    <Container>
      <Title>
        <span>Quality controls history</span>
      </Title>
      <FormList>
        {forms.map((form, i) => (
          <ListItem key={i} onClick={() => selectForm(form.id)}>
            <span>{form.id}</span>
            <span style={{ fontWeight: 'bold' }}>{form.start_timestamp}</span>
          </ListItem>
        ))}
      </FormList>
    </Container>
  )
}

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})
const Title = styled('div')(() => ({
  height: 40,
  fontWeight: 'bold',
  fontSize: 20,
  display: 'flex',
  alignItems: 'center',
}))
const FormList = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  paddingTop: 15,
  flexDirection: 'column',
  overflowY: 'auto',
}))
const ListItem = styled('div')(({ theme }) => ({
  width: '100%',
  height: 50,
  flexShrink: 0,
  flexGrow: 0,
  backgroundColor: theme.palette.background.default,
  borderRadius: 15,
  marginBottom: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  paddingLeft: 10,
  paddingRight: 10,
  cursor: 'pointer',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.7,
  },
}))

export default LastFormsContainer
