// Also grid energy
export const acEnergy = (
  rectifierCurrent: number,
  rectifierVoltage: number
): number => rectifierVoltage * rectifierCurrent

// Also Load energy
export const loadPowerComsuption = (
  essVoltage: number,
  loadCurrent: number
): number => +(essVoltage * loadCurrent)

export const solarEnergyFunction = (
  mpptVoltage: number,
  mpptCurrent: number
): number => +((mpptVoltage * mpptCurrent) / 1000)

export const downtimeHours = (loadCurrent: number) => (loadCurrent < 5 ? 1 : 0)

export const activeLoad = (loadPC: number) => loadPC || 0

export const hoursOfGenset = (rectifierCurrent: number) =>
  rectifierCurrent > 1 ? 1 : 0

export const batteryEnergy = (essVoltage: number, essCurrent: number): number =>
  +Math.abs(essVoltage * essCurrent)

export const excessEnergy = (
  loadEnergy: number,
  solarEnergy: number
): number => {
  const result = solarEnergy - loadEnergy / 1000
  return result < 0 ? 0 : +result
}

export const powerChanges = (
  loadEnergy: number,
  contractedLoad: number
): number => +(loadEnergy / 1000 / contractedLoad)

export const CO2Emission = (solarEnergy: number): number =>
  solarEnergy > 0 ? +(solarEnergy * 651.1) : 0
