import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Site } from 'nocapp-types'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import {
  FormButton,
  FormContainer,
  FormSection,
  FormSectionContainer,
  FormSectionContainerRow,
  FormSectionContainerTitle,
} from '../../../components/FormComponents'
import LoadingPage from '../../../components/PageComponents/LoadingPage'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import { Android12Switch } from '../../../components/PageComponents/SwitchComponent'
import { SITES_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import { availableCustomersAndSupervisors } from '../CreateSite/CreateSitePage.queries'
import { allSiteInfoQueries } from '../SiteInfo/SiteInfoPage.queries'
import { updateSiteQueries } from './UpdateSitePage.queries'

const UpdateSitePage = () => {
  const { nocappUser } = useNocAppAuth()
  const { cabanId } = useParams<'cabanId'>()
  const [siteInfo, setSiteInfo] = useState<Site>()
  const [loadingProps, setLoadingProps] = useState(true)
  const [country, setCountry] = useState<{ country: string }[]>([])
  const [customer, setCustomer] = useState<{ customer: string }[]>([])
  const [supervisor, setSupervisor] = useState<{ supervisor: string }[]>([])
  const [loading, setLoading] = useState(false)
  // FormControllers
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [selectedSupervisor, setSelectedSupervisor] = useState('')
  const [activeSite, setActiveSite] = useState(true)
  const [genset, setGenset] = useState(false)
  const [grid, setGrid] = useState(false)
  const [addSiteDistances, setAddSiteDistances] = useState(false)
  const [addSiteSeverity, setSiteSeverity] = useState(false)
  const [newCustomer, setNewCustomer] = useState(false)
  const [newSupervisor, setNewSupervisor] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const getCustomersAndSupervisors = async (value: string) => {
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    // Queries
    const queries = availableCustomersAndSupervisors(value)
    const [customers, supervisors] = await Promise.all([
      apiHandler.getList(queries.customers),
      apiHandler.getList(queries.supervisors),
    ])
    //
    setCustomer(customers)
    setSupervisor(supervisors)
    setSelectedCountry(value)
  }

  const getSiteInfo = useCallback(async () => {
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    // Queries
    const siteInfoQuery = allSiteInfoQueries(cabanId!)
    // First gets
    const [siteInfoData, countries]: [Site, any] = await Promise.all([
      apiHandler.getObject(siteInfoQuery.info),
      apiHandler.getList(
        `SELECT DISTINCT country FROM ${SITES_DB} ORDER BY country`
      ),
    ])
    // Set site info
    setSiteInfo(siteInfoData)
    setCountry(countries)
    // Data for controllers
    setSelectedCountry(siteInfoData.country)
    setSelectedCustomer(
      siteInfoData.customer !== null ? siteInfoData.customer : ''
    )
    setSelectedSupervisor(
      siteInfoData.supervisor !== null ? siteInfoData.supervisor : ''
    )
    setActiveSite(siteInfoData.active === 1)
    setGenset(siteInfoData.genset === 1)
    setGrid(siteInfoData.grid === 1)
    setAddSiteDistances(siteInfoData.distance !== null)
    setSiteSeverity(siteInfoData.severity !== null)
    // Supervisor and customers by country
    getCustomersAndSupervisors(siteInfoData.country)
    //
    setLoadingProps(false)
  }, [cabanId])

  useEffect(() => {
    getSiteInfo()
  }, [getSiteInfo])

  if (!siteInfo) {
    return <LoadingPage />
  }

  const updateSite = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Transform data
    const body = apiHandler.transformParametizedFormDataToJson(
      event.currentTarget
    )
    // Queries
    const queries = updateSiteQueries()
    try {
      await Promise.all([
        apiHandler.postParameterizedItem(queries.sites, [
          body.caban_id,
          body.modem_id === 'NULL' ? null : +body.modem_id,
          body.site_name === 'NULL' ? null : body.site_name,
          body.country === 'NULL' ? null : body.country,
          activeSite ? 1 : 0,
          body.commentaries === 'NULL' ? null : body.commentaries,
          siteInfo.caban_id,
        ]),
        apiHandler.postParameterizedItem(queries.siteInfo, [
          body.caban_id,
          body.contracted_load === 'NULL' ? null : body.contracted_load,
          body.customer === 'NULL' ? null : body.customer,
          body.customer_id === 'NULL' ? null : body.customer_id,
          body.owner === 'NULL' ? null : body.owner,
          body.owner_id === 'NULL' ? null : body.owner_id,
          body.type === 'NULL' ? null : body.type,
          body.supervisor === 'NULL' ? null : body.supervisor,
          siteInfo.caban_id,
        ]),
        apiHandler.postParameterizedItem(queries.location, [
          body.caban_id,
          body.latitude === 'NULL' ? null : body.latitude,
          body.longitude === 'NULL' ? null : body.longitude,
          body.address === 'NULL' ? null : body.address,
          body.zone === 'NULL' ? null : body.zone,
          siteInfo.caban_id,
        ]),
        apiHandler.postParameterizedItem(queries.devicesInfo, [
          body.caban_id,
          genset ? 1 : 0,
          grid ? 1 : 0,
          body.grid_percentage === 'NULL' ? null : body.grid_percentage,
          body.solar_panels === 'NULL' ? null : +body.solar_panels,
          body.solar_structures === 'NULL' ? null : +body.solar_structures,
          body.packs === 'NULL' ? null : +body.packs,
          body.mppts === 'NULL' ? null : +body.mppts,
          body.rectifiers === 'NULL' ? null : +body.rectifiers,
          siteInfo.caban_id,
        ]),
        apiHandler.postParameterizedItem(queries.devicesIds, [
          body.caban_id,
          body.modem_id === 'NULL' ? null : +body.modem_id,
          body.cabinet_id === 'NULL' ? null : body.cabinet_id,
          body.configuration === 'NULL' ? null : body.configuration,
          body.cabinet_type === 'NULL' ? null : body.cabinet_type,
          body.battery_model === 'NULL' ? null : body.battery_model,
          siteInfo.caban_id,
        ]),
        apiHandler.postParameterizedItem(queries.dates, [
          body.caban_id,
          body.turn_on_date === 'NULL' ? null : body.turn_on_date,
          body.alerts_activation_date === 'NULL'
            ? null
            : body.alerts_activation_date,
          body.migration_date === 'NULL' ? null : body.migration_date,
          body.commencement_date === 'NULL' ? null : body.commencement_date,
          siteInfo.caban_id,
        ]),
        apiHandler.postParameterizedItem(queries.links, [
          body.caban_id,
          body.report_link === 'NULL' ? null : body.report_link,
          siteInfo.caban_id,
        ]),
        apiHandler.postParameterizedItem(queries.update, [
          siteInfo.caban_id,
          nocappUser!.name,
          nocappUser!.username,
        ]),
      ])

      // Adding distances
      if (addSiteDistances) {
        if (siteInfo.distance) {
          await apiHandler.postParameterizedItem(queries.distancesUpdate, [
            body.caban_id,
            body.distance,
            body.travel_time,
            siteInfo.caban_id,
          ])
        } else {
          await apiHandler.postParameterizedItem(queries.distancesInsert, [
            body.caban_id,
            body.distance,
            body.travel_time,
          ])
        }
      }

      // Adding seerity
      if (addSiteSeverity) {
        if (siteInfo.severity) {
          await apiHandler.postParameterizedItem(queries.severityUpdate, [
            body.caban_id,
            body.severity,
            body.max_repair_time_total_loss,
            body.max_repair_time_partial_loss,
            body.max_repair_time_without_service_affection,
            siteInfo.caban_id,
          ])
        } else {
          await apiHandler.postParameterizedItem(queries.severityInsert, [
            body.caban_id,
            body.severity,
            body.max_repair_time_total_loss,
            body.max_repair_time_partial_loss,
            body.max_repair_time_without_service_affection,
          ])
        }
      }

      setSeverity('success')
      setMessage(`${siteInfo.caban_id} updated`)
      setOpenSnackbar(true)
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageComponent
      title={`Update ${siteInfo.caban_id}`}
      loading={loadingProps}
      scrollable
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormContainer onSubmit={updateSite}>
        {/* Site */}
        <FormSection
          useBackButton
          title="Update site"
          sections={[
            [
              <TextField
                label="Caban ID"
                required
                fullWidth
                placeholder="Obligatory field"
                name="caban_id"
                defaultValue={siteInfo.caban_id}
              />,
              <TextField
                label="Modem ID"
                fullWidth
                placeholder="****"
                type="number"
                name="modem_id"
                defaultValue={siteInfo.modem_id}
              />,
            ],
            [
              <TextField
                label="Site name"
                required
                fullWidth
                name="site_name"
                defaultValue={siteInfo.site_name}
              />,
              <TextField
                required
                fullWidth
                select
                value={selectedCountry}
                onChange={(e) => getCustomersAndSupervisors(e.target.value)}
                label="Country"
                name="country"
              >
                <MenuItem value="" />
                {country.map((value, i) => (
                  <MenuItem key={i} value={value.country}>
                    {value.country}
                  </MenuItem>
                ))}
              </TextField>,
            ],
            [
              <ActiveRow>
                <FormControlLabel
                  control={
                    <Android12Switch
                      checked={activeSite}
                      onChange={() => setActiveSite(!activeSite)}
                    />
                  }
                  label="Active site"
                />
              </ActiveRow>,
            ],
          ]}
        />
        {/* Site info */}
        <FormSection
          title="Site info."
          sections={[
            [
              // New customer inputs
              newCustomer ? (
                <TextField
                  fullWidth
                  label="Customer"
                  name="customer"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewCustomer(!newCustomer)}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Customer"
                  name="customer"
                  required
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                  value={selectedCustomer}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewCustomer(!newCustomer)}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {customer.map((value, i) => (
                    <MenuItem key={i} value={value.customer}>
                      {value.customer}
                    </MenuItem>
                  ))}
                </TextField>
              ),
              //
              <TextField
                label="Customer ID"
                fullWidth
                name="customer_id"
                defaultValue={siteInfo.customer_id}
              />,
            ],
            [
              <TextField
                label="Owner"
                fullWidth
                name="owner"
                defaultValue={siteInfo.owner}
              />,
              <TextField
                label="Owner ID"
                fullWidth
                name="owner_id"
                defaultValue={siteInfo.owner_id}
              />,
            ],
            [
              <TextField
                label="Contracted load"
                fullWidth
                placeholder=""
                name="contracted_load"
                defaultValue={siteInfo.contracted_load}
              />,
              <TextField
                label="Site type"
                fullWidth
                name="type"
                defaultValue={siteInfo.type}
              />,
            ],
            [
              // New customer inputs
              newSupervisor ? (
                <TextField
                  fullWidth
                  label="Supervisor"
                  name="supervisor"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewSupervisor(!newSupervisor)}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  required
                  onChange={(e) => setSelectedSupervisor(e.target.value)}
                  value={selectedSupervisor}
                  label="Supervisor"
                  name="supervisor"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewSupervisor(!newSupervisor)}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {supervisor.map((sup, i) => (
                    <MenuItem key={i} value={sup.supervisor}>
                      {sup.supervisor}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            ],
          ]}
        />
        {/* Location */}
        <FormSection
          title="Location"
          sections={[
            [
              <TextField
                label="Latitude"
                fullWidth
                name="latitude"
                defaultValue={siteInfo.latitude}
              />,
              <TextField
                label="Longitude"
                fullWidth
                name="longitude"
                defaultValue={siteInfo.longitude}
              />,
            ],
            [
              <TextField
                label="Zone"
                fullWidth
                name="zone"
                defaultValue={siteInfo.zone}
              />,
            ],
            [
              <TextField
                label="Address"
                fullWidth
                multiline
                rows={4}
                name="address"
                defaultValue={siteInfo.address}
              />,
            ],
          ]}
        />
        {/* Devices info */}
        <FormSection
          title="Devices info."
          sections={[
            [
              <TextField
                label="Solar panels"
                fullWidth
                type="number"
                name="solar_panels"
                defaultValue={siteInfo.solar_panels}
              />,
              <TextField
                label="Solar structures"
                fullWidth
                type="number"
                name="solar_structures"
                defaultValue={siteInfo.solar_structures}
              />,
              <TextField
                label="Grid percentage"
                placeholder="0 - 1"
                fullWidth
                name="grid_percentage"
                defaultValue={siteInfo.grid_percentage}
              />,
            ],
            [
              <TextField
                label="Packs"
                fullWidth
                type="number"
                name="packs"
                defaultValue={siteInfo.packs}
              />,
              <TextField
                label="MPPTs"
                fullWidth
                type="number"
                name="mppts"
                defaultValue={siteInfo.mppts}
              />,
              <TextField
                label="Rectifiers"
                fullWidth
                type="number"
                name="rectifiers"
                defaultValue={siteInfo.rectifiers}
              />,
            ],
            [
              <FormControlLabel
                control={
                  <Android12Switch
                    value={grid}
                    onChange={() => setGrid(!grid)}
                  />
                }
                label="Grid"
              />,
              <FormControlLabel
                control={
                  <Android12Switch
                    value={genset}
                    onChange={() => setGenset(!genset)}
                  />
                }
                label="Genset"
              />,
            ],
          ]}
        />
        {/* Devices Ids */}
        <FormSection
          title="Devices IDs"
          sections={[
            [
              <TextField
                label="Cabinet SN"
                fullWidth
                name="cabinet_id"
                defaultValue={siteInfo.cabinet_id}
              />,
              <TextField
                label="Configuration"
                fullWidth
                name="configuration"
                defaultValue={siteInfo.configuration}
              />,
            ],
            [
              <TextField
                label="Cabinet type"
                fullWidth
                name="cabinet_type"
                defaultValue={siteInfo.cabinet_type}
              />,
              <TextField
                label="Battery model"
                fullWidth
                name="battery_model"
                defaultValue={siteInfo.battery_model}
              />,
            ],
          ]}
        />
        {/* Dates */}
        <FormSection
          title="Dates"
          sections={[
            [
              <TextField
                label="Turn on date"
                fullWidth
                focused
                type="date"
                name="turn_on_date"
                defaultValue={siteInfo.turn_on_date}
              />,
              <TextField
                label="Alerts activation"
                fullWidth
                focused
                type="date"
                name="alerts_activation_date"
                defaultValue={siteInfo.alerts_activation_date}
              />,
            ],
            [
              <TextField
                label="Migration date"
                fullWidth
                focused
                type="date"
                name="migration_date"
                defaultValue={siteInfo.migration_date}
              />,
              <TextField
                label="Commencement date"
                fullWidth
                focused
                type="date"
                name="commencement_date"
                defaultValue={siteInfo.commencement_date}
              />,
            ],
          ]}
        />
        {/* Links */}
        <FormSection
          title="Links"
          sections={[
            [
              <TextField
                label="Site information link"
                fullWidth
                name="report_link"
                defaultValue={siteInfo.report_link}
              />,
            ],
          ]}
        />
        {/* Commentaries */}
        <FormSection
          title="Commentaries"
          sections={[
            [
              <TextField
                label="Commentaries"
                placeholder="Place here your comments"
                multiline
                rows={5}
                fullWidth
                name="commentaries"
                defaultValue={siteInfo.commentaries}
              />,
            ],
          ]}
        />
        {/* Site distances */}
        <FormSectionContainer>
          <FormSectionContainerTitle>
            <span>Site Distances</span>
            <FormControlLabel
              control={
                <Android12Switch
                  checked={addSiteDistances}
                  onChange={() => setAddSiteDistances(!addSiteDistances)}
                />
              }
              label="Add site distances"
            />
          </FormSectionContainerTitle>
          {addSiteDistances && (
            <FormSectionContainerRow>
              <TextField
                label="Distance"
                placeholder="Distance in km"
                fullWidth
                name="distance"
                defaultValue={siteInfo.distance}
              />
              <TextField
                label="Travel time"
                fullWidth
                placeholder="Travel time in H:m"
                name="travel_time"
                defaultValue={siteInfo.travel_time}
              />
            </FormSectionContainerRow>
          )}
        </FormSectionContainer>
        {/* Severity */}
        <FormSectionContainer>
          <FormSectionContainerTitle>
            <span>Site Severity</span>
            <FormControlLabel
              control={
                <Android12Switch
                  checked={addSiteSeverity}
                  onChange={() => setSiteSeverity(!addSiteSeverity)}
                />
              }
              label="Add site severity"
            />
          </FormSectionContainerTitle>
          {addSiteSeverity && (
            <>
              <FormSectionContainerRow>
                <TextField
                  label="Severity"
                  fullWidth
                  name="severity"
                  defaultValue={siteInfo.severity}
                />
              </FormSectionContainerRow>
              <FormSectionContainerRow>
                <TextField
                  label="Repair time with total loss of service"
                  fullWidth
                  name="max_repair_time_total_loss"
                  defaultValue={siteInfo.max_repair_time_total_loss}
                />
                <TextField
                  label="Repair time with partial loss of service"
                  fullWidth
                  name="max_repair_time_partial_loss"
                  defaultValue={siteInfo.max_repair_time_partial_loss}
                />
                <TextField
                  label="Repair time without service affection"
                  fullWidth
                  name="max_repair_time_without_service_affection"
                  defaultValue={
                    siteInfo.max_repair_time_without_service_affection
                  }
                />
              </FormSectionContainerRow>
            </>
          )}
        </FormSectionContainer>
        {/* Send */}
        <FormButton loading={loading} title="Update site" />
      </FormContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

const ActiveRow = styled('div')({
  width: '100%',
  display: 'flex',
  marginTop: 8,
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontWeight: 'bold',
})

export default UpdateSitePage
