import {
  DEVICES_IDS_DB,
  DEVICES_INFO_DB,
  SITES_DATES_DB,
  SITES_DB,
  SITES_DISTANCES_DB,
  SITES_INFO_DB,
  SITES_LINKS_DB,
  SITES_LOCATION_DB,
  SITES_SEVERITY_DB,
  SITES_UPDATES_DB,
} from '../../../services/api/databases'

export const allSiteInfoQueries = (id: string) => {
  return {
    info: `SELECT
        *
      FROM ${SITES_DB}
      LEFT JOIN
        ${SITES_INFO_DB}
      ON
        ${SITES_DB}.caban_id = ${SITES_INFO_DB}.site_id
      LEFT JOIN
        ${DEVICES_INFO_DB}
      ON
        ${SITES_DB}.caban_id = ${DEVICES_INFO_DB}.site_id
      LEFT JOIN
        ${DEVICES_IDS_DB}
      ON
        ${SITES_DB}.caban_id = ${DEVICES_IDS_DB}.site_id
      LEFT JOIN
        ${SITES_DATES_DB}
      ON
        ${SITES_DB}.caban_id = ${SITES_DATES_DB}.site_id
      LEFT JOIN
        ${SITES_LINKS_DB}
      ON
        ${SITES_DB}.caban_id = ${SITES_LINKS_DB}.site_id
      LEFT JOIN
        ${SITES_LOCATION_DB}
      ON
        ${SITES_DB}.caban_id = ${SITES_LOCATION_DB}.site_id
      LEFT JOIN
        ${SITES_DISTANCES_DB}
      ON
        ${SITES_DB}.caban_id = ${SITES_DISTANCES_DB}.site_id
      LEFT JOIN
        ${SITES_SEVERITY_DB}
      ON
        ${SITES_DB}.caban_id = ${SITES_SEVERITY_DB}.site_id
      WHERE
        ${SITES_DB}.caban_id = '${id}'`,
    lastUpdate: `SELECT * FROM ${SITES_UPDATES_DB} WHERE site_id = '${id}' ORDER BY update_timestamp DESC LIMIT 1`,
  }
}

export const deleteSiteQueries = () => ({
  sites: `DELETE FROM ${SITES_DB} WHERE caban_id = ?`,
  sitesInfo: `DELETE FROM ${SITES_INFO_DB} WHERE site_id = ?`,
  location: `DELETE FROM ${SITES_LOCATION_DB} WHERE site_id = ?`,
  devicesInfo: `DELETE FROM ${DEVICES_INFO_DB} WHERE site_id = ?`,
  devicesIds: `DELETE FROM ${DEVICES_IDS_DB} WHERE site_id = ?`,
  dates: `DELETE FROM ${SITES_DATES_DB} WHERE site_id = ?`,
  links: `DELETE FROM ${SITES_LINKS_DB} WHERE site_id = ?`,
  distances: `DELETE FROM ${SITES_DISTANCES_DB} WHERE site_id = ?`,
  severity: `DELETE FROM ${SITES_SEVERITY_DB} WHERE site_id = ?`,
})
