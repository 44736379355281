import { AppViews } from 'nocapp-types'
import { ContactsPage } from '../pages'

export const contactsViews: AppViews[] = [
  {
    path: 'contacts',
    page: <ContactsPage />,
    access: ['noc', 'admin', 'finances', 'oym'],
  },
]
