import { AppViews } from 'nocapp-types'
import {
  BasicReportPage,
  BasicTemplatePage,
  ClaroTemplatePage,
  LlaReportsPage,
  ReportsMenuPage,
  SiteReportsCharts,
  TigoTemplatePage,
  UploadSiteDataPage,
  DeleteSiteData,
} from '../pages'

export const reportsViews: AppViews[] = [
  {
    path: 'reports',
    page: <ReportsMenuPage />,
    access: ['noc', 'admin', 'oym'],
  },
  {
    path: 'reports/lla-report',
    page: <LlaReportsPage />,
    access: ['noc', 'admin', 'oym'],
  },
  {
    path: 'reports/lla-report/:cabanId/charts',
    page: <SiteReportsCharts />,
    access: ['noc', 'admin', 'oym'],
  },
  {
    path: 'reports/upload',
    page: <UploadSiteDataPage />,
    access: ['noc', 'admin'],
  },
  {
    path: 'reports/delete',
    page: <DeleteSiteData />,
    access: ['noc', 'admin'],
  },
  {
    path: 'reports/basic-report',
    page: <BasicReportPage />,
    access: ['noc', 'admin', 'oym'],
  },
  {
    path: 'reports/basic-report/:cabanId/template',
    page: <BasicTemplatePage />,
    access: ['noc', 'admin', 'oym'],
  },
  {
    path: 'reports/basic-report/:cabanId/claro-template',
    page: <ClaroTemplatePage />,
    access: ['noc', 'admin', 'oym'],
  },
  {
    path: 'reports/basic-report/:cabanId/tigo-template',
    page: <TigoTemplatePage />,
    access: ['noc', 'admin', 'oym'],
  },
]
