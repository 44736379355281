import { Button, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  GetAppRounded as ExportIcon,
  UploadRounded as ImportIcon,
  InfoRounded as AlertpediaIcon,
} from '@mui/icons-material'
import { Alert } from 'nocapp-types'

interface AlertsTopButtonsProps {
  alerts: Alert[]
  setOpenAlertpedia: Function
  canUploadAlerts: boolean
}

const AlertsTopButtons = ({
  alerts,
  setOpenAlertpedia,
  canUploadAlerts,
}: AlertsTopButtonsProps) => {
  const navigate = useNavigate()

  const exportAlerts = async () => {
    const currentDate = new Date()
    const dateLog = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
    let csv =
      'id, id_site, site, site_name, project, country, message, priority, state, created_at_date, hour, year, week, month, day, updated_at_date, alert_duration, count, owner, shift, comments, teams, handoff\n'
    alerts.map((alert) => {
      const rowData = [
        alert.id,
        alert.id_site,
        alert.site,
        alert.site_name,
        alert.project,
        alert.country,
        alert.message!.replace(/\n/g, '').replace(/\r/g, ''),
        alert.priority,
        alert.state,
        alert.created_at_date,
        alert.hour,
        alert.year,
        alert.week,
        alert.month,
        alert.day,
        alert.updated_at_date,
        alert.alert_duration,
        alert.count,
        alert.owner,
        alert.shift,
        alert.comments,
        alert.teams,
        alert.handoff,
      ]
      const row = rowData.join(',').replaceAll('\n', '')
      csv += `${row}\n`
      return alert
    })
    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`
    hiddenElement.target = '_blank'
    hiddenElement.download = `Alerts log ${dateLog}.csv`
    hiddenElement.click()
  }

  return (
    <Container>
      <BarButton onClick={exportAlerts} startIcon={<ExportIcon />}>
        Export alerts CSV
      </BarButton>
      {canUploadAlerts && (
        <BarButton
          onClick={() => navigate('upload')}
          startIcon={<ImportIcon />}
        >
          Import alerts
        </BarButton>
      )}
      <BarButton
        onClick={() => setOpenAlertpedia(true)}
        startIcon={<AlertpediaIcon />}
      >
        Alertpedia
      </BarButton>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
})
const BarButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  color: theme.palette.text.secondary,
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.8,
    background: theme.palette.divider,
  },
}))

export default AlertsTopButtons
