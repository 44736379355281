export const qualityControlConcreteBaseItems = [
  {
    id: 1,
    action:
      'Todas las losas de concreto deben colocarse exactamente como en los dibujos',
    comments:
      'Las losas de concreto deben construirse y colocarse con precisión',
    photos: [1, 2, 3],
  },
  {
    id: 2,
    action:
      'Todas las bases de concreto deben tener barras de refuerzo tanto en posición horizontal como vertical',
    comments: 'Ver planos de diseno',
    photos: [1, 2, 3],
  },
  {
    id: 3,
    action:
      'La estructura solar debe tener una tubería de PVC en la losa de concreto para el cableado solar/conexión a tierra a la caja de inspección eléctrica (consulte el dibujo para el detalle 4)',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 4,
    action:
      'La caja de inspección eléctrica debe estar diseñada como se indica en el dibujo',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 5,
    action:
      'La base de concreto para el gabinete Cabán debe tener tubería de PVC para cable solar',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 6,
    action:
      'La base de concreto para el Gabinete Caban debe tener tubería de PVC para conexión a tierra',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 7,
    action: 'Las tuberías de PVC deben enterrarse en losas de concreto',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 8,
    action: 'No se debe exponer ninguna tubería de PVC',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 9,
    action:
      'Las losas de concreto deben estar centradas, alineadas, rectas y niveladas ',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 10,
    action: 'Todas las losas de concreto deben tener acabado segun diseno',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 11,
    action:
      'Todas las losas de concreto deben tener bordes redondeados (biselados/biselados) ',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 12,
    action:
      'Las varillas roscadas deben tener la longitud correcta como se muestra en los planos de dise~no',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 13,
    action:
      'Todas las varillas roscadas no deben estar expuestas más de 1.5”-2” desde la parte superior del concreto.',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 14,
    action:
      'Toda la obra civil esta tal como se indican en los planos completados y presentados',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 15,
    action: 'La zanja debe hacerse a la profundidad correcta como en el dibujo',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 16,
    action: 'Se retiraron todas las áreas de mezcla de cemento',
    comments:
      'Restaurar el sitio a su estado original o mejor; no dañe la vegetación (área de césped)',
    photos: [1, 2, 3],
  },
  {
    id: 17,
    action:
      'Cuando complete la excavación de zanjas, la grava quedo como la norma establece',
    comments:
      'No reemplace con tierra o arena; restaurar el sitio a su estado original o mejor',
    photos: [1, 2, 3],
  },
]

export const qualityControlSolarStructureItems = [
  {
    id: 1,
    action:
      'Asegúrese de que la estructura esté construida con la orientación correcta',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 2,
    action: 'Todos los paneles deben tener la prevención de oxidación aplicada',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 3,
    action: 'Todo el hardware debe estar presente y en la pila correcta',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 4,
    action: 'Todo el hardware debe apretarse adecuadamente',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 5,
    action:
      'La estructura de soporte de energía solar fotovoltaica debe estar nivelada y los montantes deben estar rectos',
    comments: '',
    photos: [1, 2, 3],
  },

  {
    id: 6,
    action:
      'Utilizo la herramienta correcta par la colocacion de los conectores MC4',
    comments: 'Revisar 2',
    photos: [1, 2, 3],
  },
  {
    id: 7,
    action: 'Los paneles instalados son de la potencia requerida',
    comments: 'Colocar fotografia de la placa de caracteristicas del panel',
    photos: [1, 2, 3],
  },
]

export const qualityControlEarthingSystem = [
  {
    id: 1,
    action:
      'Cual es el valor de la resistencia del sistema de pueta a tierra en la conexion en torre',
    comments: '',
    photos: [1, 2, 3],
    input: true,
  },
  {
    id: 2,
    action:
      'Cual es el valor de la resistencia del sistema de pueta a tierra en la conexion en la barra de tierra de equipos',
    comments: '',
    photos: [1, 2, 3],
    input: true,
  },
  {
    id: 3,
    action:
      'Cual es el valor de la resistencia del sistema de pueta a tierra en la conexion en la malla perimetral del sitio',
    comments: '',
    photos: [1, 2, 3],
    input: true,
  },
  {
    id: 4,
    action: 'Conexión a tierra instalada según el diseño',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 5,
    action:
      'Los cables de conexión a tierra del tamaño y tipo correctos están correctamente instalados sin hebras de cables fuera de las conexiones de conexión a tierra sin brillos excesivos',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 6,
    action:
      'Todo el equipo está correctamente conectado a tierra según el estándar NEC/GEI local de Jamaica',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 7,
    action:
      'Los terminales de conexión a tierra son del tamaño y tipo adecuados',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 8,
    action:
      'Todas las conexiones a tierra están recubiertas con NO-OX-ID o grasa de contacto eléctrico similar',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 9,
    action:
      'Sellado hermético de 1/2" instalado sobre cables de tierra de cobre desnudo',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 10,
    action:
      'Conexiones exotérmicas utilizadas para conectar a la varilla de tierra',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 11,
    action:
      'Orejetas de dos orificios de barril largo que se utilizan para conectar a la barra de tierra principal',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 12,
    action: 'Tuerca y perno apropiados utilizados para la puesta a tierra',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 13,
    action:
      'Las lengüetas de conexión a tierra deben estar unidas al acero expuesto',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 14,
    action: 'Tapas de PVC instaladas sobre recintos de varillas de tierra',
    comments: '',
    photos: [1, 2, 3],
  },
]

export const qualityControlEquipment = [
  {
    id: 1,
    action: 'Todo el equipo y los paneles solares están libres de daños',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 2,
    action:
      'Todo el equipo y los paneles solares están conectados correctamente',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 3,
    action: 'Todos los equipos instalados son del tipo/material requerido',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 4,
    action:
      'Todas las tuercas y pernos están apretados según las especificaciones',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 5,
    action:
      'Todas las estructuras que estan instaldas cumplen con lo requerido segun diseno',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 6,
    action: 'Marcas de torsión aplicadas a todos los pernos/tuercas',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 7,
    action: 'Se usa el apilamiento de hardware adecuado',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 8,
    action: 'Se usa silicona/espuma donde se requiere',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 9,
    action:
      'El sitio despues de toda la instalacion fue restaurado a una condición adecuada',
    comments: 'Grava, Tierra, etc',
    photos: [1, 2, 3],
  },
  {
    id: 10,
    action: 'Se eliminó toda la basura del sitio',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 11,
    action: 'Se elimino la vegetación de las cercas y el alambre de púas',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 12,
    action: 'Se eliminó todo el cemento en áreas donde se realizo la mezcla',
    comments: '',
    photos: [1, 2, 3],
  },
]

export const qualityControlElectricalInstallation = [
  {
    id: 1,
    action:
      'El tamaño y el tipo de conducto y los accesorios están correctamente instalados y conectados al conducto y a los gabinetes según el diseño',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 2,
    action: 'Todo el conducto está enterrado a la profundidad adecuada',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 3,
    action: 'Todo el conducto tiene el soporte adecuado',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 4,
    action: 'Todo el conducto está enrutados correctamente según el diseño',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 5,
    action:
      'Todos los puntos de entrada del conducto están sellados correctamente',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 6,
    action:
      'Se usa el accesorio correcto entre el conducto flexible y la tubería de PVC ',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 7,
    action: 'Todos los cables se sujetan correctamente con abrazaderas',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 8,
    action: 'Todos los cables están redondeados lejos de los bordes afilados',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 9,
    action:
      'Los casquillos de PVC del conector del conducto están instalados para proteger los cables ',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 10,
    action: 'Todos los cables están libres de daños, tensión o tensión',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 11,
    action:
      'Todo el radio de curvatura del cable está dentro de las especificaciones',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 12,
    action:
      'Todas las terminales de los cables están engarzadas correctamente con una herramienta de enganche',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 13,
    action: 'Se usa termorretráctil en todas las terminales engarzadas',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 14,
    action:
      'Todos los cables están debidamente etiquetados con una máquina etiquetadora',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 15,
    action:
      'Todos los interruptores están debidamente etiquetados etiquetados con una máquina etiquetadora',
    comments: '',
    photos: [1, 2, 3],
  },
  {
    id: 16,
    action:
      'Todas las cajas y gabinetes están etiquetados con una máquina etiquetadora',
    comments: '',
    photos: [1, 2, 3],
  },
]
