import { styled } from '@mui/material'
import { CountryDivisionOfCustomers } from 'nocapp-types'

interface CountryDivisionsTableProps {
  countriesDivisions: CountryDivisionOfCustomers[]
}

const CountryDivisionsTable = ({
  countriesDivisions,
}: CountryDivisionsTableProps) => {
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <TableTitles>Country</TableTitles>
            <TableTitles>Customers</TableTitles>
            <TableTitles>Count</TableTitles>
            <TableTitles>Total</TableTitles>
          </tr>
        </thead>
        <tbody>
          {countriesDivisions.map((country, i) => (
            <tr key={i}>
              <Country>{country.country}</Country>
              <CustomersContainer>
                <table>
                  <tbody>
                    {country.customers.map((customer, j) => (
                      <tr key={j}>
                        <Customer>{customer.customer}</Customer>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CustomersContainer>
              <CustomersContainer>
                <table>
                  <tbody>
                    {country.customers.map((customer, j) => (
                      <tr key={j}>
                        <Customer>{customer.count} sites</Customer>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CustomersContainer>
              <Country>
                {country.customers
                  .map((item) => item.count)
                  .reduce((a, b) => a + b, 0)}{' '}
                sites
              </Country>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

const Container = styled('table')({
  width: '100%',
  height: '100%',
  display: 'flex',
  overflow: 'auto',
})
const Table = styled('table')({
  width: '100%',
  maxHeight: '100%',
  textAlign: 'center',
})
const TableTitles = styled('th')(({ theme }) => ({
  width: '25%',
  borderBottom: `2px solid ${theme.palette.background.default}`,
}))
const Country = styled('td')(({ theme }) => ({
  width: '25%',
  textAlign: 'center',
  fontWeight: 'bold',
  borderBottom: `2px solid ${theme.palette.background.default}`,
  padding: 5,
}))
const CustomersContainer = styled('td')(({ theme }) => ({
  width: '25%',
  fontWeight: 'bold',
  borderBottom: `2px solid ${theme.palette.background.default}`,
  padding: 5,
  textAlign: 'center',
}))
const Customer = styled('td')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  fontWeight: 'bold',
  backgroundColor: theme.palette.background.default,
  borderRadius: 6,
  padding: 5,
  marginBottom: 5,
  margin: 'auto',
  fontSize: 14,
}))

export default CountryDivisionsTable
