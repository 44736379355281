import { Dialog, DialogContent, DialogTitle, styled } from '@mui/material'
import { BasicReportKpis } from 'nocapp-types'

interface BasicReportKPIsProps {
  open: boolean
  setOpen: Function
  kpi: BasicReportKpis
}

const PRECISION = 2

const BasicReportKPIs = ({ open, setOpen, kpi }: BasicReportKPIsProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 22 }}>
        Site KPI
      </DialogTitle>
      <DialogContent>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <CellStyle>Avg Load [kW]</CellStyle>
              <CellStyle>{kpi.avgLoad.toFixed(PRECISION)}</CellStyle>
            </tr>
            <tr>
              <CellStyle>Total Solar Energy Generation [kWh]</CellStyle>
              <CellStyle>
                {kpi.totalSolarEnergyGeneration.toFixed(PRECISION)}
              </CellStyle>
            </tr>
            <tr>
              <CellStyle>Total Battery Energy Throughput [kWh]</CellStyle>
              <CellStyle>
                {kpi.totalBatteryThroughput.toFixed(PRECISION)}
              </CellStyle>
            </tr>
            <tr>
              <CellStyle>Total Grid Energy Usage [kWh]</CellStyle>
              <CellStyle>
                {kpi.totalGridEnergyUsage.toFixed(PRECISION)}
              </CellStyle>
            </tr>
            <tr>
              <CellStyle>Total Load Energy Consumption [kWh]</CellStyle>
              <CellStyle>
                {kpi.totalLoadEnergyConsumption.toFixed(PRECISION)}
              </CellStyle>
            </tr>
            <tr>
              <CellStyle>Grid Availability</CellStyle>
              <CellStyle>
                {(kpi.gridAvailability * 100).toFixed(PRECISION)}%
              </CellStyle>
            </tr>
            <tr>
              <CellStyle>GPA (Generated Power Availability)</CellStyle>
              <CellStyle>{(kpi.gpa * 100).toFixed(PRECISION)}%</CellStyle>
            </tr>
            <tr>
              <CellStyle>% Energy Reduction from the Grid</CellStyle>
              <CellStyle>
                {(kpi.energyReduction * 100).toFixed(PRECISION)}%
              </CellStyle>
            </tr>
            <tr>
              <CellStyle>Avg Solar Generation per day [kWh]</CellStyle>
              <CellStyle>{kpi.avgSolarGeneration.toFixed(PRECISION)}</CellStyle>
            </tr>
            <tr>
              <CellStyle>Avg Grid Energy Usage per day [kWh]</CellStyle>
              <CellStyle>{kpi.avgGridEnergyUsage.toFixed(PRECISION)}</CellStyle>
            </tr>
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  )
}

const CellStyle = styled('th')(({ theme }) => ({
  padding: 5,
  borderBottom: `1px solid ${theme.palette.background.default}`,
}))

export default BasicReportKPIs
