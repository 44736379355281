import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { TaskTicket } from 'nocapp-types'
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded'
import { TASK_TICKETS_DB } from '../../../services/api/databases'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import ApiHandler, { MailServiceBody } from '../../../services/api/apiHandler'
import { MONTHS_LIST } from '../../../utils/datesHandle'

interface TicketInfoProps {
  open: boolean
  setOpen: Function
  updateTicketsList: Function
  ticket: TaskTicket
}

const TicketInfo = ({
  open,
  setOpen,
  updateTicketsList,
  ticket,
}: TicketInfoProps) => {
  const { nocappUser } = useNocAppAuth()
  const [loading, setLoading] = useState(false)
  const [commentaries, setCommentaries] = useState('')

  const getMonthDayYearDate = (string: string) => {
    const date = new Date(string)
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`
  }

  const dennyTicket = async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    //
    const mailBody: MailServiceBody = {
      body: `Your task ticket #${ticket.id} has been denied by ${
        nocappUser!.name
      }\n\n${commentaries !== '' ? `Message: ${commentaries}` : ''}`,
      subject: `Task ticket #${ticket.id} Denied`,
      destination: ticket.email,
      headers: `To: ${ticket.assigned_name} <${ticket.email}> \r\nFrom: Task Tickets NOC app <tasktickets@nocapp.com> \r\nCc: ${ticket.creator_email} \r\n`,
    }
    await Promise.all([
      apiHandler.postItem(
        `UPDATE ${TASK_TICKETS_DB} SET end_timestamp=NULL, creator_response='${commentaries}' WHERE id = '${ticket.id}'`
      ),
      apiHandler.sendMail(mailBody),
    ])
    updateTicketsList()
    setLoading(false)
    setOpen(false)
  }

  const endTicket = async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    const date = new Date()
    const endDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    //
    const mailBody: MailServiceBody = {
      body: `Task ticket #${ticket.id} has been finished by ${
        ticket.assigned_name
      } and is waiting for your approval\n\n${
        commentaries !== '' ? `Message: ${commentaries}` : ''
      }\n\n To resolve this ticket enter https://solucionesv.xyz/_Caban/app/#/task-tickets in the my task ticket tab`,
      subject: `Task ticket #${ticket.id} finished`,
      destination: ticket.creator_email,
      headers: `To: ${ticket.creator_name} <${ticket.assigned_name}> \r\nFrom: Trouble Tickets NOC app <troubletickets@nocapp.com> \r\nCc: ${ticket.email} \r\n`,
    }
    await Promise.all([
      apiHandler.postItem(
        `UPDATE ${TASK_TICKETS_DB} SET end_timestamp='${endDate}', user_response='${commentaries}' WHERE id = '${ticket.id}'`
      ),
      apiHandler.sendMail(mailBody),
    ])
    updateTicketsList()
    setLoading(false)
    setOpen(false)
  }

  const approveTicket = async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    const date = new Date()
    const startDate = new Date(ticket.created_at)
    const endDate = new Date(ticket.end_timestamp)
    const approvedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    const sla = (endDate.getTime() - startDate.getTime()) / (1000 * 60)
    //
    const mailBody: MailServiceBody = {
      body: `Task ticket #${ticket.id} has been approved by ${
        ticket.creator_name
      }, your SLA is ${(+sla / 60).toFixed(2)} hours`,
      subject: `Task ticket #${ticket.id} approved`,
      destination: ticket.email,
      headers: `To: ${ticket.assigned_name} <${ticket.email}> \r\nFrom: Task Tickets NOC app <tasktickets@nocapp.com> \r\nCc: ${ticket.creator_email} \r\n`,
    }
    await Promise.all([
      apiHandler.postItem(
        `UPDATE ${TASK_TICKETS_DB} SET approved_at='${approvedDate}', sla='${sla.toFixed(
          2
        )}' WHERE id = '${ticket.id}'`
      ),
      apiHandler.sendMail(mailBody),
    ])
    updateTicketsList()
    setLoading(false)
    setOpen(false)
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <span>{ticket.subject}</span>
        {ticket.approved_at !== '' && <CheckCircleIcon color="success" />}
      </DialogTitle>
      <DialogContent>
        <TicketDescription>{ticket.description}</TicketDescription>
        <DateActionsRow>
          {ticket.end_timestamp !== null ? (
            <span>Done at: {getMonthDayYearDate(ticket.due_timestamp)}</span>
          ) : (
            <span>Due to: {getMonthDayYearDate(ticket.due_timestamp)}</span>
          )}
          <div style={{ display: 'flex', gap: 10 }}>
            {(ticket.created_by === nocappUser!.username ||
              (ticket.end_timestamp !== null &&
                (nocappUser!.profile === 'admin' ||
                  nocappUser!.profile === 'noc'))) && (
              <LoadingButton
                variant="contained"
                loading={loading}
                color="error"
                onClick={dennyTicket}
                disabled={
                  ticket.end_timestamp === null
                    ? true
                    : ticket.approved_at !== null
                }
              >
                Denny ticket
              </LoadingButton>
            )}
            {ticket.created_by === nocappUser!.username ||
            (ticket.end_timestamp !== null &&
              (nocappUser!.profile === 'admin' ||
                nocappUser!.profile === 'noc')) ? (
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={approveTicket}
                disabled={
                  ticket.end_timestamp === null
                    ? true
                    : ticket.approved_at !== null
                }
              >
                Approve ticket
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={endTicket}
                disabled={ticket.end_timestamp !== null}
              >
                End ticket
              </LoadingButton>
            )}
          </div>
        </DateActionsRow>
        <div style={{ marginTop: 10 }}>
          {ticket.user_response !== null &&
            `User message: ${ticket.user_response}`}
        </div>
        <div style={{ marginTop: 10 }}>
          {ticket.creator_response !== null &&
            `Creator message: ${ticket.creator_response}`}
        </div>
        <TextField
          style={{ marginTop: 10 }}
          fullWidth
          label="Commentaries"
          value={commentaries}
          onChange={(e) => setCommentaries(e.target.value)}
          multiline
          rows={5}
        />
      </DialogContent>
      <CreatedInfo>
        <span>
          {ticket.approved_at !== null && `SLA: ${ticket.sla.toFixed(2)} min.`}
        </span>
        <div>
          <span>Created by: {ticket.creator_name}</span>
          <span>Assignet to: {ticket.assigned_name}</span>
          <span>Created at: {getMonthDayYearDate(ticket.created_at)}</span>
        </div>
      </CreatedInfo>
    </Dialog>
  )
}

const TicketDescription = styled('span')(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  fontSize: 15,
  textAlign: 'justify',
}))
const CreatedInfo = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  fontSize: 14,
}))
const DateActionsRow = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 10,
  fontWeight: 'bold',
})

export default TicketInfo
