import { LoadingButton } from '@mui/lab'
import { MenuItem, styled, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import GridCard from '../../../components/PageComponents/GridCard'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import { SITES_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'

const SitesKmlGeneratorPage = () => {
  const [countries, setCountries] = useState<{ country: string }[]>([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [loading, setLoading] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const getData = async () => {
      const apiHandler = new ApiHandler()
      const data = await apiHandler.getList(
        `SELECT DISTINCT country FROM ${SITES_DB} ORDER BY country`
      )

      setCountries(data)
    }

    getData()
  }, [])

  const generateLink = () => {
    setLoading(true)
    if (selectedCountry === '') {
      setSeverity('warning')
      setMessage('You need to choose a country')
      setOpenSnackbar(true)
      return
    }
    const serverName = window.location.origin
    const kmlLink = `${serverName}/api/sites/kml/?country=${selectedCountry}`
    navigator.clipboard.writeText(kmlLink)
    setSeverity('success')
    setMessage('Network link copied')
    setOpenSnackbar(true)
    setLoading(false)
  }

  return (
    <PageComponent
      title="Sites KML"
      loading={loading}
      style={{
        justifyContent: 'center',
      }}
    >
      <UploadFileContainer>
        <Title>Network link for sites (Google earth)</Title>
        <div style={{ marginBottom: 20 }}>
          <span>
            This will generate a network link for Google Earth that will list
            all sites from a country or all sites from all countries
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 15,
          }}
        >
          <TextField
            style={{ width: '100%' }}
            value={selectedCountry}
            select
            onChange={(e) => setSelectedCountry(e.target.value)}
            label="Select a country"
          >
            <MenuItem value="" />
            {countries.map((country, i) => (
              <MenuItem value={country.country} key={i}>
                {country.country}
              </MenuItem>
            ))}
            <MenuItem value="all_sites">All sites</MenuItem>
          </TextField>
        </div>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={generateLink}
        >
          Generate KML link
        </LoadingButton>
      </UploadFileContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

const UploadFileContainer = styled(GridCard)(({ theme }) => ({
  width: '40%',
  height: 'border-box',
  marginBottom: 20,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
const Title = styled('span')({
  fontSize: 25,
  fontWeight: 'bolder',
  marginBottom: 15,
})

export default SitesKmlGeneratorPage
