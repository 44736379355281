import { PaletteMode } from '@mui/material'
import { createContext } from 'react'

interface ThemeModeContextProps {
  mode: PaletteMode
  changeThemeMode: Function
}

const ThemeModeContext = createContext<Partial<ThemeModeContextProps>>({})

export default ThemeModeContext
