import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { Site, User } from 'nocapp-types'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import {
  FormButton,
  FormContainer,
  FormSection,
} from '../../../components/FormComponents'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import ApiHandler from '../../../services/api/apiHandler'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import {
  getInsertQuery,
  getCountriesAndCustomersQuery,
  getSitesByCountryQuery,
} from './CreateIncidentPage.queries'

const CreateIncidentPage = () => {
  const { nocappUser } = useNocAppAuth()
  const [countries, setCountries] = useState<{ country: string }[]>([])
  const [customers, setCustomers] = useState<{ customer: string }[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [sites, setSites] = useState<Site[]>([])
  const [loadingProps, setLoadingProps] = useState(false)
  const [loading, setLoading] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const worksToDo = [
    'Migration',
    'Turn on caban system',
    'Corrective Maintenace',
  ]

  const getProps = useCallback(async () => {
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    // Get queries
    const queries = getCountriesAndCustomersQuery()
    // Get datas
    const [countriesData, customersData, usersData] = await Promise.all([
      apiHandler.getList(queries.countries),
      apiHandler.getList(queries.customers),
      apiHandler.getList(queries.users),
    ])
    setCountries(countriesData)
    setCustomers(customersData)
    setUsers(usersData)
    setLoadingProps(false)
  }, [])

  const getSites = async (country: string) => {
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    const sitesQuery = getSitesByCountryQuery(country)
    const data = await apiHandler.getList(sitesQuery)
    setSites(data)
    setLoadingProps(false)
  }

  useEffect(() => {
    getProps()
  }, [getProps])

  const createIncidentHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Transform data
    const body = apiHandler.transformFormDataToJson(event.currentTarget)
    // Get insert query
    const insertQuery = getInsertQuery(body, nocappUser!)
    try {
      // Insert
      await apiHandler.postItem(insertQuery)
      setSeverity('success')
      setMessage('The incident for this sites has been created')
      setOpenSnackbar(true)
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageComponent
      title="Create incident"
      loading={loadingProps}
      scrollable
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormContainer onSubmit={createIncidentHandler}>
        <FormSection
          useBackButton
          title="Create incident"
          sections={[
            [
              <TextField
                select
                fullWidth
                required
                label="Select a country"
                onChange={(e) => getSites(e.target.value)}
                name="country"
              >
                <MenuItem value="" />
                {countries.map((country, i) => (
                  <MenuItem key={i} value={country.country}>
                    {country.country}
                  </MenuItem>
                ))}
              </TextField>,
              <TextField
                fullWidth
                size="small"
                select
                label="Select a site"
                name="caban_id"
              >
                <MenuItem value="" />
                {sites.map((site, i) => (
                  <MenuItem key={i} value={site.caban_id}>
                    {site.caban_id}
                  </MenuItem>
                ))}
              </TextField>,
            ],
          ]}
        />
        <FormSection
          title="Incident details"
          sections={[
            [
              <TextField
                fullWidth
                size="small"
                select
                label="Select a company"
                name="customer"
              >
                <MenuItem value="" />
                {customers.map((company, i) => (
                  <MenuItem key={i} value={company.customer}>
                    {company.customer}
                  </MenuItem>
                ))}
              </TextField>,
            ],
          ]}
        />
        <FormSection
          title="Assigned to"
          sections={[
            [
              <TextField
                fullWidth
                size="small"
                select
                label="Select a user to assign the incident "
                name="assigned_to"
              >
                <MenuItem value="" />
                {users.map((company, i) => (
                  <MenuItem key={i} value={company.name}>
                    {company.name}
                  </MenuItem>
                ))}
              </TextField>,
            ],
          ]}
        />
        <FormSection
          title="Work to do"
          sections={[
            [
              <RadioGroup name="type">
                {worksToDo.map((work, i) => (
                  <FormControlLabel
                    key={i}
                    value={work}
                    control={<Radio value={work} name="type" />}
                    label={work}
                  />
                ))}
              </RadioGroup>,
            ],
            [
              <TextField
                style={{ width: '100%' }}
                size="small"
                label="Enter the details"
                name="description"
                multiline
                rows={6}
              />,
            ],
          ]}
        />
        <FormButton loading={loading} title="Create incident" />
      </FormContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

export default CreateIncidentPage
