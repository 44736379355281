import { INCIDENTS_DB, SITES_DB } from '../../../services/api/databases'

export const getIncidentsList = () =>
  `SELECT
    ${INCIDENTS_DB}.*,
    ${SITES_DB}.caban_id,
    ${SITES_DB}.modem_id,
    ${SITES_DB}.site_name,
    ${SITES_DB}.country
  FROM
    ${INCIDENTS_DB}
  INNER JOIN
    ${SITES_DB}
  ON
    ${INCIDENTS_DB}.caban_id = ${SITES_DB}.caban_id
  AND
    ${INCIDENTS_DB}.end_datetime IS NULL
  ORDER BY ${INCIDENTS_DB}.created_at DESC`
