import {
  Button,
  InputAdornment,
  MenuItem,
  styled,
  TextField,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { Site } from 'nocapp-types'
import { GLOBAL_PAGE_GAP } from '../../PageComponents/constants'

interface ActiveInactiveSitesListProps {
  active: Site[]
  inactive: Site[]
}

const ActiveInactiveSitesList = ({
  active,
  inactive,
}: ActiveInactiveSitesListProps) => {
  const theme = useTheme()
  const [sites, setSites] = useState<Site[]>([])
  const [filteredSites, setFilteredSites] = useState<Site[]>([])
  const [listType, setListType] = useState('all')
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    setSites([...active, ...inactive])
    setFilteredSites([...active, ...inactive])
  }, [active, inactive])

  const changeSiteTypeHandler = (value: string) => {
    setListType(value)
    switch (value) {
      case 'active':
        setSites(active)
        setFilteredSites(active)
        break
      case 'inactive':
        setSites(inactive)
        setFilteredSites(inactive)
        break
      case 'all':
      default:
        setSites([...active, ...inactive])
        setFilteredSites([...active, ...inactive])
        break
    }
  }

  const searchSiteHandler = useCallback(
    (value: string) => {
      function compareObjects(o1: any, o2: any) {
        let k = ''
        for (k in o1) if (o1[k] !== o2[k]) return false
        for (k in o2) if (o1[k] !== o2[k]) return false
        return true
      }

      function itemExists(haystack: any, needle: any) {
        for (let i = 0; i < haystack.length; i++)
          if (compareObjects(haystack[i], needle)) return true
        return false
      }

      const results: Site[] = []
      sites.forEach((site) => {
        for (const key in site) {
          if (Object.prototype.hasOwnProperty.call(site, key)) {
            // @ts-ignore
            const element: string =
              // @ts-ignore
              site[key] == null ? '' : site[key].toString()
            const formatedElement = element
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll('_', '')
              .replaceAll('-', '')
            const formatedValue = value
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll('_', '')
              .replaceAll('-', '')
            if (formatedElement.search(formatedValue) !== -1) {
              if (!itemExists(results, site)) {
                results.push(site)
              }
            }
          }
        }
      })
      setSearchValue(value)
      setFilteredSites(results)
    },
    [sites]
  )

  const exportSites = async () => {
    const currentDate = new Date()
    const dateLog = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`

    const res = await fetch(`/api/sites/csv?type=${listType}`, {
      method: 'POST',
    })
    const csv = await res.text()

    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
      csv
    )}`
    hiddenElement.target = '_blank'
    hiddenElement.download = `${listType}_sites_table_${dateLog}.csv`
    hiddenElement.click()
  }

  return (
    <Container>
      <SearchContainer>
        <SearchBarContainer>
          <TextField
            fullWidth
            value={searchValue}
            size="small"
            onChange={(e) => searchSiteHandler(e.target.value)}
            variant="filled"
            label="Search for a site"
            placeholder="Device ID, Caban ID, name, dates, etc."
            InputProps={{
              disableUnderline: true,
              style: {
                borderRadius: 10,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </SearchBarContainer>
        <SelectActiveInactiveContainer>
          <TextField
            select
            fullWidth
            size="small"
            label="Select type"
            variant="filled"
            value={listType}
            onChange={(e) => changeSiteTypeHandler(e.target.value)}
            InputProps={{
              disableUnderline: true,
              style: {
                borderRadius: 10,
              },
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </TextField>
        </SelectActiveInactiveContainer>
        <SelectActiveInactiveContainer>
          <Button size="large" onClick={exportSites}>
            Export
          </Button>
        </SelectActiveInactiveContainer>
      </SearchContainer>
      <ListContainer>
        {filteredSites.map((site, i) => (
          <SiteRow to={`/sites/info/${site.caban_id}`} key={i}>
            <ActiveDot
              style={{
                backgroundColor:
                  site.active === 1
                    ? theme.palette.success.light
                    : theme.palette.error.light,
              }}
            />
            <SiteItemContainer>{site.modem_id}</SiteItemContainer>
            <SiteItemContainer>{site.caban_id}</SiteItemContainer>
            <SiteItemContainer>{site.site_name}</SiteItemContainer>
            <SiteItemContainer>{site.country}</SiteItemContainer>
            <SiteItemContainer>{site.customer}</SiteItemContainer>
          </SiteRow>
        ))}
      </ListContainer>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const SearchContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
})
const SearchBarContainer = styled('div')({
  flex: 4,
})
const SelectActiveInactiveContainer = styled('div')({
  flex: 1,
})
const ListContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  gap: GLOBAL_PAGE_GAP,
})
const SiteRow = styled(Link)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.default,
  padding: 15,
  borderRadius: 15,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 14,
  gap: GLOBAL_PAGE_GAP,
}))
const SiteItemContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: 8,
  borderRadius: 8,
}))
const ActiveDot = styled('div')({
  width: 15,
  height: 15,
  borderRadius: 100,
})

export default ActiveInactiveSitesList
