import { LoadingButton } from '@mui/lab'
import { Dialog, DialogTitle, MenuItem, styled, TextField } from '@mui/material'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { CONTACTS_DB, TASK_TICKETS_DB } from '../../../services/api/databases'
import ApiHandler, { MailServiceBody } from '../../../services/api/apiHandler'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import SnackBar, { SnackBarSeverity } from '../../SnackBar'

interface CreateTaskTicketProps {
  open: boolean
  setOpen: Function
  updateTicketsList: Function
}

const CreateTaskTicket = ({
  open,
  setOpen,
  updateTicketsList,
}: CreateTaskTicketProps) => {
  const { nocappUser } = useNocAppAuth()
  const [users, setUsers] = useState<
    { name: string; email: string; username: string; country: string }[]
  >([])
  const [selectedUser, setSelectedUser] = useState<{
    name: string
    email: string
    username: string
    country: string
  }>({ name: '', email: '', username: '', country: '' })
  const [email, setEmail] = useState('')
  const [completeDate, setCompleteDate] = useState('')
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const ticketForm = useRef<HTMLFormElement>(null)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const getUsers = async () => {
      const apiHandler = new ApiHandler()
      const data = await apiHandler.getList(
        `SELECT name, email, username, country FROM ${CONTACTS_DB} ORDER BY name`
      )
      setUsers(data)
    }

    getUsers()
  }, [])

  const sendTravelTicket = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Query
    const query = `INSERT INTO ${TASK_TICKETS_DB}(
      subject,
      description,
      assigned_to,
      country,
      assigned_name,
      due_timestamp,
      email,
      created_by,
      creator_name,
      creator_email
    ) VALUES (
      '${subject}',
      '${description}',
      '${selectedUser.username}',
      '${selectedUser.country}',
      '${selectedUser.name}',
      '${completeDate}',
      '${email}',
      '${nocappUser!.username}',
      '${nocappUser!.name}',
      '${nocappUser!.email}'
    )`
    // Post ticket
    const ticketId = await apiHandler.insertAndGetId(query)
    // Send mail
    const mailBody: MailServiceBody = {
      destination: email,
      subject: `Task ticket - ${subject}`,
      body: `You have been assigned by ${nocappUser!.name} to a task ticket #${
        ticketId.id
      }\n\nDescription: ${description} \n\n To resolve this ticket enter https://solucionesv.xyz/_Caban/app/#/task-tickets in the my task ticket tab`,
      headers: `To: ${
        selectedUser.name
      } <${email}> \r\nFrom: Task Tickets NOC app <tasktickets@nocapp.com> \r\nCc: ${
        nocappUser!.email
      } \r\n`,
    }
    await apiHandler.sendMail(mailBody)
    await updateTicketsList()
    //
    setSeverity('success')
    setMessage('Task ticket created and sended')
    setOpenSnackbar(true)
    setLoading(false)
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Create task ticket</DialogTitle>
      <TicketForm ref={ticketForm} onSubmit={sendTravelTicket}>
        <AsignToUserRow>
          <TextField
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            required
          />
          <TextField
            select
            value={selectedUser.username}
            onChange={(e) => {
              const selected = users.find(
                (el) => el.username === e.target.value
              )
              setSelectedUser(selected!)
              setEmail(selected!.email)
            }}
            style={{ width: 180 }}
            label="Select a user"
            required
          >
            <MenuItem value="" />
            {users.map((user, i) => (
              <MenuItem value={user.username} key={i}>
                {user.name}
              </MenuItem>
            ))}
          </TextField>
        </AsignToUserRow>
        <TicketBody>
          <AsignToUserRow>
            <TextField
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              label="Subject"
              fullWidth
              required
              placeholder="Set a title to the ticket"
            />
            <TextField
              value={completeDate}
              type="date"
              focused
              onChange={(e) => setCompleteDate(e.target.value)}
              label="Set to"
              fullWidth
              required
            />
          </AsignToUserRow>
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Description"
            placeholder="Describe this ticket"
            multiline
            rows={6}
          />
        </TicketBody>
        <LoadingButton
          variant="contained"
          loading={loading}
          type="submit"
          style={{ marginTop: 15, width: '100%' }}
        >
          Send task ticket
        </LoadingButton>
      </TicketForm>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </Dialog>
  )
}

const AsignToUserRow = styled('div')({
  width: '100%',
  display: 'flex',
})
const TicketForm = styled('form')({
  padding: 20,
  paddingTop: 0,
})
const TicketBody = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  marginTop: 15,
})

export default CreateTaskTicket
