import { useAuth0 } from '@auth0/auth0-react'
import { FormControlLabel, styled, TextField } from '@mui/material'
import { FormEvent, useState } from 'react'
import {
  FormButton,
  FormContainer,
  FormSection,
} from '../../../components/FormComponents'
import PageComponent from '../../../components/PageComponents/PageComponent'
import { Android12Switch } from '../../../components/PageComponents/SwitchComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import ApiHandler from '../../../services/api/apiHandler'

const AddNewProcedure = () => {
  // context
  const { getAccessTokenSilently } = useAuth0()

  // state
  const [activeProcedure, setActiveProcedure] = useState(true)
  const [loading, setLoading] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const createProcedure = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    try {
      const apiHandler = new ApiHandler()
      // Transform data
      const body = apiHandler.transformParametizedFormDataToJson(
        event.currentTarget
      )
      const newProcedure = {
        active: activeProcedure,
        description: body.description,
        title: body.title,
        procedure_url: body.procedure_url,
      }

      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
      })
      // Insert query
      await fetch('/api/procedures', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: 'post',
        body: JSON.stringify(newProcedure),
      })
      setSeverity('success')
      setMessage('Added new procedure')
      setOpenSnackbar(true)
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been a problem adding new procedure")
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageComponent
      title="Add new procedure"
      scrollable
      loading={loading}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormContainer onSubmit={createProcedure}>
        <FormSection
          useBackButton
          title="New procedure"
          sections={[
            [
              <TextField
                fullWidth
                label="Procedure title"
                name="title"
                required
              />,
            ],
            [
              <TextField
                fullWidth
                label="Description"
                name="description"
                multiline
                rows={5}
              />,
            ],
            [<TextField fullWidth label="Helpful url" name="procedure_url" />],
            [
              <ActiveRow>
                <FormControlLabel
                  control={
                    <Android12Switch
                      defaultChecked
                      checked={activeProcedure}
                      onChange={() => setActiveProcedure(!activeProcedure)}
                    />
                  }
                  label="Active procedure"
                />
              </ActiveRow>,
            ],
          ]}
        />
        {/* Send */}
        <FormButton loading={loading} title="Create procedure" />
      </FormContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

const ActiveRow = styled('div')({
  width: '100%',
  display: 'flex',
  marginTop: 8,
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontWeight: 'bold',
})

export default AddNewProcedure
