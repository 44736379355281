import { useCallback, useEffect, useState } from 'react'
import { Bar, Pie } from 'react-chartjs-2'
import './alerts-log-metrics.css'
import { MenuItem, styled, TextField } from '@mui/material'
import { AlertLogMetricsProps } from 'nocapp-types'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import PageComponent from '../../../components/PageComponents/PageComponent'

const AlertsStatisticsPage = () => {
  const currentDate = new Date()
  const { nocappUser } = useNocAppAuth()
  const [notShow, setNotShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alertLogMetrics, setAlertLogMetrics] = useState<AlertLogMetricsProps>()
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth() + 1)
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear())

  const getData = useCallback(async () => {
    setLoading(true)
    let country
    const userCountry = nocappUser!.country
    if (
      userCountry === 'Colombia' ||
      userCountry === 'Jamaica' ||
      userCountry === 'Republica Dominicana'
    ) {
      country = userCountry
      setNotShow(true)
    } else {
      country = 'all'
    }
    const res = await fetch(
      `/api/statistics/alerts?month=${selectedMonth}&year=${selectedYear}&country=${country}`,
      {
        method: 'get',
      }
    )
    const data = await res.json()
    setAlertLogMetrics(data)
    setLoading(false)
  }, [selectedMonth, selectedYear, nocappUser])

  const formatMessageString = (string: string) => {
    const replaced = string.replaceAll('_', ' ')
    const lower = replaced.toLowerCase()
    return lower.charAt(0).toUpperCase() + lower.slice(1)
  }

  useEffect(() => {
    getData()
  }, [selectedMonth, getData, selectedYear])

  return (
    <PageComponent
      loading={loading}
      style={{ display: 'flex', justifyContent: 'center', paddingBottom: 15 }}
    >
      <div style={{ padding: 25 }}>
        <TopBar>
          <Title>Alerts ({selectedYear})</Title>
          <div>
            <TextField
              style={{ width: 100 }}
              value={selectedYear}
              size="small"
              onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
              select
              label="Select a year"
            >
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2021">2021</MenuItem>
            </TextField>
          </div>
        </TopBar>
        {/* Messages */}
        <SectionTitle>
          Alerts from{' '}
          {selectedYear === currentDate.getFullYear()
            ? `${selectedYear} to today`
            : selectedYear}
        </SectionTitle>
        <div className="metrics-row">
          <div className="metrics-table-container">
            <table>
              <thead>
                <th>Messages</th>
                <th>Count</th>
              </thead>
              <tbody>
                {alertLogMetrics?.messagesTable.map((message, i) => (
                  <tr key={i}>
                    <td>{formatMessageString(message.message)}</td>
                    <th>{message.count}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="metrics-chart-container">
            <Pie
              className="metric-chart"
              data={{
                labels: alertLogMetrics?.messagesTable.map((message) =>
                  formatMessageString(message.message)
                ),
                datasets: [
                  {
                    label: 'Count',
                    data: alertLogMetrics?.messagesTable.map(
                      (message) => message.count
                    ),
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 5,
                  },
                ],
              }}
              width={300}
              height={400}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>
        {/* Owners */}
        <SectionTitle>Alerts by NOC operator</SectionTitle>
        <div className="metrics-row">
          <div className="metrics-table-container">
            <table>
              <thead>
                <th>Owner</th>
                <th>Count</th>
              </thead>
              <tbody>
                {alertLogMetrics?.ownerTable.map((owner, i) => (
                  <tr key={i}>
                    <td>{owner.owner}</td>
                    <th>{owner.count}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="metrics-chart-container">
            <Bar
              className="metric-chart"
              data={{
                labels: alertLogMetrics?.ownerTable.map((owner) => owner.owner),
                datasets: [
                  {
                    label: 'Count',
                    data: alertLogMetrics?.ownerTable.map(
                      (owner) => owner.count
                    ),
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 5,
                  },
                ],
              }}
              width={300}
              height={400}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>
        {/* Shift */}
        <SectionTitle>Alerts by shift</SectionTitle>
        <div className="metrics-row">
          <div className="metrics-table-container">
            <table>
              <thead>
                <th>Shift</th>
                <th>Count</th>
              </thead>
              <tbody>
                {alertLogMetrics?.shiftCountTable.map((shift, i) => (
                  <tr key={i}>
                    <td>{shift.shift}</td>
                    <th>{shift.count}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="metrics-chart-container">
            <Pie
              className="metric-chart"
              data={{
                labels: alertLogMetrics?.shiftCountTable.map(
                  (shift) => shift.shift
                ),
                datasets: [
                  {
                    label: 'Count',
                    data: alertLogMetrics?.shiftCountTable.map(
                      (shift) => shift.count
                    ),
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 5,
                  },
                ],
              }}
              width={300}
              height={400}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>
        {/* Days */}
        <SectionTitle>
          <span>Alerts by date</span>
          <TextField
            select
            style={{ marginLeft: 10 }}
            label="Select a month"
            size="small"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(parseInt(e.target.value, 10))}
          >
            <MenuItem value="1">January</MenuItem>
            <MenuItem value="2">February</MenuItem>
            <MenuItem value="3">March</MenuItem>
            <MenuItem value="4">April</MenuItem>
            <MenuItem value="5">May</MenuItem>
            <MenuItem value="6">June</MenuItem>
            <MenuItem value="7">Jule</MenuItem>
            <MenuItem value="8">August</MenuItem>
            <MenuItem value="9">September</MenuItem>
            <MenuItem value="10">October</MenuItem>
            <MenuItem value="11">November</MenuItem>
            <MenuItem value="12">December</MenuItem>
          </TextField>
        </SectionTitle>
        <div className="metrics-row">
          <div className="metrics-table-container">
            <table>
              <thead>
                <th>Day</th>
                <th>Count</th>
              </thead>
              <tbody>
                {alertLogMetrics?.dayCountTable.map((day, i) => (
                  <tr key={i}>
                    <td>{day.day_number}</td>
                    <th>{day.count}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="metrics-chart-container">
            <Bar
              className="metric-chart"
              data={{
                labels: alertLogMetrics?.dayCountTable.map(
                  (day) => day.day_number
                ),
                datasets: [
                  {
                    label: 'Count',
                    data: alertLogMetrics?.dayCountTable.map(
                      (day) => day.count
                    ),
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 5,
                  },
                ],
              }}
              width={300}
              height={400}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>
        {/* Projects */}
        {!notShow && (
          <>
            <SectionTitle>Alerts by country & client</SectionTitle>
            <div className="metrics-table-container" style={{ width: '100%' }}>
              <table>
                <thead>
                  <th>Project</th>
                  {alertLogMetrics?.projectsByCountryCountTable.countries.map(
                    (country, i) => (
                      <th key={i}>{country}</th>
                    )
                  )}
                  <th>Total</th>
                </thead>
                <tbody>
                  {alertLogMetrics?.projectsByCountryCountTable.data.map(
                    (data, i) => (
                      <tr key={i}>
                        <th>{data.project}</th>
                        {data.count.map((count, j) => (
                          <td key={j} style={{ textAlign: 'center' }}>
                            {count.count}
                          </td>
                        ))}
                        <th>{data.count.reduce((a, b) => a + b.count, 0)}</th>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </PageComponent>
  )
}

const TopBar = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const Title = styled('span')({
  fontSize: 28,
  fontWeight: 'bold',
})
const SectionTitle = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 28,
  fontWeight: 'bold',
  padding: 10,
  borderRadius: 15,
})

export default AlertsStatisticsPage
