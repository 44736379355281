import { styled } from '@mui/material'
import { Alert } from 'nocapp-types'

interface AlertsTodayProps {
  alert: Alert
}

const ALertsToday = ({ alert }: AlertsTodayProps) => {
  const getTime = (string: string) => {
    const date = new Date(string)
    return `${date.getHours()}:${date.getMinutes()}`
  }

  return (
    <Container>
      <AlertIdContainer>
        <AlertIdAndActivityBox>
          <AlertId>{alert.id}</AlertId>
          <AlertWork>{alert.message}</AlertWork>
        </AlertIdAndActivityBox>
        <SiteInfoBox>
          {alert.site_name} - {alert.site} - {alert.country}
        </SiteInfoBox>
      </AlertIdContainer>
      <AlertHour>Created at {getTime(alert.created_at_date!)}</AlertHour>
    </Container>
  )
}

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: 60,
  padding: 10,
  borderBottom: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 14,
  fontWeight: 'bold',
}))
const AlertIdContainer = styled('div')(() => ({
  flex: 3,
  display: 'flex',
  flexDirection: 'column',
}))
const AlertHour = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))
const AlertIdAndActivityBox = styled('div')({
  flex: 3,
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})
const SiteInfoBox = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'flex-end',
  color: theme.palette.text.secondary,
  fontSize: 12,
  marginTop: 5,
}))
const AlertId = styled('span')(({ theme }) => ({
  minWidth: 30,
  minHeight: 30,
  padding: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 100,
  background: theme.palette.background.default,
}))
const AlertWork = styled('span')({
  fontWeight: 'bold',
  fontSize: 16,
})

export default ALertsToday
