import { Button, styled, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { TaskTicket } from 'nocapp-types'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import { GLOBAL_PAGE_GAP } from '../../PageComponents/constants'
import GridCard from '../../PageComponents/GridCard'
import CreateTaskTicket from '../CreateTaskTicket'
import TaskTicketBox from '../TaskTicketBox'

interface TaskTicketsListProps {
  activeTickets: TaskTicket[]
  completedTickets: TaskTicket[]
  userTickets: TaskTicket[]
  allTickets: TaskTicket[]
  updateTicketsList: Function
}

const TaskTicketsList = ({
  activeTickets,
  completedTickets,
  updateTicketsList,
  userTickets,
  allTickets,
}: TaskTicketsListProps) => {
  const { nocappUser } = useNocAppAuth()
  const [ticketsIndex, setTicketsIndex] = useState(0)
  const [createTaskTicket, setCreateTaskTicket] = useState(false)

  return (
    <Container>
      <TicketsInfoRow>
        <Tabs
          value={ticketsIndex}
          onChange={(_, index) => setTicketsIndex(index)}
        >
          <Tab label="My task tickets" />
          <Tab label="Completed tickets" />
          <Tab label="Assigned tickets" />
          {(nocappUser!.profile === 'admin' ||
            nocappUser!.profile === 'noc' ||
            nocappUser!.username === 'mdiaz' ||
            nocappUser!.username === 'fpulido') && <Tab label="All tickets" />}
        </Tabs>
        <Button onClick={() => setCreateTaskTicket(true)}>Create ticket</Button>
      </TicketsInfoRow>
      <TicketsContainer>
        {ticketsIndex === 0 &&
          activeTickets.map((ticket, i) => (
            <TaskTicketBox
              key={i}
              ticket={ticket}
              updateTicketsList={updateTicketsList}
            />
          ))}
        {ticketsIndex === 1 &&
          completedTickets.map((ticket, i) => (
            <TaskTicketBox
              key={i}
              ticket={ticket}
              updateTicketsList={updateTicketsList}
            />
          ))}
        {ticketsIndex === 2 &&
          userTickets.map((ticket, i) => (
            <TaskTicketBox
              key={i}
              ticket={ticket}
              updateTicketsList={updateTicketsList}
            />
          ))}
        {ticketsIndex === 3 &&
          allTickets.map((ticket, i) => (
            <TaskTicketBox
              key={i}
              ticket={ticket}
              updateTicketsList={updateTicketsList}
            />
          ))}
      </TicketsContainer>
      <CreateTaskTicket
        open={createTaskTicket}
        setOpen={setCreateTaskTicket}
        updateTicketsList={updateTicketsList}
      />
    </Container>
  )
}

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: GLOBAL_PAGE_GAP,
})
const TicketsInfoRow = styled(GridCard)({
  height: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const TicketsContainer = styled(GridCard)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  gap: GLOBAL_PAGE_GAP,
})

export default TaskTicketsList
