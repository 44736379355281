import { NocAppAppProfiles } from 'nocapp-types'
import { ALERTS_INFO_DB, ALERTS_LOG_DB } from '../../../services/api/databases'

const ALERTS_SQL_VALUES = `${ALERTS_LOG_DB}.id,
${ALERTS_LOG_DB}.id_site,
${ALERTS_LOG_DB}.site,
${ALERTS_LOG_DB}.site_name,
${ALERTS_LOG_DB}.project,
${ALERTS_LOG_DB}.country,
${ALERTS_LOG_DB}.message,
${ALERTS_LOG_DB}.priority,
${ALERTS_LOG_DB}.state,
${ALERTS_LOG_DB}.created_at_date,
${ALERTS_LOG_DB}.hour,
${ALERTS_LOG_DB}.year,
${ALERTS_LOG_DB}.week,
${ALERTS_LOG_DB}.month,
${ALERTS_LOG_DB}.day,
${ALERTS_LOG_DB}.updated_at_date,
${ALERTS_LOG_DB}.alert_duration,
${ALERTS_LOG_DB}.count,
${ALERTS_LOG_DB}.owner,
${ALERTS_LOG_DB}.shift,
${ALERTS_LOG_DB}.comments,
${ALERTS_LOG_DB}.teams,
${ALERTS_LOG_DB}.handoff,
${ALERTS_INFO_DB}.prc,
${ALERTS_INFO_DB}.troubles,
${ALERTS_INFO_DB}.proposal,
${ALERTS_INFO_DB}.field,
${ALERTS_INFO_DB}.severity,
${ALERTS_INFO_DB}.priority`

export const getAlertsQuery = (
  profile: NocAppAppProfiles,
  limit: 'all' | number,
  country: string
) => {
  // get Alerts
  let query = `SELECT ${ALERTS_SQL_VALUES} FROM ${ALERTS_LOG_DB} INNER JOIN ${ALERTS_INFO_DB} ON ${ALERTS_LOG_DB}.message = ${ALERTS_INFO_DB}.alert ORDER BY ${ALERTS_LOG_DB}.created_at_date DESC`
  // Alerts profile distinction
  if (profile === 'oym' || profile === 'contractor-int') {
    query = `SELECT ${ALERTS_SQL_VALUES} FROM ${ALERTS_LOG_DB} INNER JOIN ${ALERTS_INFO_DB} ON ${ALERTS_LOG_DB}.message = ${ALERTS_INFO_DB}.alert AND ${ALERTS_LOG_DB}.country = '${country}'  ORDER BY ${ALERTS_LOG_DB}.created_at_date DESC`
  }
  // Limit handler
  if (limit !== 'all') {
    query += ` LIMIT ${limit}`
  }
  return query
}

export const getAlertsByCountryQuery = (
  startDate: string,
  endDate: string,
  profile: NocAppAppProfiles,
  country: string
) => {
  let query: string = `SELECT DISTINCT country, COUNT(id) as count FROM ${ALERTS_LOG_DB} WHERE created_at_date >= '${startDate}' AND created_at_date <= '${endDate}' GROUP BY country ORDER BY country`
  // Profile distiction
  if (profile === 'oym' || profile === 'contractor-int') {
    query = `SELECT DISTINCT country, COUNT(id) as count FROM ${ALERTS_LOG_DB} WHERE created_at_date >= '${startDate}' AND created_at_date <= '${endDate}' AND country = '${country}' GROUP BY country ORDER BY country`
  }
  return query
}
