import { IconButton, styled } from '@mui/material'
import { ArrowBackRounded as ArrowBackRoundedIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { ReactElement } from 'react'

interface ReportsTopBarProps {
  title: string
  rightRender?: ReactElement | undefined | false
}

export const REPORTS_TOPBAR_HEIGHT = 80

const ReportsTopBar = ({ title, rightRender }: ReportsTopBarProps) => {
  const navigate = useNavigate()

  return (
    <Container>
      <TitleContainer>
        <BackContainer onClick={() => navigate(-1)}>
          <ArrowBackRoundedIcon />
        </BackContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <TopBarActionButtons>{rightRender}</TopBarActionButtons>
    </Container>
  )
}

const Container = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
}))
const TitleContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
})
const Title = styled('span')({
  fontSize: 25,
  fontWeight: 'bold',
})
const BackContainer = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: 100,
  marginRight: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.background.paper,
}))
const TopBarActionButtons = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))

export default ReportsTopBar
