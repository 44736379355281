import { AppViews } from 'nocapp-types'
import { TaskTicketsDashboardPage } from '../pages'

export const taskTicketsViews: AppViews[] = [
  {
    path: 'task-tickets',
    page: <TaskTicketsDashboardPage />,
    access: ['noc', 'admin', 'oym', 'finances'],
  },
]
