import { AppViews } from 'nocapp-types'
import { AlertpediaPage, AlertsPage, UploadAlertsPage } from '../pages'

export const alertsViews: AppViews[] = [
  {
    path: 'alerts',
    page: <AlertsPage />,
    access: ['noc', 'admin', 'contractor-int', 'finances', 'oym'],
  },
  {
    path: 'alerts/alertpedia',
    page: <AlertpediaPage />,
    access: ['noc', 'admin', 'contractor-int', 'finances', 'oym'],
  },
  {
    path: 'alerts/upload',
    page: <UploadAlertsPage />,
    access: ['noc', 'admin'],
  },
]
