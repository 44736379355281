import { styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { TaskTicket } from 'nocapp-types'
import TaskTicketsList from '../../components/TaskTickets/TaskTicketsList'
import { TASK_TICKETS_DB } from '../../services/api/databases'
import { useNocAppAuth } from '../../services/auth/NocAppAuthContext'
import { useInterval } from '../../hooks/useInterval'
import ApiHandler from '../../services/api/apiHandler'
import { ticketsQueries } from './TaskTicketsDashboardPage.queries'
import PageComponent from '../../components/PageComponents/PageComponent'
import { GLOBAL_PAGE_GAP } from '../../components/PageComponents/constants'

const TaskTicketsDashboardPage = () => {
  const { nocappUser } = useNocAppAuth()
  const [activeTickets, setActiveTickets] = useState<TaskTicket[]>([])
  const [completedTickets, setCompletedTickets] = useState<TaskTicket[]>([])
  const [userTickets, setUserTickets] = useState<TaskTicket[]>([])
  const [allTickets, setAllTickets] = useState<TaskTicket[]>([])
  const [loading, setLoading] = useState(false)

  const getTickets = useCallback(async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Queries
    const queries = ticketsQueries(nocappUser!)
    if (
      nocappUser!.username === 'mdiaz' ||
      nocappUser!.username === 'fpulido' ||
      nocappUser!.country === 'Jamaica'
    ) {
      queries.allTickets = `SELECT * FROM ${TASK_TICKETS_DB} WHERE country = '${
        nocappUser!.country
      }' ORDER BY created_at DESC`
    }
    const [
      activeTicketsData,
      completedTicketsData,
      userTicketsData,
      allTicketsData,
    ] = await Promise.all([
      apiHandler.getList(queries.active),
      apiHandler.getList(queries.completed),
      apiHandler.getList(queries.userTickets),
      apiHandler.getList(queries.allTickets),
    ])
    //
    setActiveTickets(activeTicketsData)
    setCompletedTickets(completedTicketsData)
    setUserTickets(userTicketsData)
    setAllTickets(allTicketsData)
    setLoading(false)
  }, [nocappUser])

  useEffect(() => {
    getTickets()
  }, [getTickets])

  useInterval(() => {
    getTickets()
  }, 60000)

  return (
    <PageComponent title="Task tickets" loading={loading}>
      <GridContainer>
        <TaskTicketsContainer>
          <TaskTicketsList
            activeTickets={activeTickets}
            completedTickets={completedTickets}
            allTickets={allTickets}
            updateTicketsList={getTickets}
            userTickets={userTickets}
          />
        </TaskTicketsContainer>
      </GridContainer>
    </PageComponent>
  )
}

const GridContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gap: GLOBAL_PAGE_GAP,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}))
const TaskTicketsContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  overflowY: 'auto',
})

export default TaskTicketsDashboardPage
