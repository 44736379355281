import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, DialogTitle, styled } from '@mui/material'
import { User } from 'nocapp-types'
import { useState } from 'react'
import ApiHandler from '../../../services/api/apiHandler'
import { CONTACTS_DB } from '../../../services/api/databases'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'

interface ContactInfoProps {
  open: boolean
  setOpen: Function
  contact: User
  getList: Function
}

const ContactInfo = ({ open, setOpen, contact, getList }: ContactInfoProps) => {
  const { nocappUser } = useNocAppAuth()
  const [loading, setLoading] = useState(false)

  const deleteUserHandle = async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    await apiHandler.postItem(`
      DELETE FROM ${CONTACTS_DB} WHERE email = '${contact.email}'
    `)
    getList()
    setLoading(false)
    setOpen(false)
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'column' }}
      >
        <ContactName>{contact.name}</ContactName>
        <ContactUsername>{contact.username}</ContactUsername>
      </DialogTitle>
      <DialogContent>
        <InfoTable>
          <tbody>
            {nocappUser?.profile === 'admin' && (
              <tr>
                <InfoTitle>Profile</InfoTitle>
                <InfoBody>{contact.profile}</InfoBody>
              </tr>
            )}
            <tr>
              <InfoTitle>Company</InfoTitle>
              <InfoBody>{contact.company}</InfoBody>
            </tr>
            <tr>
              <InfoTitle>Country</InfoTitle>
              <InfoBody>{contact.country}</InfoBody>
            </tr>
            <tr>
              <InfoTitle>Position</InfoTitle>
              <InfoBody>{contact.position}</InfoBody>
            </tr>
            <tr>
              <InfoTitle>Email</InfoTitle>
              <InfoBody>{contact.email}</InfoBody>
            </tr>
            <tr>
              <InfoTitle>Mobile</InfoTitle>
              <InfoBody>{contact.mobile}</InfoBody>
            </tr>
            <tr>
              <InfoTitle>Escalation</InfoTitle>
              <InfoBody>{contact.escalation}</InfoBody>
            </tr>
            <tr>
              <InfoTitle>Comments</InfoTitle>
              <InfoBody>{contact.commentaries}</InfoBody>
            </tr>
          </tbody>
        </InfoTable>
        {nocappUser?.profile === 'admin' && (
          <LoadingButton
            variant="contained"
            color="error"
            loading={loading}
            onClick={deleteUserHandle}
            style={{ width: '100%', marginTop: 15 }}
          >
            Delete user
          </LoadingButton>
        )}
      </DialogContent>
    </Dialog>
  )
}

const ContactName = styled('span')({
  fontSize: 25,
})
const ContactUsername = styled('span')(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.secondary,
}))
const InfoTable = styled('table')({
  width: 'calc(100% - 10px)',
  marginRight: 10,
  fontSize: 14,
})
const InfoTitle = styled('td')(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: 6,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 5,
}))
const InfoBody = styled('td')(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  background: theme.palette.background.default,
  borderRadius: 6,
  padding: 5,
}))

export default ContactInfo
