import {
  DEVICES_INFO_DB,
  SITES_DB,
  SITES_INFO_DB,
  SITES_REPORTS_DATA,
} from '../../services/api/databases'

export const getAvailableSitesInfoForReportQueries = () => {
  return {
    availableSites: `SELECT DISTINCT site_id FROM ${SITES_REPORTS_DATA} ORDER BY site_id`,
    sitesInfo: `SELECT
      *
    FROM ${SITES_DB}
    LEFT JOIN
      ${SITES_INFO_DB}
    ON
      ${SITES_DB}.caban_id = ${SITES_INFO_DB}.site_id
    LEFT JOIN
      ${DEVICES_INFO_DB}
    ON
      ${SITES_DB}.caban_id = ${DEVICES_INFO_DB}.site_id
    ORDER BY
      ${SITES_DB}.caban_id`,
  }
}

export const getReportInfoFromSiteQuery = (
  site: string,
  startDate: string,
  endDate: string
) =>
  `SELECT
    CAST(time as DATE) as date,
    CAST(time as TIME) as time,
    core_ess_brick_max_temp,
    core_ess_current,
    core_ess_min_soc,
    core_mppt_output_current,
    core_mppt_output_voltage,
    core_system_ambient_temp,
    core_system_link_voltage,
    info_grid_active_status,
    metric_load_current_estimate,
    tel_status_rect_measured_current,
    tel_status_rect_measured_voltage
  FROM
    ${SITES_REPORTS_DATA}
  WHERE site_id = '${site}' AND time >= '${startDate}' AND time <= '${endDate}'`
