import { NocAppAuthUser } from 'nocapp-types'
import {
  CONTACTS_DB,
  INCIDENTS_DB,
  SITES_DB,
  SITES_INFO_DB,
} from '../../../services/api/databases'

export const getCountriesAndCustomersQuery = () => {
  return {
    countries: `SELECT DISTINCT country FROM ${SITES_DB} ORDER BY country`,
    customers: `SELECT DISTINCT customer FROM ${SITES_INFO_DB} ORDER BY customer`,
    users: `SELECT * FROM ${CONTACTS_DB} ORDER BY name`,
  }
}

export const getSitesByCountryQuery = (country: string) =>
  `SELECT * FROM ${SITES_DB} WHERE country = '${country}'`

export const getInsertQuery = (body: any, nocappUser: NocAppAuthUser) =>
  `INSERT INTO ${INCIDENTS_DB}(
    caban_id,
    country,
    customer,
    assigned_to,
    type,
    description,
    messages_log,
    activities_log,
    created_by
  ) VALUES (
    ${body.caban_id},
    ${body.country},
    ${body.customer},
    ${body.assigned_to},
    ${body.type},
    ${body.description},
    '[]',
    '[]',
    '${nocappUser!.username}'
  )`
