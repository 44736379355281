import { CONTACTS_DB } from '../../../services/api/databases'

export const getAvailableCountriesAndProfiles = () => {
  return {
    countries: `SELECT DISTINCT country FROM ${CONTACTS_DB} ORDER BY country`,
    profiles: `SELECT DISTINCT profile FROM ${CONTACTS_DB} ORDER BY profile`,
  }
}

export const createNewUserQuery = (body: any) => `INSERT INTO ${CONTACTS_DB}(
  email,
  name,
  username,
  password,
  profile,
  country,
  company,
  position,
  mobile
) VALUES (
  ${body.email},
  ${body.name},
  ${body.username},
  ${body.password},
  ${body.profile},
  ${body.country},
  ${body.company},
  ${body.position},
  ${body.mobile}
)`
