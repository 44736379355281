import { ArrowBackRounded as ArrowBackRoundedIcon } from '@mui/icons-material'
import { IconButton, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const ReturnButton = () => {
  const navigate = useNavigate()

  return (
    <BackContainer onClick={() => navigate(-1)}>
      <ArrowBackRoundedIcon />
    </BackContainer>
  )
}

const BackContainer = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: 100,
  marginRight: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.background.paper,
}))

export default ReturnButton
