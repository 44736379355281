import { Button, styled } from '@mui/material'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

const ProceduresTopButtons = () => {
  return (
    <Container>
      <Link to="create">
        <BarButton startIcon={<AddIcon />}>Create new procedure</BarButton>
      </Link>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
})
const BarButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  color: theme.palette.text.secondary,
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.8,
    background: theme.palette.divider,
  },
}))

export default ProceduresTopButtons
