import {
  DEVICES_INFO_DB,
  SITES_DB,
  SITES_INFO_DB,
} from '../../../services/api/databases'

export const sitesIndexQueries = () => {
  return {
    sites: `SELECT * FROM ${SITES_DB} INNER JOIN ${SITES_INFO_DB} ON ${SITES_DB}.caban_id = ${SITES_INFO_DB}.site_id INNER JOIN ${DEVICES_INFO_DB} ON ${SITES_DB}.caban_id = ${DEVICES_INFO_DB}.site_id AND ${SITES_DB}.active = 1`,
    countries: `SELECT DISTINCT country, COUNT(caban_id) as count FROM ${SITES_DB} WHERE active = 1 GROUP BY country ORDER BY country`,
  }
}

export const sitesByCountryQuery = (country: string) =>
  `SELECT * FROM ${SITES_DB} INNER JOIN ${SITES_INFO_DB} ON ${SITES_DB}.caban_id = ${SITES_INFO_DB}.site_id INNER JOIN ${DEVICES_INFO_DB} ON ${SITES_DB}.caban_id = ${DEVICES_INFO_DB}.site_id AND ${SITES_DB}.active = 1 AND ${SITES_DB}.country = '${country}'`
