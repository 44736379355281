import { AppViews } from 'nocapp-types'
import {
  AlertsStatisticsPage,
  StatisticsMenuPage,
  WeeklyAlertsReport,
} from '../pages'

export const statisticsViews: AppViews[] = [
  {
    path: 'statistics/select-type',
    page: <StatisticsMenuPage />,
    access: ['noc', 'admin', 'finances', 'oym'],
  },
  {
    path: 'statistics/alerts',
    page: <AlertsStatisticsPage />,
    access: ['noc', 'admin', 'finances', 'oym'],
  },
  {
    path: 'statistics/alerts/weekly-report',
    page: <WeeklyAlertsReport />,
    access: ['noc', 'admin', 'finances', 'oym'],
  },
]
