import {
  DEVICES_IDS_DB,
  DEVICES_INFO_DB,
  SITES_DATES_DB,
  SITES_DB,
  SITES_DISTANCES_DB,
  SITES_INFO_DB,
  SITES_LINKS_DB,
  SITES_LOCATION_DB,
  SITES_SEVERITY_DB,
} from '../../../services/api/databases'

export const availableCustomersAndSupervisors = (country: string) => {
  return {
    customers: `SELECT
      DISTINCT ${SITES_INFO_DB}.customer
    FROM
      ${SITES_DB}
    INNER JOIN
      ${SITES_INFO_DB}
    ON
      ${SITES_DB}.caban_id = ${SITES_INFO_DB}.site_id
    WHERE
      ${SITES_DB}.country = '${country}'
    ORDER BY customer`,
    //
    supervisors: `SELECT
      DISTINCT ${SITES_INFO_DB}.supervisor
    FROM
      ${SITES_DB}
    INNER JOIN
      ${SITES_INFO_DB}
    ON
      ${SITES_DB}.caban_id = ${SITES_INFO_DB}.site_id
    WHERE
      ${SITES_DB}.country = '${country}' AND ${SITES_INFO_DB}.supervisor IS NOT NULL
    ORDER BY supervisor`,
  }
}

//

export const insertNewSiteQueries = (
  body: any,
  activeSite: boolean,
  genset: boolean,
  grid: boolean
) => {
  return {
    sites: `INSERT INTO ${SITES_DB}(
      caban_id,
      modem_id,
      site_name,
      country,
      active,
      commentaries
    ) VALUES (
      ${body.caban_id},
      ${body.modem_id},
      ${body.site_name},
      ${body.country},
      ${activeSite ? 1 : 0},
      ${body.commentaries}
    );`,
    //
    sitesInfo: `INSERT INTO ${SITES_INFO_DB}(
      site_id,
      contracted_load,
      customer,
      customer_id,
      owner,
      owner_id,
      type,
      supervisor
    ) VALUES (
      ${body.caban_id},
      ${body.contracted_load},
      ${body.customer},
      ${body.customer_id},
      ${body.owner},
      ${body.owner_id},
      ${body.type},
      ${body.supervisor}
    );`,
    //
    location: `INSERT INTO ${SITES_LOCATION_DB}(
      site_id,
      latitude,
      longitude,
      address,
      zone
    ) VALUES (
      ${body.caban_id},
      ${body.latitude},
      ${body.longitude},
      ${body.address},
      ${body.zone}
    );`,
    //
    devicesInfo: `INSERT INTO ${DEVICES_INFO_DB}(
      site_id,
      genset,
      grid,
      grid_percentage,
      solar_panels,
      solar_structures,
      packs,
      mppts,
      rectifiers
    ) VALUES (
      ${body.caban_id},
      ${genset ? 1 : 0},
      ${grid ? 1 : 0},
      ${body.grid_percentage},
      ${body.solar_panels},
      ${body.solar_structures},
      ${body.packs},
      ${body.mppts},
      ${body.rectifiers}
    );`,
    //
    devicesIds: `INSERT INTO ${DEVICES_IDS_DB}(
      site_id,
      modem_id,
      cabinet_id,
      configuration,
      cabinet_type,
      battery_model
    ) VALUES (
      ${body.caban_id},
      ${body.modem_id},
      ${body.cabinet_id},
      ${body.configuration},
      ${body.cabinet_type},
      ${body.battery_model}
    );`,
    //
    dates: `INSERT INTO ${SITES_DATES_DB}(
      site_id,
      turn_on_date,
      alerts_activation_date,
      migration_date,
      commencement_date
    ) VALUES (
      ${body.caban_id},
      ${body.turn_on_date},
      ${body.alerts_activation_date},
      ${body.migration_date},
      ${body.commencement_date}
    );`,
    //
    links: `INSERT INTO ${SITES_LINKS_DB}(
      site_id,
      report_link,
      atp_link,
      engineering_data_link,
      plans_link
    ) VALUES (
      ${body.caban_id},
      ${body.report_link},
      NULL,
      NULL,
      NULL
    );`,
    //
    distances: `INSERT INTO ${SITES_DISTANCES_DB}(
      site_id,
      distance,
      travel_time
    ) VALUES (
      ${body.caban_id},
      ${body.distance},
      ${body.travel_time}
    );`,
    //
    severity: `INSERT INTO ${SITES_SEVERITY_DB}(
      site_id,
      severity,
      max_repair_time_total_loss,
      max_repair_time_partial_loss,
      max_repair_time_without_service_affection
    ) VALUES (
      ${body.caban_id},
      ${body.severity},
      ${body.max_repair_time_total_loss},
      ${body.max_repair_time_partial_loss},
      ${body.max_repair_time_without_service_affection}
    );`,
  }
}
