import { useCallback, useEffect, useState } from 'react'
import { Site, ActiveInactiveSitesByCountry, CountryCount } from 'nocapp-types'
import AllSitesBody from '../../../components/Sites/AllSitesBody'
import ApiHandler from '../../../services/api/apiHandler'
import { activeAndInactiveSitesQueries } from './ActiveAndInactiveSitesPage.queries'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SitesSearchBar from '../../../components/Sites/SitesSearchBar'

const ActiveAndInactiveSitesPage = () => {
  const [loading, setLoading] = useState(false)
  const [activeSites, setActiveSites] = useState<Site[]>([])
  const [inactiveSites, setInactiveSites] = useState<Site[]>([])
  const [countryCount, setCountryCount] = useState<
    ActiveInactiveSitesByCountry[]
  >([])

  const formatCountries = (
    active: CountryCount[],
    inactive: CountryCount[]
  ) => {
    let countries: any[] = []
    ;[...active, ...inactive].map((country) => {
      countries.push(country.country)
      return country
    })
    countries = countries.filter((item, pos) => countries.indexOf(item) === pos)
    countries = countries.map((country) => {
      let activeCount = 0
      let inactiveCount = 0
      active.map((activeCountry) => {
        if (activeCountry.country === country) {
          activeCount += activeCountry.count
        }
        return activeCountry
      })
      inactive.map((inactiveCountry) => {
        if (inactiveCountry.country === country) {
          inactiveCount += inactiveCountry.count
        }
        return inactiveCountry
      })
      return { country, active: activeCount, inactive: inactiveCount }
    })
    setCountryCount(countries)
  }

  const getData = useCallback(async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Queries
    const queries = activeAndInactiveSitesQueries()
    //
    const [activeSitesData, inactiveSitesData, activeCountry, inactiveCountry] =
      await Promise.all([
        apiHandler.getList(queries.active),
        apiHandler.getList(queries.inactive),
        apiHandler.getList(queries.activeByCountry),
        apiHandler.getList(queries.inactiveByCountry),
      ])
    //
    setActiveSites(activeSitesData)
    setInactiveSites(inactiveSitesData)
    formatCountries(activeCountry, inactiveCountry)
    setLoading(false)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <PageComponent
      title="Active/Inactive sites"
      centerComponent={<SitesSearchBar />}
      loading={loading}
    >
      <AllSitesBody
        activeSites={activeSites}
        inactiveSites={inactiveSites}
        countryCount={countryCount}
      />
    </PageComponent>
  )
}

export default ActiveAndInactiveSitesPage
