import { useAuth0 } from '@auth0/auth0-react'
import { styled, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Bar, Line, Pie } from 'react-chartjs-2'
import LoadingPage from '../../../components/PageComponents/LoadingPage/LoadingPage'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SelectWeekForAlerts from '../../../components/Statistics/SelectWeekForAlerts'

type AlertsReport = {
  alerts_by_country_and_week: {
    [key: string]: { country: string; count: number }[]
  }
  alerts_by_week_count: {
    [key: number]: number
  }
  alerts_by_priority_and_week: {
    [key: number]: {
      BATTERY_VOLTAGE_LOW: number
      GENSET_REQUEST_FAILED: number
      GRID_NOT_ACTIVE: number
      LOAD_NOT_DETECTED: number
      SYSTEM_IDLE_FAULT: number
    }[]
  }
  alerts_by_week_day_and_shift: [
    { week_day: number; day_shift: number; night_shift: number },
    { week_day: number; day_shift: number; night_shift: number },
    { week_day: number; day_shift: number; night_shift: number },
    { week_day: number; day_shift: number; night_shift: number },
    { week_day: number; day_shift: number; night_shift: number }
  ]
  amount_of_alerts_by_type: { message: string; count: number }[]
  avg_duration_by_alert_type: { message: string; count: number }[]
  avg_duration_by_week: {
    [key: number]: number
  }
  recognition_of_alerts: {
    reconized: number
    unreconized: number
  }[]
  timestamp: {
    start: string
    end: string
  }
}

// eslint-disable-next-line no-unused-vars
const getCurrentWeek = () => {
  const currentDate = new Date()
  const startDate = new Date(currentDate.getFullYear(), 0, 1)
  const days = Math.floor((+currentDate - +startDate) / (24 * 60 * 60 * 1000))
  return Math.ceil(days / 7)
}

const getCurrentYear = () => {
  const currentDate = new Date()
  return currentDate.getFullYear()
}

const WeeklyAlertsReport = () => {
  const { getAccessTokenSilently } = useAuth0()
  const theme = useTheme()
  const [loading, setLoading] = useState(true)
  const [alertsReport, setAlertsReport] = useState<AlertsReport | null>(null)
  const [selectedWeek, setSelectedWeek] = useState(`${getCurrentWeek()}`)
  const [selectedYear, setSelectedYear] = useState(`${getCurrentYear()}`)

  const getReportHandle = useCallback(async () => {
    setLoading(true)

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    const res = await fetch(
      `/api/alerts/weekly-report/${selectedWeek}/${selectedYear}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    const data = await res.json()
    setAlertsReport(data)

    setLoading(false)
  }, [getAccessTokenSilently, selectedWeek, selectedYear])

  useEffect(() => {
    getReportHandle()
  }, [getReportHandle])

  if (loading) {
    return (
      <PageComponent
        centerComponent={
          <SelectWeekForAlerts
            week={selectedWeek}
            setWeek={(value: string) => {
              setLoading(true)
              setAlertsReport(null)
              setSelectedWeek(value)
            }}
            year={selectedYear}
            setYear={(value: string) => {
              setLoading(true)
              setAlertsReport(null)
              setSelectedYear(value)
            }}
          />
        }
        loading={loading}
        title="Statistics menu"
      >
        <LoadingPage />
      </PageComponent>
    )
  }

  return (
    <PageComponent
      centerComponent={
        <SelectWeekForAlerts
          week={selectedWeek}
          setWeek={(value: string) => {
            setLoading(true)
            setAlertsReport(null)
            setSelectedWeek(value)
          }}
          year={selectedYear}
          setYear={(value: string) => {
            setLoading(true)
            setAlertsReport(null)
            setSelectedYear(value)
          }}
        />
      }
      loading={loading}
      title="Statistics menu"
      style={{
        flexDirection: 'column',
        gap: 20,
      }}
    >
      {/* Section 1 */}
      <ReportSection>
        <div style={{ flex: 1 }}>
          <Bar
            data={{
              labels: alertsReport!.amount_of_alerts_by_type.map(
                (data) => data.message
              ),
              datasets: [
                {
                  label: 'Count',
                  data: alertsReport!.amount_of_alerts_by_type.map(
                    (data) => data.count
                  ),
                  backgroundColor: [theme.palette.primary.light],
                  borderColor: [theme.palette.primary.main],
                  borderWidth: 2,
                  borderRadius: 5,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'Amount of alerts by type',
                },
              },
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Bar
            data={{
              labels: alertsReport!.amount_of_alerts_by_type.map(
                (data) => data.message
              ),
              datasets: [
                {
                  label: 'Count',
                  data: alertsReport!.amount_of_alerts_by_type.map(
                    (data) => data.count
                  ),
                  backgroundColor: [theme.palette.primary.light],
                  borderColor: [theme.palette.primary.main],
                  borderWidth: 2,
                  borderRadius: 5,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'AVG duration of alerts by type',
                },
              },
            }}
          />
        </div>
      </ReportSection>

      {/* Section 2 */}
      <ReportSection>
        <div style={{ flex: 1 }}>
          <Bar
            data={{
              labels: alertsReport!.alerts_by_week_day_and_shift.map(
                (data) => data.week_day
              ),
              datasets: [
                {
                  label: 'Day shift',
                  data: alertsReport!.alerts_by_week_day_and_shift.map(
                    (data) => data.day_shift
                  ),
                  backgroundColor: [theme.palette.primary.light],
                  borderColor: [theme.palette.primary.main],
                  borderWidth: 2,
                  borderRadius: 5,
                },
                {
                  label: 'Count',
                  data: alertsReport!.alerts_by_week_day_and_shift.map(
                    (data) => data.night_shift
                  ),
                  backgroundColor: [theme.palette.secondary.light],
                  borderColor: [theme.palette.secondary.main],
                  borderWidth: 2,
                  borderRadius: 5,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'Amount of alerts by shift',
                },
              },
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: 300, height: 300 }}>
            <Pie
              data={{
                labels: ['Recognized', 'Unrecognized'],
                datasets: [
                  {
                    label: 'Count',
                    data: [
                      alertsReport!.recognition_of_alerts[0].reconized,
                      alertsReport!.recognition_of_alerts[0].unreconized,
                    ],
                    backgroundColor: [
                      theme.palette.primary.light,
                      theme.palette.secondary.light,
                    ],
                    borderColor: [
                      theme.palette.primary.main,
                      theme.palette.secondary.main,
                    ],
                    borderWidth: 2,
                    borderRadius: 5,
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: 'Alert acknowledgment',
                  },
                },
              }}
              width={200}
              height={200}
            />
          </div>
        </div>
      </ReportSection>

      {/* Section 3 */}
      <ReportSection>
        <div style={{ flex: 1 }}>
          <Bar
            data={{
              labels: [
                +selectedWeek,
                +selectedWeek - 1,
                +selectedWeek - 2,
                +selectedWeek - 3,
              ],
              datasets: [
                {
                  label: 'Count',
                  data: [
                    alertsReport!.alerts_by_week_count[`${+selectedWeek}`],
                    alertsReport!.alerts_by_week_count[`${+selectedWeek - 1}`],
                    alertsReport!.alerts_by_week_count[`${+selectedWeek - 2}`],
                    alertsReport!.alerts_by_week_count[`${+selectedWeek - 3}`],
                  ],
                  backgroundColor: [theme.palette.primary.light],
                  borderColor: [theme.palette.primary.main],
                  borderWidth: 2,
                  borderRadius: 5,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'Alerts by week',
                },
              },
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Line
            data={{
              labels: [
                +selectedWeek,
                +selectedWeek - 1,
                +selectedWeek - 2,
                +selectedWeek - 3,
              ],
              datasets: [
                {
                  label: 'Count',
                  data: [
                    parseInt(
                      `${
                        alertsReport!.avg_duration_by_week[`${+selectedWeek}`]
                      }`,
                      10
                    ),
                    parseInt(
                      `${
                        alertsReport!.avg_duration_by_week[
                          `${+selectedWeek - 1}`
                        ]
                      }`,
                      10
                    ),
                    parseInt(
                      `${
                        alertsReport!.avg_duration_by_week[
                          `${+selectedWeek - 2}`
                        ]
                      }`,
                      10
                    ),
                    parseInt(
                      `${
                        alertsReport!.avg_duration_by_week[
                          `${+selectedWeek - 3}`
                        ]
                      }`,
                      10
                    ),
                  ],
                  backgroundColor: [theme.palette.primary.light],
                  borderColor: [theme.palette.primary.main],
                  borderWidth: 2,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'AVG duration of alerts by week',
                },
              },
            }}
          />
        </div>
      </ReportSection>

      {/* Section 4 */}
      <ReportSection>
        <div style={{ flex: 1 }}>
          <Bar
            data={{
              labels: alertsReport!.alerts_by_country_and_week[
                selectedWeek
              ].map((data) => data.country),
              datasets: [
                {
                  label: selectedWeek,
                  data: alertsReport!.alerts_by_country_and_week[
                    selectedWeek
                  ].map((data) => data.count),
                  backgroundColor: [theme.palette.primary.light],
                  borderColor: [theme.palette.primary.main],
                  borderWidth: 2,
                  borderRadius: 5,
                },
                {
                  label: `${+selectedWeek - 1}`,
                  data: alertsReport!.alerts_by_country_and_week[
                    `${+selectedWeek - 1}`
                  ].map((data) => data.count),
                  backgroundColor: [theme.palette.secondary.light],
                  borderColor: [theme.palette.secondary.main],
                  borderWidth: 2,
                  borderRadius: 5,
                },
                {
                  label: `${+selectedWeek - 2}`,
                  data: alertsReport!.alerts_by_country_and_week[
                    `${+selectedWeek - 2}`
                  ].map((data) => data.count),
                  backgroundColor: ['#08a9d6'],
                  borderColor: ['#0699c2'],
                  borderWidth: 2,
                  borderRadius: 5,
                },
                {
                  label: `${+selectedWeek - 3}`,
                  data: alertsReport!.alerts_by_country_and_week[
                    `${+selectedWeek - 3}`
                  ].map((data) => data.count),
                  backgroundColor: ['#58a5a6'],
                  borderColor: ['#63b7b8'],
                  borderWidth: 2,
                  borderRadius: 5,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'Alerts by country',
                },
              },
            }}
          />
        </div>
      </ReportSection>

      {/* Section 5 */}
      <ReportSection>
        <div style={{ flex: 1 }}>
          <Line
            data={{
              labels: [
                +selectedWeek,
                +selectedWeek - 1,
                +selectedWeek - 2,
                +selectedWeek - 3,
              ],
              datasets: [
                {
                  label: 'Count',
                  data: [
                    alertsReport?.alerts_by_priority_and_week[+selectedWeek][0]
                      .SYSTEM_IDLE_FAULT,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 1}`
                    ][0].SYSTEM_IDLE_FAULT,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 2}`
                    ][0].SYSTEM_IDLE_FAULT,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 3}`
                    ][0].SYSTEM_IDLE_FAULT,
                  ],
                  backgroundColor: [theme.palette.primary.light],
                  borderColor: [theme.palette.primary.main],
                  borderWidth: 2,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'System Idle Fault',
                },
              },
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Line
            data={{
              labels: [
                +selectedWeek,
                +selectedWeek - 1,
                +selectedWeek - 2,
                +selectedWeek - 3,
              ],
              datasets: [
                {
                  label: 'Count',
                  data: [
                    alertsReport?.alerts_by_priority_and_week[+selectedWeek][0]
                      .LOAD_NOT_DETECTED,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 1}`
                    ][0].LOAD_NOT_DETECTED,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 2}`
                    ][0].LOAD_NOT_DETECTED,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 3}`
                    ][0].LOAD_NOT_DETECTED,
                  ],
                  backgroundColor: [theme.palette.secondary.light],
                  borderColor: [theme.palette.secondary.main],
                  borderWidth: 2,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'Load Not Detected',
                },
              },
            }}
          />
        </div>
      </ReportSection>
      <ReportSection>
        <div style={{ flex: 1 }}>
          <Line
            data={{
              labels: [
                +selectedWeek,
                +selectedWeek - 1,
                +selectedWeek - 2,
                +selectedWeek - 3,
              ],
              datasets: [
                {
                  label: 'Count',
                  data: [
                    alertsReport?.alerts_by_priority_and_week[+selectedWeek][0]
                      .BATTERY_VOLTAGE_LOW,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 1}`
                    ][0].BATTERY_VOLTAGE_LOW,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 2}`
                    ][0].BATTERY_VOLTAGE_LOW,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 3}`
                    ][0].BATTERY_VOLTAGE_LOW,
                  ],
                  backgroundColor: ['#08a9d6'],
                  borderColor: ['#0699c2'],
                  borderWidth: 2,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'Battery Voltage Low',
                },
              },
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Line
            data={{
              labels: [
                +selectedWeek,
                +selectedWeek - 1,
                +selectedWeek - 2,
                +selectedWeek - 3,
              ],
              datasets: [
                {
                  label: 'Count',
                  data: [
                    alertsReport?.alerts_by_priority_and_week[+selectedWeek][0]
                      .GRID_NOT_ACTIVE,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 1}`
                    ][0].GRID_NOT_ACTIVE,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 2}`
                    ][0].GRID_NOT_ACTIVE,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 3}`
                    ][0].GRID_NOT_ACTIVE,
                  ],
                  backgroundColor: ['#08a9d6'],
                  borderColor: ['#0699c2'],
                  borderWidth: 2,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'Grid Not Active',
                },
              },
            }}
          />
        </div>
      </ReportSection>
      <ReportSection>
        <div style={{ flex: 1 }}>
          <Line
            data={{
              labels: [
                +selectedWeek,
                +selectedWeek - 1,
                +selectedWeek - 2,
                +selectedWeek - 3,
              ],
              datasets: [
                {
                  label: 'Count',
                  data: [
                    alertsReport?.alerts_by_priority_and_week[+selectedWeek][0]
                      .GENSET_REQUEST_FAILED,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 1}`
                    ][0].GENSET_REQUEST_FAILED,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 2}`
                    ][0].GENSET_REQUEST_FAILED,
                    alertsReport?.alerts_by_priority_and_week[
                      `${+selectedWeek - 3}`
                    ][0].GENSET_REQUEST_FAILED,
                  ],
                  backgroundColor: ['#58a5a6'],
                  borderColor: ['#63b7b8'],
                  borderWidth: 2,
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'Genset Request Failed',
                },
              },
            }}
          />
        </div>
        <div style={{ flex: 1 }} />
      </ReportSection>
    </PageComponent>
  )
}

const ReportSection = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 10,
  background: theme.palette.background.paper,
  borderRadius: 20,
  padding: 10,
}))

export default WeeklyAlertsReport
