import { Alert, Snackbar } from '@mui/material'

export type SnackBarSeverity = 'success' | 'warning' | 'error'
interface SnackBarProps {
  open: boolean
  setOpen: Function
  severity: SnackBarSeverity
  message: string
}

const SnackBar = ({ open, setOpen, severity, message }: SnackBarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  )
}

export default SnackBar
