import { NocAppAuthUser } from 'nocapp-types'
import { TASK_TICKETS_DB } from '../../services/api/databases'

export const ticketsQueries = (nocappUser: NocAppAuthUser) => {
  return {
    active: `SELECT * FROM ${TASK_TICKETS_DB} WHERE assigned_to = '${
      nocappUser!.username
    }' AND approved_at IS NULL ORDER BY created_at DESC`,
    completed: `SELECT * FROM ${TASK_TICKETS_DB} WHERE assigned_to = '${
      nocappUser!.username
    }' AND approved_at IS NOT NULL ORDER BY created_at DESC`,
    userTickets: `SELECT * FROM ${TASK_TICKETS_DB} WHERE created_by = '${
      nocappUser!.username
    }' ORDER BY created_at DESC`,
    allTickets: `SELECT * FROM ${TASK_TICKETS_DB} ORDER BY created_at DESC`,
  }
}
