import { MenuItem, TextField } from '@mui/material'

interface Props {
  week: string
  setWeek: Function
  year: string
  setYear: Function
}

const SelectWeekForAlerts = ({ week, setWeek, year, setYear }: Props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
      <TextField
        select
        value={week}
        onChange={(e) => setWeek(e.target.value)}
        label="Select week report"
        style={{ width: 200 }}
      >
        {Array(53)
          .fill(null)
          .map((_, key) => (
            <MenuItem value={key + 1}>Week {key + 1}</MenuItem>
          ))}
      </TextField>
      <TextField
        select
        value={year}
        onChange={(e) => setYear(e.target.value)}
        label="Select year"
        style={{ width: 200 }}
      >
        {[2023, 2022, 2021, 2020].map((yearValue, key) => (
          <MenuItem value={yearValue} key={key}>
            {yearValue.toString()}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

export default SelectWeekForAlerts
