import { AppViews } from 'nocapp-types'
import { AddNewProcedure, ProceduresPage } from '../pages'

export const proceduresViews: AppViews[] = [
  {
    path: 'procedures',
    page: <ProceduresPage />,
    access: ['noc', 'admin'],
  },
  {
    path: 'procedures/create',
    page: <AddNewProcedure />,
    access: ['noc', 'admin'],
  },
]
