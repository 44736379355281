import { AppViews } from 'nocapp-types'
import {
  RequestListPage,
  SparesRequestFormPage,
  SparesRequestMenuPage,
} from '../pages'

export const sparesRequestViews: AppViews[] = [
  {
    path: 'spares-request',
    page: <SparesRequestMenuPage />,
    access: ['admin'],
  },
  {
    path: 'spares-request/form',
    page: <SparesRequestFormPage />,
    access: ['admin'],
  },
  {
    path: 'spares-request/requests-list',
    page: <RequestListPage />,
    access: ['admin'],
  },
]
