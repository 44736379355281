import { MenuItem, styled, TextField, useTheme } from '@mui/material'
import { Alert } from 'nocapp-types'
import { useEffect, useState } from 'react'
import { MONTHS_LIST } from '../../../utils/datesHandle'
import GridCard from '../../PageComponents/GridCard'
import AlertInfoDialog from '../AlertsInfoDialog'

interface AlertsListProps {
  alerts: Alert[]
  limit: number | 'all'
  setLimit: Function
}

const TOP_HEIGHT = 60

const AlertsList = ({ alerts, limit, setLimit }: AlertsListProps) => {
  const theme = useTheme()
  const [searchInput, setSearchInput] = useState('')
  const [filteredAlerts, setFilteredAlerts] = useState<Alert[]>([])
  const [showAlert, setShowAlert] = useState(false)
  const [selectedAlert, setSelectedAlert] = useState<Alert | null>(null)

  useEffect(() => {
    setFilteredAlerts(alerts)
  }, [alerts])

  const getMonthDayYearDateWithTime = (string: string) => {
    const date = new Date(string)
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`
  }

  const searchAlertHandler = (value: string) => {
    function compareObjects(o1: any, o2: any) {
      let k = ''
      for (k in o1) if (o1[k] !== o2[k]) return false
      for (k in o2) if (o1[k] !== o2[k]) return false
      return true
    }

    function itemExists(haystack: any, needle: any) {
      for (let i = 0; i < haystack.length; i++)
        if (compareObjects(haystack[i], needle)) return true
      return false
    }

    const results: Alert[] = []
    alerts.forEach((alert) => {
      for (const key in alert) {
        if (Object.prototype.hasOwnProperty.call(alert, key)) {
          // @ts-ignore
          const element: string =
            // @ts-ignore
            alert[key] == null ? '' : alert[key].toString()
          const formatedElement = element
            .toLowerCase()
            .replaceAll(' ', '')
            .replaceAll('_', '')
            .replaceAll('-', '')
          const formatedValue = value
            .toLowerCase()
            .replaceAll(' ', '')
            .replaceAll('_', '')
            .replaceAll('-', '')
          if (formatedElement.search(formatedValue) !== -1) {
            if (!itemExists(results, alert)) {
              results.push(alert)
            }
          }
        }
      }
    })
    setSearchInput(value)
    setFilteredAlerts(results)
  }

  return (
    <Container>
      <SearchbarContainer>
        <TextField
          fullWidth
          label="Search alert"
          placeholder="Name, message, date, etc."
          value={searchInput}
          onChange={(e) => searchAlertHandler(e.target.value)}
        />
      </SearchbarContainer>
      <AlertsContainer>
        {filteredAlerts.map((alert, i) => (
          <AlertBox
            key={i}
            onClick={() => {
              setShowAlert(true)
              setSelectedAlert(alert)
            }}
          >
            <AlertInfoContainer>
              <AlertTitle>
                {alert.message} / {alert.site} / {alert.id_site}
              </AlertTitle>
              <AlertInfo>
                <AlertInfoItem>
                  {alert.site_name}, {alert.project}
                </AlertInfoItem>
                <AlertInfoItem>{alert.country}</AlertInfoItem>
                <AlertInfoItem>
                  Created in{' '}
                  {getMonthDayYearDateWithTime(alert.created_at_date!)}
                </AlertInfoItem>
              </AlertInfo>
            </AlertInfoContainer>
            <AlertsStatusBox>
              <StatusCircle
                style={{
                  backgroundColor:
                    alert.state === 'open'
                      ? theme.palette.error.light
                      : theme.palette.success.light,
                }}
              />
            </AlertsStatusBox>
          </AlertBox>
        ))}
      </AlertsContainer>
      <DateAndLimitContainer>
        <LastUpdate>
          Last alert at:{' '}
          {alerts.length !== 0 ? alerts[0].created_at_date : 'Loading...'}
        </LastUpdate>
        <TextField
          select
          style={{ width: 120 }}
          label="Set a limit"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        >
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={5000}>5000</MenuItem>
        </TextField>
      </DateAndLimitContainer>
      {selectedAlert && (
        <AlertInfoDialog
          alert={selectedAlert}
          open={showAlert}
          setOpen={setShowAlert}
        />
      )}
    </Container>
  )
}

const Container = styled(GridCard)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const SearchbarContainer = styled('div')({
  width: '100%',
  height: TOP_HEIGHT,
  display: 'flex',
  alignItems: 'center',
})
const DateAndLimitContainer = styled('div')({
  width: '100%',
  height: TOP_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const LastUpdate = styled('span')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}))
const AlertsContainer = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  paddingTop: 10,
  gap: 10,
})
const AlertBox = styled('div')(({ theme }) => ({
  width: '100%',
  height: 80,
  flexShrink: 0,
  flexGrow: 0,
  borderRadius: 10,
  padding: 15,
  background: theme.palette.background.default,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const AlertInfoContainer = styled('div')({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})
const AlertTitle = styled('span')({
  fontSize: 18,
  fontWeight: 'bold',
})
const AlertInfo = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 14,
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: 5,
}))
const AlertInfoItem = styled('span')(({ theme }) => ({
  padding: 5,
  borderRadius: 5,
  background: theme.palette.background.paper,
}))
const AlertsStatusBox = styled('div')({
  width: 50,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const StatusCircle = styled('div')({
  width: 20,
  height: 20,
  borderRadius: 100,
})

export default AlertsList
