import { PaletteMode } from '@mui/material'
import { Theme } from '@mui/material/styles'

export const getDesignTokens = (mode: PaletteMode): Theme => ({
  // @ts-ignore
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Light theme colors
          primary: {
            main: '#71B302',
          },
          secondary: {
            main: '#08D3D6',
          },
          background: {
            default: '#f0f0f0',
            paper: '#fff',
          },
          warning: {
            main: '#ff9640',
            light: '#fff540',
          },
          error: {
            main: '#f03232',
          },
          text: {
            primary: '#000',
            secondary: '#333333',
          },
        }
      : {
          // Dark theme colors
          primary: {
            main: '#71B302',
          },
          secondary: {
            main: '#08D3D6',
          },
          background: {
            default: '#1E1E1E',
            paper: '#0E0E0E',
          },
          warning: {
            main: '#ff9640',
            light: '#fff540',
          },
          error: {
            main: '#f03232',
          },
          text: {
            primary: '#fff',
            secondary: '#d6d6d6',
          },
        }),
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
          color: '#fff',
          borderRadius: 10,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        size: 'small',
        InputProps: {
          disableUnderline: true,
          style: {
            borderRadius: 10,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        disableUnderline: true,
        style: {
          borderRadius: 10,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          style: {
            borderRadius: 15,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
        },
      },
    },
  },
})
