import { styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import { Alert, AlertsByCountryCount } from 'nocapp-types'
import AlertsTopButtons from '../../../components/Alerts/AlertsTopButtons'
import Alertpedia from '../../../components/Alerts/Alertpedia'
import AlertsByCountry from '../../../components/Alerts/AlertsByCountry'
import AlertsList from '../../../components/Alerts/AlertsList'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import AlertsFilterDialog from '../../../components/Alerts/AlertsFilterDialog'
import { getAlertsByCountryQuery, getAlertsQuery } from './AlertsPage.queries'
import ApiHandler from '../../../services/api/apiHandler'
import PageComponent from '../../../components/PageComponents/PageComponent'
import GridCard from '../../../components/PageComponents/GridCard'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import ReturnButton from '../../../components/PageComponents/ReturnButton'

const AlertsPage = () => {
  const { nocappUser } = useNocAppAuth()
  const [alerts, setAlerts] = useState<Alert[]>([])
  const [countryCount, setCountryCount] = useState<AlertsByCountryCount[]>([])
  const [limit, setLimit] = useState<number | 'all'>(100)
  const [openAlertpedia, setOpenAlertpedia] = useState(false)
  const [canUploadAlerts, setCanUploadAlerts] = useState(true)
  const [openFilter, setOpenFilter] = useState(false)
  const [loading, setLoading] = useState(false)

  const getAlerts = useCallback(async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Alerts query
    const alertsQuery = getAlertsQuery(
      nocappUser!.profile,
      limit,
      nocappUser!.country
    )
    const data = await apiHandler.getList(alertsQuery)
    setAlerts(data)
    // Alerts by country
    const startAlertsDate = data[0].created_at_date
    const endAlertsDate = data[data.length - 1].created_at_date
    const countryQuery = getAlertsByCountryQuery(
      startAlertsDate,
      endAlertsDate,
      nocappUser!.profile,
      nocappUser!.country
    )
    const countryCountData = await apiHandler.getList(countryQuery)
    setCountryCount(countryCountData)
    // Can upload alerts
    if (nocappUser!.profile === 'admin' || nocappUser!.profile === 'noc') {
      setCanUploadAlerts(true)
    }
    setLoading(false)
  }, [limit, nocappUser])

  useEffect(() => {
    getAlerts()
  }, [getAlerts])

  return (
    <PageComponent
      loading={loading}
      title="Alerts"
      centerComponent={
        <AlertsTopButtons
          canUploadAlerts={canUploadAlerts}
          setOpenAlertpedia={setOpenAlertpedia}
          alerts={alerts}
        />
      }
    >
      <GridContainer>
        <ReturnContainer hoveranimation>
          <ReturnButton />
        </ReturnContainer>
        <ApplyFilterContainer
          hoveranimation
          onClick={() => setOpenFilter(true)}
        >
          <div
            style={{
              flex: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FilterAltRoundedIcon fontSize="large" />
          </div>
          <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
            Apply filter
          </div>
        </ApplyFilterContainer>
        <TotalOfAlertsContainer hoveranimation>
          <div
            style={{
              flex: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 35 }}>
              {alerts.length}
            </span>
          </div>
          <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
            <span style={{ fontWeight: 'bold' }}>
              Total of alerts in display
            </span>
          </div>
        </TotalOfAlertsContainer>
        <AlertsByCountryContainer>
          <AlertsByCountry countries={countryCount} />
        </AlertsByCountryContainer>
        <AlertsListContainer>
          <AlertsList alerts={alerts} limit={limit} setLimit={setLimit} />
        </AlertsListContainer>
      </GridContainer>
      <Alertpedia open={openAlertpedia} setOpen={setOpenAlertpedia} />
      <AlertsFilterDialog
        open={openFilter}
        setOpen={setOpenFilter}
        setAlerts={setAlerts}
        limit={limit}
      />
    </PageComponent>
  )
}

const GridContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gridTemplateRows: 'repeat(6, 1fr)',
  gap: GLOBAL_PAGE_GAP,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}))
const ReturnContainer = styled(GridCard)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
const TotalOfAlertsContainer = styled(GridCard)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridRowStart: 2,
  gridColumnStart: 1,
  gridColumnEnd: 3,
}))
const ApplyFilterContainer = styled(GridCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const AlertsByCountryContainer = styled('div')(() => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 3,
  gridRowEnd: 7,
  display: 'flex',
}))
const AlertsListContainer = styled('div')(() => ({
  gridColumnStart: 3,
  gridColumnEnd: 7,
  gridRowStart: 1,
  gridRowEnd: 7,
}))

export default AlertsPage
