import { styled } from '@mui/material'
import { CSSProperties, FC, ReactElement } from 'react'
import { GLOBAL_PAGE_GAP, NAVBAR_HEIGHT } from '../constants'
import NavBar from '../NavBar'

interface PageComponentsProps {
  scrollable?: boolean
  loading?: boolean
  centerComponent?: ReactElement
  title?: string
  // For sidebar
  menuForSidebar?: boolean
  openSidebar?: boolean
  setOpenSidebar?: Function
  style?: CSSProperties
}

const PageComponent: FC<PageComponentsProps> = ({
  children,
  scrollable,
  loading,
  centerComponent,
  title,
  menuForSidebar,
  openSidebar,
  setOpenSidebar,
  style,
}) => {
  return (
    <PageContaniner>
      <NavBarContainer>
        <NavBar
          menuForSidebar={menuForSidebar}
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
          loading={loading}
          centerComponent={centerComponent}
          title={title}
        />
      </NavBarContainer>
      <PageBody
        style={{
          height: scrollable ? 'auto' : `calc(100vh - ${NAVBAR_HEIGHT}px`,
          ...style,
        }}
      >
        {children}
      </PageBody>
    </PageContaniner>
  )
}

export const PageContaniner = styled('div')(() => ({
  width: '100%',
  height: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}))
export const NavBarContainer = styled('div')(() => ({
  width: '100%',
  height: NAVBAR_HEIGHT,
  display: 'flex',
}))
export const PageBody = styled('div')(() => ({
  width: '100%',
  padding: GLOBAL_PAGE_GAP,
  display: 'flex',
}))

export default PageComponent
