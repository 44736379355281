import { styled } from '@mui/material'
import { Link } from 'react-router-dom'
import PageComponent from '../../../components/PageComponents/PageComponent'

const StatisticsMenuPage = () => {
  const ITEMS = [
    {
      name: 'General dashboard',
      link: '/sites/countries',
    },
    {
      name: 'Alerts',
      link: '/statistics/alerts',
    },
    {
      name: 'Weekly Alerts Report',
      link: '/statistics/alerts/weekly-report',
    },
  ]

  return (
    <PageComponent title="Statistics menu">
      <SelectorWrap>
        {ITEMS.map((contact, i) => (
          <SelectorContainer key={i} to={contact.link}>
            {contact.name}
          </SelectorContainer>
        ))}
      </SelectorWrap>
    </PageComponent>
  )
}

const SelectorWrap = styled('div')({
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
})
const SelectorContainer = styled(Link)(({ theme }) => ({
  width: 300,
  height: 150,
  backgroundColor: theme.palette.background.paper,
  padding: 20,
  borderRadius: 20,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 20,
}))

export default StatisticsMenuPage
