import { useEffect, useRef } from 'react'

export const useInterval = (callback: Function, delay: number) => {
  const savedCallback = useRef<Function>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const interval = setInterval(() => savedCallback.current!(), delay)
    return () => clearInterval(interval)
  }, [delay])
}
