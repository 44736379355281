import { Pie } from 'react-chartjs-2'

interface TotalSitesChartProps {
  active: number
  inactive: number
}

const TotalSitesChart = ({ active, inactive }: TotalSitesChartProps) => {
  return (
    <div>
      <Pie
        data={{
          labels: ['Active sites', 'Inactive sites'],
          datasets: [
            {
              label: 'Count',
              data: [active, inactive],
              backgroundColor: [
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 206, 86, 0.2)',
              ],
              borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 206, 86, 1)'],
              borderWidth: 2,
              borderRadius: 5,
            },
          ],
        }}
      />
    </div>
  )
}

export default TotalSitesChart
