import { styled } from '@mui/material'

const GridCard = styled('div')<{ hoveranimation?: boolean }>(
  ({ theme, hoveranimation = false }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 15,
    padding: 15,
    boxShadow: '0px 5px 20px rgba(32, 52, 89, 0.08)',
    transition: '200ms ease-in-out',
    ':hover': {
      marginTop: hoveranimation ? -3 : 0,
      boxShadow: hoveranimation
        ? '0px 5px 30px rgba(32, 52, 89, 0.11)'
        : 'initial',
    },
  })
)

export default GridCard
