// Sites
export const SITES_DB = 'devices.sites'
export const SITES_INFO_DB = 'devices.sites__info'
export const DEVICES_INFO_DB = 'devices.sites__devices_info'
export const DEVICES_IDS_DB = 'devices.sites__devices_ids'
export const SITES_DATES_DB = 'devices.sites__dates'
export const SITES_DISTANCES_DB = 'devices.sites__distances'
export const SITES_LINKS_DB = 'devices.sites__links'
export const SITES_LOCATION_DB = 'devices.sites__location'
export const SITES_SEVERITY_DB = 'devices.sites__severity'
export const SITES_UPDATES_DB = 'devices.sites__updates_history'
// Alerts
export const ALERTS_INFO_DB = 'devices.alerts__info'
export const ALERTS_LOG_DB = 'devices.alerts__log'
export const VISITS_DB = 'devices.visits'
export const CONTACTS_DB = 'devices.users'
export const SITES_REPORTS_DATA = 'devices.sites_reports_data'
export const INCIDENTS_DB = 'devices.incidents'
// Quality Control
export const QUALITY_CONTROL_DB = 'devices.quality_control'
export const QUALITY_CONTROL_CONCRETE_BASE_DB =
  'devices.quality_control__concrete_base'
export const QUALITY_CONTROL_SOLAR_STRUCTURE_DB =
  'devices.quality_control__solar_structure'
export const QUALITY_CONTROL_EQUIPMENT_DB = 'devices.quality_control__equipment'
export const QUALITY_CONTROL_EARTHING_SYSTEM_DB =
  'devices.quality_control__earthing_system'
export const QUALITY_CONTROL_ELECTRICAL_INSTALLATION_DB =
  'devices.quality_control__electrical_installation'
// Travel tickets
export const TASK_TICKETS_DB = 'devices.task_tickets'
// Spares request
export const SPARES_REQUEST_DB = 'devices.spares_request'
// Procedures
export const PROCEDURES_DB = 'devices.procedures'
