import { LoadingButton } from '@mui/lab'
import { Checkbox, styled, TextField } from '@mui/material'
import { NocAppAuthUser } from 'nocapp-types'
import { useState } from 'react'
import logo from '../../assets/img/caban-brandname-logo.svg'
import SnackBar, { SnackBarSeverity } from '../../components/SnackBar'
import ApiHandler from '../../services/api/apiHandler'
import { useNocAppAuth } from '../../services/auth/NocAppAuthContext'

const LoginPage = () => {
  const { setUserHandler } = useNocAppAuth()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const login = async () => {
    setLoading(true)
    const body = {
      username,
      password,
    }
    const apiHandler = new ApiHandler()
    try {
      const res: NocAppAuthUser = await apiHandler.postTo(
        body,
        'api/auth/login/'
      )
      setUserHandler!(res)
      // Save date of login
      const date = new Date()
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
      localStorage.setItem('loginDate', date.getTime().toString())
    } catch (error) {
      setSeverity('error')
      setMessage('The username or password is incorrect')
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageContainer>
      <LoginBoxContainer onSubmit={login}>
        <LogoContainer>
          <LogoImg src={logo} alt="" />
        </LogoContainer>
        <Title>
          <span>Log In</span>
        </Title>
        <InputsContainer>
          <TextField
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            label="Username"
            type="email"
          />
          <br />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            type={!showPassword ? 'password' : 'text'}
          />
          <ShowPasswordContainer>
            <Checkbox
              value={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              color="secondary"
            />
            <span>Show password</span>
          </ShowPasswordContainer>
        </InputsContainer>
        <ButtonContainer>
          <LoadingButton
            type="submit"
            style={{ width: '100%', height: 50 }}
            variant="contained"
            loading={loading}
            onClick={login}
          >
            Log in
          </LoadingButton>
        </ButtonContainer>
      </LoginBoxContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageContainer>
  )
}

const PageContainer = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
const LoginBoxContainer = styled('form')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  maxheight: '80%',
  height: 600,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 30,
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down(1000)]: {
    width: '45%',
  },
  [theme.breakpoints.down(700)]: {
    width: '55%',
    height: '70%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '85%',
    height: '70%',
  },
}))
const LogoImg = styled('img')({
  width: 100,
  height: 'auto',
})
const LogoContainer = styled('div')({
  flex: 0.5,
  display: 'flex',
  justifyContent: 'center',
})
const Title = styled('div')({
  flex: 1.2,
  fontSize: 35,
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
})
const InputsContainer = styled('div')({
  flex: 5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})
const ButtonContainer = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
})
const ShowPasswordContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  fontSize: 14,
}))

export default LoginPage
