import { GetAppRounded as ExportIcon } from '@mui/icons-material'
import { Button, styled } from '@mui/material'
import { FC } from 'react'

interface Props {
  exportVisits: Function
}

const VisitsTopButtons: FC<Props> = ({ exportVisits }) => {
  return (
    <Container>
      <BarButton onClick={() => exportVisits()} startIcon={<ExportIcon />}>
        Export visits CSV
      </BarButton>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
})
const BarButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  color: theme.palette.text.secondary,
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.8,
    background: theme.palette.divider,
  },
}))

export default VisitsTopButtons
