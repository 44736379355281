import { LoadingButton } from '@mui/lab'
import { MenuItem, styled, TextField } from '@mui/material'
import { Site } from 'nocapp-types'
import { useCallback, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import ApiHandler from '../../../services/api/apiHandler'
import { SITES_DB, SITES_REPORTS_DATA } from '../../../services/api/databases'

const DeleteSiteData = () => {
  const [sites, setSites] = useState<Site[]>([])
  const [selectedSite, setSelectedSite] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [loadingProps, setLoadingProps] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedCount, setSelectedCount] = useState<null | number>(null)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const getSites = useCallback(async () => {
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    // Get sites
    const data = await apiHandler.getList(
      `SELECT * FROM ${SITES_DB} ORDER BY caban_id`
    )
    setSites(data)
    setLoadingProps(false)
  }, [])

  useEffect(() => {
    getSites()
  }, [getSites])

  /**
   * Will change result every time dates changes or site change
   */
  const getNumberOfValuesBetweenDates = useCallback(async () => {
    if (selectedSite === '' || startDate === '' || endDate === '') {
      return
    }
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    try {
      const data = await apiHandler.getObject(
        `SELECT COUNT(time) as count FROM ${SITES_REPORTS_DATA} WHERE site_id = '${selectedSite}' AND time >= '${startDate}' AND time <= '${endDate}'`
      )
      setSelectedCount(data.count)
    } catch (error) {
      setOpenSnackbar(true)
      setSeverity('error')
      setMessage("There's been an error deleting the site data")
    }
    setLoadingProps(false)
  }, [selectedSite, startDate, endDate])

  useEffect(() => {
    getNumberOfValuesBetweenDates()
  }, [getNumberOfValuesBetweenDates])

  /**
   * Delete data between dates handle
   */
  const deleteDataHandle = async () => {
    if (selectedSite === '' || startDate === '' || endDate === '') {
      setOpenSnackbar(true)
      setSeverity('warning')
      setMessage('You need to select a site, start and end date')
      return
    }
    setLoading(true)
    const apiHandler = new ApiHandler()
    try {
      await apiHandler.postItem(
        `DELETE FROM ${SITES_REPORTS_DATA} WHERE site_id = '${selectedSite}' AND time >= '${startDate}' AND time <= '${endDate}'`
      )
      setOpenSnackbar(true)
      setSeverity('success')
      setMessage(`Deleted ${selectedCount} values successfully`)
    } catch (error) {
      setOpenSnackbar(true)
      setSeverity('error')
      setMessage("There's been an error deleting the site data")
    }
    setLoading(false)
  }

  return (
    <PageComponent
      title="Delete data"
      loading={loadingProps}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <DeleteSiteDataContainer>
        <div>
          <Title>Delete site report data</Title>
          <div style={{ marginBottom: 40 }}>
            <span>
              Make sure to select the correct dates, this action is not
              reversible
            </span>
          </div>
          <div style={{ marginBottom: 20 }}>
            <TextField
              select
              fullWidth
              size="small"
              label="Select site"
              value={selectedSite}
              onChange={(e) => setSelectedSite(e.target.value)}
            >
              <MenuItem value="" />
              {sites.map((site, i) => (
                <MenuItem key={i} value={site.caban_id}>
                  {site.caban_id} / {site.modem_id} / {site.site_name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <SelectDatesContainer>
            <TextField
              label="Start date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              focused
              type="datetime-local"
              name="start_date"
            />
            <TextField
              label="End date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              focused
              type="datetime-local"
              name="end_date"
            />
          </SelectDatesContainer>
          <SelectionContainer>
            <span>Values that will get deleted:</span>
            <span style={{ fontWeight: 'bold' }}>{selectedCount} values</span>
          </SelectionContainer>
        </div>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={deleteDataHandle}
        >
          Delete data
        </LoadingButton>
      </DeleteSiteDataContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

const DeleteSiteDataContainer = styled('div')(({ theme }) => ({
  width: '40%',
  borderRadius: 20,
  padding: 20,
  marginBottom: 20,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
const Title = styled('span')({
  fontSize: 25,
  fontWeight: 'bolder',
})
const SelectDatesContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 10,
})
const SelectionContainer = styled('div')({
  marginTop: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export default DeleteSiteData
