import { styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Site, SiteReport } from 'nocapp-types'
import { Bar as BarChart } from 'react-chartjs-2'
import { DEVICES_INFO_DB } from '../../../../services/api/databases'
import cabanLogo from '../../../../assets/img/caban-brandname-logo.svg'
import { basicReportKpis } from '../../BasicReport/BasicReportPage.functions'
import { getReportInfoFromSiteQuery } from '../../reportPagesQueries'
import ApiHandler from '../../../../services/api/apiHandler'
import { loadPowerComsuption } from '../../../../utils/reportsFunctions'
import PageComponent from '../../../../components/PageComponents/PageComponent'

const ClaroTemplatePage = () => {
  const { cabanId } = useParams<'cabanId'>()
  const [searchParams] = useSearchParams()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [reportData, setReportData] = useState<SiteReport[]>([])
  const [site, setSite] = useState<Site>()
  const [loadingData, setLoadingData] = useState(false)
  // Site KPIs
  const [maxLoad, setMaxLoad] = useState(0)
  const [avgSolarGeneration, setAvgSolarGeneration] = useState(0)
  const [avgAcConsumption, setAvgAcConsupmption] = useState(0)
  const [gridAvailability, setGridAvailability] = useState(0)

  const getMonthDayYearDate = (string: string) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'Jule',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const date = new Date(string)
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  const getReportData = useCallback(async () => {
    setLoadingData(true)
    const start = searchParams.get('from')
    const end = searchParams.get('to')
    setStartDate(getMonthDayYearDate(start!))
    setEndDate(getMonthDayYearDate(end!))
    //
    const apiHandler = new ApiHandler()
    // Report Queries
    const query = getReportInfoFromSiteQuery(cabanId!, start!, end!)
    //
    const [reportValues, siteData]: [SiteReport[], Site] = await Promise.all([
      apiHandler.getList(query),
      apiHandler.getObject(
        `SELECT * FROM ${DEVICES_INFO_DB} WHERE site_id = '${cabanId}'`
      ),
    ])
    setReportData(reportValues)
    setSite(siteData)
    // Set KPIs
    const kpi = basicReportKpis(reportValues, start!, end!)
    //
    setMaxLoad(+kpi.maxLoad.toFixed(2))
    setAvgSolarGeneration(+kpi.solarEnergyAvgPerDay.toFixed(2))
    setAvgAcConsupmption(+kpi.acEnergyGenerationAvg.toFixed(2))
    setGridAvailability(+(kpi.availability * 100).toFixed(2))
    setLoadingData(false)
  }, [cabanId, searchParams])

  useEffect(() => {
    getReportData()
  }, [getReportData])

  const kpis = [
    [
      { name: 'Max Load [kW]', value: maxLoad },
      { name: 'Avg Solar Generation per day [kW]', value: avgSolarGeneration },
      { name: 'Avg AC Consumption [kW]', value: avgAcConsumption },
      { name: 'Uptime', value: `${gridAvailability}%` },
    ],
  ]

  return (
    <PageComponent scrollable loading={loadingData}>
      <Body>
        <ReportContainer>
          <TopbarInfo>
            <CabanLogoImg src={cabanLogo} alt="" />
            <MonthlyReportTitle>
              <span style={{ textAlign: 'right' }}>Performance report</span>
              <span style={{ fontSize: 14, textAlign: 'right' }}>
                {startDate} - {endDate}
              </span>
            </MonthlyReportTitle>
          </TopbarInfo>
          {kpis.map((row, i) => (
            <AvgContainersRow key={i}>
              {row.map((item, j) => (
                <AvgBox key={j}>
                  <span style={{ textAlign: 'center' }}>{item.name}</span>
                  <AvgValue>{item.value}</AvgValue>
                </AvgBox>
              ))}
            </AvgContainersRow>
          ))}
          <SiteInfoTable style={{ marginTop: 40 }}>
            <tr>
              <SiteInfoTitleRow>Caban ID</SiteInfoTitleRow>
              <SiteInfoTitleRow># Packs</SiteInfoTitleRow>
              <SiteInfoTitleRow># MPPTs</SiteInfoTitleRow>
              <SiteInfoTitleRow># Panels</SiteInfoTitleRow>
            </tr>
            <tr>
              <SiteInfoValueRow>{site?.site_id}</SiteInfoValueRow>
              <SiteInfoValueRow>{site?.packs}</SiteInfoValueRow>
              <SiteInfoValueRow>{site?.mppts}</SiteInfoValueRow>
              <SiteInfoValueRow>{site?.solar_panels}</SiteInfoValueRow>
            </tr>
          </SiteInfoTable>
          <PerformanceTitle>Performance</PerformanceTitle>
          <CardContainer>
            <BarChart
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: 'System Current & Charge/ Discharge Cycle',
                  },
                },
              }}
              data={{
                labels: reportData.map((data) => `${data.date} ${data.time}`),
                datasets: [
                  {
                    label: 'Load current [A]',
                    data: reportData.map(
                      (data) => data.metric_load_current_estimate
                    ),
                    borderColor: '#FF9671',
                    backgroundColor: '#FF9671',
                  },
                  {
                    label: 'Battery current [A]',
                    data: reportData.map((data) => data.core_ess_current),
                    borderColor: '#3C8DA8',
                    backgroundColor: '#3C8DA8',
                  },
                  {
                    label: 'Rectifier current [A]',
                    data: reportData.map(
                      (data) => data.tel_status_rect_measured_current
                    ),
                    borderColor: '#FFC75F',
                    backgroundColor: '#FFC75F',
                  },
                  {
                    label: 'MPPT output current [A]',
                    data: reportData.map(
                      (data) => data.core_mppt_output_current
                    ),
                    borderColor: '#F9F871',
                    backgroundColor: '#F9F871',
                  },
                ],
              }}
            />
          </CardContainer>
          <br />
          <CardContainer>
            <BarChart
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: 'Load power consumption',
                  },
                },
              }}
              data={{
                labels: reportData.map((data) => `${data.date} ${data.time}`),
                datasets: [
                  {
                    label: 'Power [kW]',
                    data: reportData.map((data) =>
                      (
                        loadPowerComsuption(
                          data.core_system_link_voltage,
                          data.metric_load_current_estimate
                        ) / 1000
                      ).toFixed(2)
                    ),
                    borderColor: '#FF9671',
                    backgroundColor: '#FF9671',
                  },
                ],
              }}
            />
          </CardContainer>
          <br />
          <Footer>
            <span>Intelligent Energy Platform for Telecommunications</span>
            <span>© 2019 Caban Systems, Inc.</span>
            <span>California</span>
            <span>United States of America</span>
            <span>
              <a
                href="mailto:info@cabansystems.com"
                style={{ textDecoration: 'underline' }}
              >
                info@cabansystems.com
              </a>
              <span> | </span>
              <a
                href="http://www.cabansystems.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                www.cabansystems.com
              </a>
            </span>
            <br />
            <CabanLogoImg src={cabanLogo} alt="" />
            <br />
          </Footer>
        </ReportContainer>
      </Body>
    </PageComponent>
  )
}

const Body = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const ReportContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 1000,
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.paper,
  padding: 15,
  borderRadius: 15,
}))
const TopbarInfo = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 10,
  borderBottom: `2px solid ${theme.palette.primary.main}`,
}))
const CabanLogoImg = styled('img')({
  width: 200,
  height: 'auto',
})
const MonthlyReportTitle = styled('span')({
  fontSize: 22,
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
})
const AvgContainersRow = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  marginTop: 20,
}))
const AvgBox = styled('div')(({ theme }) => ({
  width: '100%',
  height: 200,
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `1px solid ${theme.palette.primary.main}`,
  borderRight: `1px solid ${theme.palette.primary.main}`,
  padding: 10,
  fontWeight: 'bold',
}))
const AvgValue = styled('span')(() => ({
  flex: 1,
  color: '#4285f4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 25,
}))
const SiteInfoTable = styled('table')({
  width: '100%',
  textAlign: 'center',
  marginTop: 15,
  borderCollapse: 'collapse',
})
const SiteInfoTitleRow = styled('th')(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  color: theme.palette.secondary.dark,
  paddingBottom: 5,
}))
const SiteInfoValueRow = styled('th')(() => ({
  paddingTop: 5,
}))
const PerformanceTitle = styled('span')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  fontSize: 20,
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginTop: 20,
  marginBottom: 20,
}))
const CardContainer = styled('div')(() => ({
  width: '100%',
  borderRadius: 15,
  padding: 10,
}))
const Footer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#4285f4',
  marginTop: 10,
  borderTop: `2px solid ${theme.palette.primary.main}`,
  paddingTop: 20,
  gap: 5,
}))

export default ClaroTemplatePage
