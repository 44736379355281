import { MenuItem, TextField } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { Site } from 'nocapp-types'
import {
  FormButton,
  FormContainer,
  FormSection,
} from '../../../components/FormComponents'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import { SITES_DB, SPARES_REQUEST_DB } from '../../../services/api/databases'
import ApiHandler, { MailServiceBody } from '../../../services/api/apiHandler'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'

const SparesRequestFormPage = () => {
  const { nocappUser } = useNocAppAuth()
  const [loadingProps, setLoadingProps] = useState(false)
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState<{ country: string }[]>([])
  const [sites, setSites] = useState<Site[]>([])
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  // Get countries
  useEffect(() => {
    const getData = async () => {
      setLoadingProps(true)
      const apiHandler = new ApiHandler()
      //
      const countries = await apiHandler.getList(
        `SELECT DISTINCT country FROM ${SITES_DB} ORDER BY country`
      )
      setCountry(countries)
      setLoadingProps(false)
    }

    getData()
  }, [])

  const getSitesByCountry = async (value: string) => {
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    //
    const data = await apiHandler.getList(
      `SELECT * FROM ${SITES_DB} WHERE country = '${value}' ORDER BY caban_id`
    )
    setSites(data)
    setLoadingProps(false)
  }

  const sendForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Transform data
    const body = apiHandler.transformFormDataToJson(event.currentTarget)
    // queries
    const insertQuery = `INSERT INTO ${SPARES_REQUEST_DB}(
      requester,
      reason_of_request,
      request_description,
      request_motive,
      customer_name,
      customer_type,
      customer_location,
      site_id,
      cabinet_part_number,
      cabinet_sn,
      device_part_number,
      device_sn,
      quantity,
      disposition,
      request_billable,
      request_needed_date,
      created_by_user,
      created_by_name
    ) VALUES (
      ${body.requester},
      ${body.reason_of_request},
      ${body.request_description},
      ${body.request_motive},
      ${body.customer_name},
      ${body.customer_type},
      ${body.customer_location},
      ${body.site_id},
      ${body.cabinet_part_number},
      ${body.cabinet_sn},
      ${body.device_part_number},
      ${body.device_sn},
      ${body.quantity},
      ${body.disposition},
      ${body.request_billable},
      ${body.request_needed_date},
      '${nocappUser!.username}',
      '${nocappUser!.name}'
    )`
    const mailBody: MailServiceBody = {
      subject: `Request for spares parts`,
      destination: 'ralvarenga@cabansystems.com; ccarbajal@gmail.com',
      body: `Requested by: ${body.requester}\n\nRequesting: "${
        body.request_description
      }" for the site ${
        body.site_id
      }\n\nYou can see the whole information <a href="${``}">here</a>`,
      headers: `To: Test name <ralvarenga@cabansystems.com> \r\nFrom: Spares request NOC app <sparesrequest@nocapp.com> \r\nCc: ccarbajal@cabansystems.com \r\n`,
    }
    // Send form
    try {
      await Promise.all([
        apiHandler.postItem(insertQuery),
        apiHandler.sendMail(mailBody),
      ])
      setSeverity('success')
      setMessage('Spare request sended')
      setOpenSnackbar(true)
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageComponent
      scrollable
      loading={loadingProps}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormContainer onSubmit={sendForm}>
        {/* Request contact */}
        <FormSection
          useBackButton
          title="Requester contact"
          sections={[
            [
              <TextField
                fullWidth
                label="Requester contact"
                placeholder="Can be an email, phone number, name, etc."
                required
                name="requester"
              />,
            ],
            [
              <TextField
                fullWidth
                label="Who is this request billable to?"
                placeholder="Can be an email, phone number, name, etc."
                required
                name="request_billable"
              />,
            ],
          ]}
        />
        {/* Request info */}
        <FormSection
          title="Request info."
          sections={[
            [
              <TextField
                fullWidth
                label="Request motive"
                select
                required
                name="request_motive"
              >
                <MenuItem value="" />
                <MenuItem value="Defective">Defective</MenuItem>
                <MenuItem value="Custom request upgrade">
                  Custom request upgrade
                </MenuItem>
                <MenuItem value="Caban initiated upgrade">
                  Caban initiated upgrade
                </MenuItem>
                <MenuItem value="Customer damage">Customer damage</MenuItem>
                <MenuItem value="Stolen">Stolen</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>,
            ],
            [
              <TextField
                fullWidth
                label="Why are you requesting"
                placeholder="Write the reason here."
                required
                name="reason_of_request"
              />,
            ],
            [
              <TextField
                fullWidth
                label="What are you requesting"
                placeholder="Write the description here."
                required
                name="request_description"
              />,
            ],
            [
              <TextField
                fullWidth
                label="Quantity needed"
                required
                type="number"
                name="quantity"
              />,
              <TextField
                fullWidth
                label="Disposition"
                required
                select
                name="disposition"
              >
                <MenuItem value="Parts recicled in field">
                  Parts recicled in field
                </MenuItem>
                <MenuItem value="Parts returned for engineering review">
                  Parts returned for engineering review
                </MenuItem>
              </TextField>,
            ],
            [
              <TextField
                fullWidth
                label="Requested need date"
                focused
                required
                type="date"
                name="request_needed_date"
              />,
            ],
          ]}
        />
        {/* Site info */}
        <FormSection
          title="Site info."
          sections={[
            [
              <TextField
                label="Select the country"
                select
                fullWidth
                required
                onChange={(e) => getSitesByCountry(e.target.value)}
              >
                <MenuItem value="" />
                {country.map((value, i) => (
                  <MenuItem key={i} value={value.country}>
                    {value.country}
                  </MenuItem>
                ))}
              </TextField>,
              <TextField
                label="Select the site"
                select
                fullWidth
                required
                name="site_id"
              >
                <MenuItem value="" />
                {sites.map((site, i) => (
                  <MenuItem key={i} value={site.caban_id}>
                    {site.caban_id} / {site.modem_id}
                  </MenuItem>
                ))}
              </TextField>,
            ],
            [
              <TextField
                label="Caban Cabinet Part Number"
                fullWidth
                name="cabinet_part_number"
              />,
              <TextField
                label="Caban Cabinet Serial Number"
                fullWidth
                name="cabinet_sn"
              />,
            ],
            [
              <TextField
                label="Device Part Number"
                fullWidth
                name="device_part_number"
              />,
              <TextField
                label="Device Serial Number"
                fullWidth
                name="device_sn"
              />,
            ],
          ]}
        />
        {/* Customer info. */}
        <FormSection
          title="Customer info."
          sections={[
            [
              <TextField
                label="Customer name"
                fullWidth
                name="customer_name"
              />,
              <TextField
                label="Customer type"
                select
                fullWidth
                name="customer_type"
              >
                <MenuItem value="" />
                <MenuItem value="EaaS">EaaS</MenuItem>
                <MenuItem value="Capex">Capex</MenuItem>
              </TextField>,
            ],
            [
              <TextField
                label="Customer location"
                fullWidth
                name="customer_location"
              />,
            ],
          ]}
        />
        {/* Send */}
        <FormButton loading={loading} title="Add new site" />
      </FormContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

export default SparesRequestFormPage
