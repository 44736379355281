import { LoadingButton } from '@mui/lab'
import { styled, TableCell, TableRow, Tooltip, useTheme } from '@mui/material'
import { useState } from 'react'
import { Visit } from 'nocapp-types'
import { VISITS_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'

interface VisitTableRowProps {
  visit: Visit
  updateList: Function
  onClick: Function
  canEdit: boolean
}

const VisitTableRow = ({
  visit,
  updateList,
  onClick,
  canEdit,
}: VisitTableRowProps) => {
  const theme = useTheme()
  const [loadingStart, setLoadingStart] = useState(false)
  const [loadingEnd, setLoadingEnd] = useState(false)

  const setStartDateHandler = async () => {
    setLoadingStart(true)
    const date = new Date()
    const startDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    const apiHandler = new ApiHandler()
    //
    await apiHandler.postItem(
      `UPDATE ${VISITS_DB} SET actual_start_datetime='${startDate}' WHERE id = '${visit.id}'`
    )
    await updateList()
    setLoadingStart(false)
  }

  const setEndDateHandler = async () => {
    setLoadingEnd(true)
    const date = new Date()
    const startDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    const apiHandler = new ApiHandler()
    //
    await apiHandler.postItem(
      `UPDATE ${VISITS_DB} SET actual_end_datetime='${startDate}' WHERE id = '${visit.id}'`
    )
    setLoadingEnd(false)
    updateList()
  }

  return (
    <TableRow
      hover
      onClick={() => onClick()}
      style={{
        cursor: 'pointer',
        transition: '150ms',
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <TableBodyCell>{visit.id}</TableBodyCell>
      <TableBodyCell>
        {visit.caban_id} / {visit.site_name}
      </TableBodyCell>
      <TableBodyCell>{visit.country}</TableBodyCell>
      <TableBodyCell>{visit.company}</TableBodyCell>
      <TableBodyCell>{visit.work}</TableBodyCell>
      <TableBodyCell>{visit.activity_detail}</TableBodyCell>
      <TableBodyCell
        style={{
          color:
            visit.actual_start_datetime !== null
              ? theme.palette.success.light
              : theme.palette.text.primary,
        }}
      >
        <Tooltip
          title={visit.actual_start_datetime !== null ? 'Actual' : 'Planned'}
        >
          <span>
            {visit.actual_start_datetime !== null
              ? visit.actual_start_datetime
              : visit.start_datetime}
          </span>
        </Tooltip>
      </TableBodyCell>
      {canEdit && (
        <>
          <TableBodyCell>
            <LoadingButton
              style={{
                overflow: 'hidden',
                textOverflow: 'clip',
                whiteSpace: 'nowrap',
              }}
              variant="contained"
              onClick={setStartDateHandler}
              loading={loadingStart}
              disabled={visit.actual_start_datetime !== null}
            >
              Add start
            </LoadingButton>
          </TableBodyCell>
          <TableBodyCell>
            <LoadingButton
              style={{
                overflow: 'hidden',
                textOverflow: 'clip',
                whiteSpace: 'nowrap',
              }}
              variant="contained"
              onClick={setEndDateHandler}
              loading={loadingEnd}
              disabled={visit.actual_end_datetime !== null}
            >
              Add end
            </LoadingButton>
          </TableBodyCell>
        </>
      )}
    </TableRow>
  )
}

const TableBodyCell = styled(TableCell)({
  flexGrow: 0,
  flexShrink: 0,
  maxWidth: '11%',
})

export default VisitTableRow
