import { AppViews } from 'nocapp-types'
import { VisitsFormPage, VisitsPage } from '../pages'

export const visitsViews: AppViews[] = [
  {
    path: 'visits',
    page: <VisitsPage />,
    access: [],
  },
  {
    path: 'visits/form',
    page: <VisitsFormPage />,
    access: [],
  },
]
