import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  styled,
  TextField,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { ALERTS_INFO_DB, ALERTS_LOG_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'

interface AlertsFilterDialogProps {
  open: boolean
  setOpen: Function
  setAlerts: Function
  limit: number | 'all'
}

const AlertsFilterDialog = ({
  open,
  setOpen,
  setAlerts,
  limit,
}: AlertsFilterDialogProps) => {
  const { nocappUser } = useNocAppAuth()
  const [filterType, setFilterType] = useState('')
  const [availableMessages, setAvailableMessages] = useState<
    { alert: string }[]
  >([])
  const [availableCountries, setAvailableCountries] = useState<
    { country: string }[]
  >([])
  const [availableSites, setAvailableSites] = useState<
    { site: string; site_name: string }[]
  >([])
  const [availableCustomer, setAvailableCustomer] = useState<
    { project: string }[]
  >([])
  const [selectedMessage, setSelectedMessage] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedSite, setSelectedSite] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  // eslint-disable-next-line prefer-const
  let [sql, setSql] = useState('')
  const [loadingFilter, setLoadingFilter] = useState(false)
  const [selectCountry, setSelectCountry] = useState(true)
  const [limitHandler, setLimitHandler] = useState('')

  useEffect(() => {
    if (limit !== 'all') {
      setLimitHandler(`LIMIT ${limit}`)
    }
  }, [limit])

  const getSitesByCountry = useCallback(
    async (country: string) => {
      const apiHandler = new ApiHandler()
      const res = await apiHandler.getList(
        `SELECT DISTINCT site, site_name FROM ${ALERTS_LOG_DB} WHERE country = '${country}' GROUP BY site ORDER BY site ${limitHandler}`
      )
      setAvailableSites(res)
    },
    [limitHandler]
  )

  const getCustomerByCountry = useCallback(
    async (country: string) => {
      const apiHandler = new ApiHandler()
      const res = await apiHandler.getList(
        `SELECT DISTINCT project FROM ${ALERTS_LOG_DB} WHERE country = '${country}' ORDER BY project ${limitHandler}`
      )
      setAvailableCustomer(res)
    },
    [limitHandler]
  )

  const getAlertsMessages = useCallback(async () => {
    const apiHandler = new ApiHandler()
    const res = await apiHandler.getList(
      `SELECT alert FROM ${ALERTS_INFO_DB} ORDER BY alert ${limitHandler}`
    )
    setAvailableMessages(res)
  }, [limitHandler])

  const getCountries = useCallback(async () => {
    const apiHandler = new ApiHandler()
    const res = await apiHandler.getList(
      `SELECT DISTINCT country FROM ${ALERTS_LOG_DB} ORDER BY country ${limitHandler}`
    )
    setAvailableCountries(res)
  }, [limitHandler])

  useEffect(() => {
    if (
      nocappUser!.profile === 'oym' ||
      nocappUser!.profile === 'contractor-int'
    ) {
      setSelectCountry(false)
      setSelectedCountry(nocappUser!.country)
      getSitesByCountry(nocappUser!.country)
      getCustomerByCountry(nocappUser!.country)
      getAlertsMessages()
    } else {
      getCountries()
      getAlertsMessages()
    }
  }, [
    getCountries,
    getAlertsMessages,
    getCustomerByCountry,
    getSitesByCountry,
    nocappUser,
  ])

  const applyFilterHandler = async () => {
    setLoadingFilter(true)
    const apiHandler = new ApiHandler()
    if (filterType === 'dates') {
      if (selectCountry) {
        sql = `SELECT * FROM ${ALERTS_LOG_DB} WHERE created_at_date >= '${startDate}' AND created_at_date <= '${endDate}' ${limitHandler}`
        setSql(
          `SELECT * FROM ${ALERTS_LOG_DB} WHERE created_at_date >= '${startDate}' AND created_at_date <= '${endDate}' ${limitHandler}`
        )
      } else {
        sql = `SELECT * FROM ${ALERTS_LOG_DB} WHERE created_at_date >= '${startDate}' AND created_at_date <= '${endDate}' AND country = '${selectedCountry}' ${limitHandler}`
        setSql(
          `SELECT * FROM ${ALERTS_LOG_DB} WHERE created_at_date >= '${startDate}' AND created_at_date <= '${endDate}' AND country = '${selectedCountry}' ${limitHandler}`
        )
      }
    }
    const res = await apiHandler.getList(sql)
    setAlerts(res)
    // setLimit('all')
    setLoadingFilter(false)
    setOpen(false)
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 28 }}>
        Set filter
      </DialogTitle>
      <DialogContent>
        <FilterTypeContainer>
          <TextField
            select
            fullWidth
            size="small"
            label="Select filter type"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <MenuItem value="" />
            {selectCountry && <MenuItem value="country">Country</MenuItem>}
            <MenuItem value="site">Site</MenuItem>
            <MenuItem value="customer">Customer</MenuItem>
            <MenuItem value="alert">Alerts</MenuItem>
            <MenuItem value="dates">Dates</MenuItem>
          </TextField>
        </FilterTypeContainer>
        {filterType === 'alert' && (
          <SelectFiltersContainer>
            <TextField
              select
              fullWidth
              value={selectedMessage}
              size="small"
              label="Select a message"
              onChange={(e) => {
                setSelectedMessage(e.target.value)
                if (selectCountry) {
                  setSql(
                    `SELECT * FROM ${ALERTS_LOG_DB} WHERE message = '${e.target.value}'`
                  )
                } else {
                  setSql(
                    `SELECT * FROM ${ALERTS_LOG_DB} WHERE message = '${e.target.value}' AND country = '${selectedCountry}'`
                  )
                }
              }}
            >
              <MenuItem value="" />
              {availableMessages.map((alert, i) => (
                <MenuItem key={i} value={alert.alert}>
                  {alert.alert}
                </MenuItem>
              ))}
            </TextField>
          </SelectFiltersContainer>
        )}
        {filterType === 'country' && (
          <SelectFiltersContainer>
            <TextField
              select
              fullWidth
              value={selectedCountry}
              size="small"
              label="Select a country"
              onChange={(e) => {
                setSelectedCountry(e.target.value)
                setSql(
                  `SELECT * FROM ${ALERTS_LOG_DB} WHERE country = '${e.target.value}'`
                )
              }}
            >
              <MenuItem value="" />
              {availableCountries.map((country, i) => (
                <MenuItem key={i} value={country.country}>
                  {country.country}
                </MenuItem>
              ))}
            </TextField>
          </SelectFiltersContainer>
        )}
        {filterType === 'site' && (
          <SelectFiltersContainer>
            {selectCountry && (
              <div style={{ flex: 1 }}>
                <TextField
                  select
                  fullWidth
                  value={selectedCountry}
                  size="small"
                  label="Select a country"
                  onChange={(e) => {
                    setSelectedCountry(e.target.value)
                    getSitesByCountry(e.target.value)
                  }}
                >
                  <MenuItem value="" />
                  {availableCountries.map((country, i) => (
                    <MenuItem key={i} value={country.country}>
                      {country.country}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
            <div style={{ flex: 1 }}>
              <TextField
                select
                fullWidth
                value={selectedSite}
                size="small"
                label="Select a site"
                onChange={(e) => {
                  setSelectedSite(e.target.value)
                  setSql(
                    `SELECT * FROM ${ALERTS_LOG_DB} WHERE site = '${e.target.value}'`
                  )
                }}
              >
                <MenuItem value="" />
                {availableSites.map((site, i) => (
                  <MenuItem key={i} value={site.site}>
                    {site.site}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </SelectFiltersContainer>
        )}
        {filterType === 'customer' && (
          <SelectFiltersContainer>
            {selectCountry && (
              <div style={{ flex: 1 }}>
                <TextField
                  select
                  fullWidth
                  value={selectedCountry}
                  size="small"
                  label="Select a country"
                  onChange={(e) => {
                    setSelectedCountry(e.target.value)
                    getCustomerByCountry(e.target.value)
                  }}
                >
                  <MenuItem value="" />
                  {availableCountries.map((country, i) => (
                    <MenuItem key={i} value={country.country}>
                      {country.country}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
            <div style={{ flex: 1 }}>
              <TextField
                select
                fullWidth
                value={selectedCustomer}
                size="small"
                label="Select a customer"
                onChange={(e) => {
                  setSelectedCustomer(e.target.value)
                  setSql(
                    `SELECT * FROM ${ALERTS_LOG_DB} WHERE project = '${e.target.value}'`
                  )
                }}
              >
                <MenuItem value="" />
                {availableCustomer.map((customer, i) => (
                  <MenuItem key={i} value={customer.project}>
                    {customer.project}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </SelectFiltersContainer>
        )}
        {filterType === 'dates' && (
          <SelectFiltersContainer>
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                size="small"
                type="date"
                label="Start date"
                focused
                onChange={(e) => {
                  setStartDate(e.target.value)
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                size="small"
                type="date"
                label="End date"
                focused
                onChange={(e) => {
                  setEndDate(e.target.value)
                }}
              />
            </div>
          </SelectFiltersContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="outlined"
          style={{ color: 'black' }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loadingFilter}
          variant="contained"
          onClick={applyFilterHandler}
        >
          Apply filter
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const FilterTypeContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginTop: 15,
})
const SelectFiltersContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginTop: 15,
})

export default AlertsFilterDialog
