export const formatMessageString = (string: string) => {
  const replaced = string.replaceAll('_', ' ')
  const lower = replaced.toLowerCase()
  return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const formatAlertDate = (string: string) => {
  const date = new Date(string)
  return `${date.getHours()}:${date.getMinutes()} ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`
}
