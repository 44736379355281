import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'
import { Fragment, ReactElement } from 'react'
import { GLOBAL_PAGE_GAP } from '../PageComponents/constants'
import GridCard from '../PageComponents/GridCard'
import ReturnButton from '../PageComponents/ReturnButton'

interface FormSectionProps {
  useBackButton?: boolean
  title: string
  sections: ReactElement[][]
}
interface FormButtonContainerProps {
  loading: boolean
  title: string
}

export const FormSection = ({
  useBackButton,
  title,
  sections,
}: FormSectionProps) => {
  return (
    <FormSectionContainer>
      <FormSectionContainerTitle>
        {useBackButton && <ReturnButton />}
        <span>{title}</span>
      </FormSectionContainerTitle>
      {sections.map((section, i) => (
        <FormSectionContainerRow key={i}>
          {section.map((input, j) => (
            <Fragment key={j}>{input}</Fragment>
          ))}
        </FormSectionContainerRow>
      ))}
    </FormSectionContainer>
  )
}

export const FormButton = ({ loading, title }: FormButtonContainerProps) => {
  return (
    <ButtonContainer>
      <LoadingButton type="submit" loading={loading} variant="contained">
        {title}
      </LoadingButton>
    </ButtonContainer>
  )
}

export const FormContainer = styled('form')({
  width: '100%',
  maxWidth: 600,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: GLOBAL_PAGE_GAP,
})
export const FormSectionContainer = styled(GridCard)(({ theme }) => ({
  width: '100%',
  borderRadius: 15,
  padding: 15,
  background: theme.palette.background.paper,
}))
export const FormSectionContainerTitle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}))
export const FormSectionContainerRow = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 8,
  gap: GLOBAL_PAGE_GAP,
})
export const FormSectionContainerInputBox = styled('div')({
  flex: 1,
})
const ButtonContainer = styled('div')({
  width: '100%',
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
})
