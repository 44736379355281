import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { FC, useState } from 'react'
import { FormSection } from '../../FormComponents'
import SnackBar, { SnackBarSeverity } from '../../SnackBar'

interface Props {
  open: boolean
  setOpen: Function
  exportVisits: ({
    // eslint-disable-next-line no-unused-vars
    startDate,
    // eslint-disable-next-line no-unused-vars
    endDate,
  }: {
    startDate: string
    endDate: string
  }) => Promise<string>
}

const CsvVisitsDialog: FC<Props> = ({ open, setOpen, exportVisits }) => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const manageCsvExport = async () => {
    const currentDate = new Date()
    const dateLog = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
    try {
      const csv = await exportVisits({ startDate, endDate })

      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csv
      )}`
      hiddenElement.target = '_blank'
      hiddenElement.download = `visits_log_${dateLog}.csv`
      hiddenElement.click()
      setSeverity('success')
      setMessage('CSV Downloaded')
      setOpenSnackbar(true)
      setOpen(false)
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>Download Visits CSV</DialogTitle>
      <DialogContent>
        <FormSection
          title="Work to do"
          sections={[
            [
              <TextField
                fullWidth
                required
                label="From"
                type="date"
                focused
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />,
              <TextField
                fullWidth
                label="To"
                focused
                required
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                name="end_datetime"
              />,
            ],
          ]}
        />
        <Button style={{ width: '100%' }} onClick={manageCsvExport}>
          Download CSV
        </Button>
      </DialogContent>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </Dialog>
  )
}

export default CsvVisitsDialog
