import { useNavigate, useParams, Link } from 'react-router-dom'
import { Site, UpdateHistory } from 'nocapp-types'
import {
  styled,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import {
  EditRounded as EditRoundedIcon,
  DeleteForever as DeleteForeverIcon,
} from '@mui/icons-material'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import SitesSearchBar from '../../../components/Sites/SitesSearchBar'
import LoadingPage from '../../../components/PageComponents/LoadingPage'
import grafanaLogo from '../../../assets/img/grafana-logo.svg'
import staticMap1 from '../../../assets/img/staticmap.png'
import ApiHandler from '../../../services/api/apiHandler'
import { allSiteInfoQueries, deleteSiteQueries } from './SiteInfoPage.queries'
import PageComponent from '../../../components/PageComponents/PageComponent'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import GridCard from '../../../components/PageComponents/GridCard'
import ReturnButton from '../../../components/PageComponents/ReturnButton'
import { MONTHS_LIST } from '../../../utils/datesHandle'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'

interface SiteCoordsBoxProps {
  img: string
}

const SiteInfoPage = () => {
  const { nocappUser } = useNocAppAuth()
  const { cabanId } = useParams<'cabanId'>()
  const navigate = useNavigate()
  const [site, setSite] = useState<Site>()
  const [lastUpdate, setLastUpdate] = useState<UpdateHistory>()
  const [loading, setLoading] = useState(false)
  const [showDeleteSite, setShowDeleteSite] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const getSiteInfo = useCallback(async (id: string) => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Queries
    const queries = allSiteInfoQueries(id)
    // Get
    const [siteData, lastUpdateValue] = await Promise.all([
      apiHandler.getObject(queries.info),
      apiHandler.getObject(queries.lastUpdate),
    ])
    //
    setLastUpdate(lastUpdateValue)
    setSite(siteData)
    setLoading(false)
  }, [])

  useEffect(() => {
    // Get site info
    getSiteInfo(cabanId!)
  }, [cabanId, getSiteInfo, nocappUser])

  if (!site) {
    return <LoadingPage />
  }

  const formatDate = (string: string) => {
    const date = new Date(string)
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`
  }

  const siteInfoData = [
    { title: 'Caban ID', value: site!.caban_id },
    { title: 'Modem ID', value: site!.modem_id },
    { title: 'Site Name', value: site!.site_name },
    { title: 'Country', value: site!.country },
    { title: 'Active', value: site!.active === 1 ? 'Yes' : 'No' },
    {
      title: lastUpdate ? 'Last update on' : 'Site added at',
      value: lastUpdate ? lastUpdate.update_timestamp : site!.created_at,
    },
  ]

  const siteLocationData = [
    // { title: 'Latitude', value: site!.latitude },
    // { title: 'Longitude', value: site!.longitude },
    { title: 'Address', value: site!.address },
    { title: 'Zone', value: site!.zone },
  ]

  const siteInfo2Data = [
    { title: 'Contracted load (kW)', value: site!.contracted_load },
    { title: 'Type', value: site!.type },
    { title: 'Customer', value: site!.customer },
    { title: 'Customer ID', value: site!.customer_id },
    { title: 'Owner', value: site!.owner },
    { title: 'Owner ID', value: site!.owner_id },
    { title: 'Supervisor', value: site!.supervisor },
    {
      title: 'See site alerts',
      link: true,
      value: `/sites/info/${site.caban_id}/alerts`,
    },
  ]

  const devicesInfoData = [
    { title: 'Genset', value: site.genset === 1 ? 'Yes' : 'No' },
    { title: 'Grid', value: site.grid === 1 ? 'Yes' : 'No' },
    { title: 'Grid percentage', value: site.grid_percentage },
    { title: 'Solar panels', value: site.solar_panels },
    { title: 'Solar structures', value: site.solar_structures },
    { title: 'Packs', value: site.packs },
    { title: 'MPTT', value: site.mppts },
    { title: 'Rectifiers', value: site.rectifiers },
  ]

  const devicesIdsData = [
    { title: 'Cabinet SN', value: site.cabinet_id },
    { title: 'Configuration', value: site.configuration },
    { title: 'Cabinet type', value: site.cabinet_type },
    { title: 'Battery model', value: site.battery_model },
  ]

  const sitesDatesData = [
    {
      title: 'Turn on date',
      value: site.turn_on_date !== null ? formatDate(site.turn_on_date!) : '',
    },
    {
      title: 'Alerts activation',
      value:
        site.alerts_activation_date !== null
          ? formatDate(site.alerts_activation_date!)
          : '',
    },
    {
      title: 'Migration date',
      value:
        site.migration_date !== null ? formatDate(site.migration_date!) : '',
    },
    {
      title: 'Commencement date',
      value:
        site.commencement_date !== null
          ? formatDate(site.commencement_date!)
          : '',
    },
  ]

  const siteLinksData = [
    { title: 'Site information link', value: site.report_link },
  ]

  const grafanaAlertsLinks = [
    {
      title: 'Custom dashboard',
      link: `https://grafana.cabansystems.com/d/LyY2GId4k/custom-dashboard?orgId=1&var-device_id=${site.modem_id}`,
    },
    {
      title: 'Dashboard (hourly)',
      link: `https://grafana.cabansystems.com/d/8X1hMSO4k/custom-dashboard-hourly?orgId=1&var-device_id=${site.modem_id}&var-custom_1=core_ess_brick_max_temp&var-custom_1=core_ess_current&var-custom_1=core_ess_max_soc&var-custom_1=core_mppt_output_current&var-custom_1=core_mppt_output_voltage&var-custom_1=core_system_ambient_temp&var-custom_1=core_system_link_voltage&var-custom_1=info_grid_active_status&var-custom_1=metric_load_current_estimate&var-custom_1=tel_status_rect_measured_current&var-custom_1=tel_status_rect_measured_voltage&var-custom_2=core_ess_brick_min_voltage`,
    },
    {
      title: 'Device insights',
      link: `https://grafana.cabansystems.com/d/AIx2MIdVz/device-insights-v2?orgId=1&var-device_id=${site.modem_id}`,
    },
    {
      title: 'History dashboard',
      link: `https://grafana.cabansystems.com/d/vQahMSd4k/historical-dashboard-v2?orgId=1&var-device_id=${site.modem_id}`,
    },
  ]

  // Conditional data
  const distanceData = [
    { title: 'Distance to site (km)', value: site.distance },
    { title: 'Travel time', value: site.travel_time },
  ]
  const severityData = [
    { title: 'Severity', value: site.severity },
    {
      title: 'Max. repair time with total loss of service',
      value: site.max_repair_time_total_loss,
    },
    {
      title: 'Max. repair time with partial loss of service',
      value: site.max_repair_time_partial_loss,
    },
    {
      title: 'Max. repair time without service affection',
      value: site.max_repair_time_without_service_affection,
    },
  ]

  const deleteSiteHandle = async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    try {
      const queries = deleteSiteQueries()
      const param = [site.caban_id]
      await Promise.all([
        apiHandler.postParameterizedItem(queries.dates, param),
        apiHandler.postParameterizedItem(queries.sites, param),
        apiHandler.postParameterizedItem(queries.sitesInfo, param),
        apiHandler.postParameterizedItem(queries.location, param),
        apiHandler.postParameterizedItem(queries.devicesInfo, param),
        apiHandler.postParameterizedItem(queries.devicesIds, param),
        apiHandler.postParameterizedItem(queries.links, param),
      ])
      setSeverity('success')
      setMessage('Succesfully deleted site')
      setOpenSnackbar(true)
      setShowDeleteSite(false)
      navigate('/sites')
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageComponent loading={loading} centerComponent={<SitesSearchBar />}>
      <GridContainer>
        <SiteNameEditContainer>
          <SiteNameContainer>
            {site!.site_name} | {site!.caban_id}
          </SiteNameContainer>
          <SiteEditReturnContainer>
            <ReturnButton />
            {(nocappUser!.profile === 'admin' ||
              nocappUser!.profile === 'noc') && (
              <IconButton onClick={() => navigate(`update`)}>
                <EditRoundedIcon />
              </IconButton>
            )}
            {nocappUser!.profile === 'admin' && (
              <IconButton onClick={() => setShowDeleteSite(true)}>
                <DeleteForeverIcon />
              </IconButton>
            )}
          </SiteEditReturnContainer>
        </SiteNameEditContainer>
        {/* Info */}
        <SiteDataContainer>
          {siteInfoData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Info 2 */}
        <SiteDataContainer>
          {siteInfo2Data.map((data, i) =>
            data.link ? (
              <LinkBox to={data.value} key={i}>
                <InfoValue />
                <InfoTitle style={{ color: 'white' }}>{data.title}</InfoTitle>
              </LinkBox>
            ) : (
              <InfoBox key={i}>
                <InfoValue>{data.value}</InfoValue>
                <InfoTitle>{data.title}</InfoTitle>
              </InfoBox>
            )
          )}
        </SiteDataContainer>
        {/* Location */}
        <SiteDataContainer>
          <Tooltip title="Not real image location">
            <SiteCoordsBackdrop
              img={staticMap1}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps?q=${site.latitude},${site.longitude}&t=k`}
            >
              <SiteCoordsContainer>
                <span>
                  Site coords: {site.latitude}, {site.longitude}
                </span>
              </SiteCoordsContainer>
            </SiteCoordsBackdrop>
          </Tooltip>
          {siteLocationData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Devices info */}
        <SiteDataContainer>
          {devicesInfoData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Devices ids */}
        <SiteDataContainer>
          {devicesIdsData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Sites dates */}
        <SiteDataContainer>
          {sitesDatesData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Sites links */}
        <SiteDataContainer style={{ gridTemplateColumns: '1fr' }}>
          {siteLinksData.map((data, i) => (
            <ExternalLinkBox
              target="_blank"
              rel="noopener noreferrer"
              href={data.value!}
              key={i}
            >
              <InfoValue />
              <InfoTitle style={{ color: 'white' }}>{data.title}</InfoTitle>
            </ExternalLinkBox>
          ))}
        </SiteDataContainer>
        {/* Grafana links */}
        <SiteDataContainer>
          {grafanaAlertsLinks.map((data, i) => (
            <GrafanaBox
              target="_blank"
              rel="noopener noreferrer"
              href={data.link}
              key={i}
            >
              <InfoValue>
                <GrafanaLogo src={grafanaLogo} alt="" />
              </InfoValue>
              <InfoTitle style={{ color: 'white' }}>{data.title}</InfoTitle>
            </GrafanaBox>
          ))}
        </SiteDataContainer>
        <SiteDataContainer style={{ gridTemplateColumns: '1fr' }}>
          <InfoBox>
            <InfoValue>{site.commentaries}</InfoValue>
            <InfoTitle>Commentaries</InfoTitle>
          </InfoBox>
        </SiteDataContainer>
        {/* Distance time */}
        {site.distance !== null && (
          <SiteDataContainer>
            {distanceData.map((data, i) => (
              <InfoBox key={i}>
                <InfoValue>{data.value}</InfoValue>
                <InfoTitle>{data.title}</InfoTitle>
              </InfoBox>
            ))}
          </SiteDataContainer>
        )}
        {/* Severity */}
        {site.severity !== null && (
          <SiteDataContainer>
            {severityData.map((data, i) => (
              <InfoBox key={i}>
                <InfoValue>{data.value}</InfoValue>
                <InfoTitle>{data.title}</InfoTitle>
              </InfoBox>
            ))}
          </SiteDataContainer>
        )}
      </GridContainer>

      {/* Delete dialog */}
      <Dialog open={showDeleteSite} onClose={() => setShowDeleteSite(false)}>
        <DialogTitle>
          Are you sure you wan&apos;t to delete {site.site_name}?
        </DialogTitle>
        <DialogContent>
          This will delete all the information about this site and cannot be
          recovered
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setShowDeleteSite(false)}>
            Cancel
          </Button>
          <Button color="error" onClick={deleteSiteHandle}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* snackbar */}
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

const GridContainer = styled('div')(() => ({
  width: '100%',
  display: 'block',
  columns: '300px',
  gap: GLOBAL_PAGE_GAP,
}))
const SiteNameEditContainer = styled(GridCard)(() => ({
  background: 'inherit',
  display: 'grid',
  height: 'fit-content',
  gridTemplateRows: 'repeat(2, 1fr)',
  gap: GLOBAL_PAGE_GAP,
  fontWeight: 'bold',
  fontSize: 18,
  marginBottom: GLOBAL_PAGE_GAP,
}))
const SiteNameContainer = styled(GridCard)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}))
const SiteEditReturnContainer = styled(GridCard)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const SiteDataContainer = styled(GridCard)(() => ({
  display: 'grid',
  height: 'fit-content',
  gridTemplateColumns: 'repeat(2, 1fr)',
  marginBottom: GLOBAL_PAGE_GAP,
  breakInside: 'avoid',
  gap: 10,
}))
const InfoBox = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: 100,
  borderRadius: 7,
  padding: 7,
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
}))
const SiteCoordsBackdrop = styled('a')<SiteCoordsBoxProps>(({ img }) => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  height: 130,
  color: 'white',
  backgroundImage: `url(${img})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  borderRadius: 7,
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 'bold',
}))
const SiteCoordsContainer = styled('div')({
  flex: 1,
  padding: 7,
  borderRadius: 7,
  background: `linear-gradient(
    to bottom,
    transparent 40%,
    #00000081 90%)`,
  display: 'flex',
  alignItems: 'flex-end',
  transition: '300ms ease-in-out',
})
const LinkBox = styled(Link)(({ theme }) => ({
  width: '100%',
  minHeight: 100,
  borderRadius: 7,
  padding: 7,
  background: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const ExternalLinkBox = styled('a')(({ theme }) => ({
  width: '100%',
  minHeight: 100,
  borderRadius: 7,
  padding: 7,
  background: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const GrafanaBox = styled('a')(() => ({
  width: '100%',
  minHeight: 100,
  borderRadius: 7,
  padding: 7,
  background: 'rgb(24, 27, 31)',
  fontSize: 14,
  display: 'flex',
  flexDirection: 'column',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const GrafanaLogo = styled('img')({
  width: 25,
  height: 25,
})
const InfoValue = styled('div')(() => ({
  flex: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  textAlign: 'center',
}))
const InfoTitle = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'flex-end',
  fontWeight: 'bold',
  fontSize: 14,
  color: theme.palette.text.secondary,
}))

export default SiteInfoPage
