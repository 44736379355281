import { styled } from '@mui/material/styles'
import { MenuItem, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect, useState } from 'react'
import { Site } from 'nocapp-types'
import { SITES_DB } from '../../../services/api/databases'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import ApiHandler from '../../../services/api/apiHandler'

const UploadSiteDataPage = () => {
  const [sites, setSites] = useState<Site[]>([])
  const [selectedSite, setSelectedSite] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingProps, setLoadingProps] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const getSites = useCallback(async () => {
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    // Get sites
    const data = await apiHandler.getList(
      `SELECT * FROM ${SITES_DB} ORDER BY caban_id`
    )
    setSites(data)
    setLoadingProps(false)
  }, [])

  useEffect(() => {
    getSites()
  }, [getSites])

  const getFileSize = (fl: File) => {
    return `${(fl.size / 1024 / 1024).toFixed(2)} MiB`
  }

  const getFileHandler = (fl: File) => {
    setReady(true)
    setFile(fl)
  }

  const uploadDataHandle = async () => {
    if (!ready) {
      setOpenSnackbar(true)
      setSeverity('warning')
      setMessage('Please upload correct file')
      return
    }
    const apiHandler = new ApiHandler()
    setLoading(true)
    // Prepare data
    const body = new FormData()
    body.append('site', selectedSite)
    body.append('file', file!)
    // Upload data
    await apiHandler.uploadFile(body, '/api/site-reports')
    setLoading(false)
    //
    setOpenSnackbar(true)
    setSeverity('success')
    setMessage('The data have been uploaded')
    //
    getSites()
  }

  return (
    <PageComponent
      title="Upload data"
      loading={loadingProps}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <UploadFileContainer>
        <Title>Upload site data CSV</Title>
        <div style={{ marginBottom: 20 }}>
          <span>Make sure the file don&apos;t have any empty rows</span>
        </div>
        <div style={{ marginBottom: 20 }}>
          <TextField
            select
            fullWidth
            size="small"
            label="Select site"
            value={selectedSite}
            onChange={(e) => setSelectedSite(e.target.value)}
          >
            <MenuItem value="" />
            {sites.map((site, i) => (
              <MenuItem key={i} value={site.caban_id}>
                {site.caban_id} / {site.modem_id} / {site.site_name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 15,
          }}
        >
          <input
            type="file"
            id="fileChooser"
            style={{ display: 'none' }}
            onChange={(e) => getFileHandler(e.target.files![0])}
          />
          <FileChooserButton htmlFor="fileChooser">
            Choose a file
          </FileChooserButton>
        </div>
        {file != null && (
          <FileDescriptionContainer>
            <div>{file.name}</div>
            <div>File size: {getFileSize(file!)}</div>
            <div />
          </FileDescriptionContainer>
        )}
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={uploadDataHandle}
        >
          Submit
        </LoadingButton>
      </UploadFileContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

const UploadFileContainer = styled('div')(({ theme }) => ({
  width: '30%',
  borderRadius: 20,
  padding: 20,
  marginBottom: 20,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
const Title = styled('span')({
  fontSize: 25,
  fontWeight: 'bolder',
})
const FileDescriptionContainer = styled('div')({
  width: '100%',
  padding: 10,
  border: '2px solid #d5d5d5',
  borderRadius: 10,
  marginBottom: 20,
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const FileChooserButton = styled('label')(({ theme }) => ({
  width: 200,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: 15,
  cursor: 'pointer',
}))

export default UploadSiteDataPage
