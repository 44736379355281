import { AppViews } from 'nocapp-types'
import {
  CreateIncidentPage,
  IncidentsMenuPage,
  IncidentTrackerPage,
  ActiveIncidentsListPage,
  EndedIncidentsPage,
} from '../pages'

export const incidentsViews: AppViews[] = [
  {
    path: 'incidents/options',
    page: <IncidentsMenuPage />,
    access: ['noc', 'admin', 'contractor-int', 'oym'],
  },
  {
    path: 'incidents/join',
    page: <ActiveIncidentsListPage />,
    access: ['noc', 'admin', 'contractor-int', 'oym'],
  },
  {
    path: 'incidents/list',
    page: <EndedIncidentsPage />,
    access: ['noc', 'admin', 'contractor-int', 'oym'],
  },
  {
    path: 'incidents/track/:id',
    page: <IncidentTrackerPage />,
    access: ['noc', 'admin', 'contractor-int', 'oym'],
  },
  {
    path: 'incidents/create',
    page: <CreateIncidentPage />,
    access: ['noc', 'admin', 'contractor-int', 'oym'],
  },
]
