import { Button, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { Visit } from 'nocapp-types'
import { VISITS_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import { MONTHS_LIST } from '../../../utils/datesHandle'

interface ActiveVisitsProps {
  visits: (Visit | null)[]
  updateList: Function
}

const ActiveVisits = ({ visits, updateList }: ActiveVisitsProps) => {
  const { nocappUser } = useNocAppAuth()
  const [canEdit, setCanEdit] = useState(true)

  useEffect(() => {
    if (nocappUser!.profile !== 'noc' && nocappUser!.profile !== 'admin') {
      setCanEdit(false)
    }
  }, [visits, nocappUser])

  const getWorkInitialLetters = (work: string) => {
    switch (work) {
      case 'Preventive Maintenance':
        return 'PM'
      case 'Implementation / Dissassembly / Change':
        return 'IDC'
      case 'Survey / Audit':
        return 'SA'
      case 'Corrective Maintenace':
        return 'CM'
      default:
        return ''
    }
  }

  const setEndDateHandler = async (visit: Visit) => {
    const date = new Date()
    const startDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    const apiHandler = new ApiHandler()
    await apiHandler.postItem(
      `UPDATE ${VISITS_DB} SET actual_end_datetime='${startDate}' WHERE id = '${visit.id}'`
    )
    updateList()
  }

  const getMonthDayYearDateWithTime = (string: string) => {
    const date = new Date(string)
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`
  }

  return (
    <Container>
      <Title>Active visits</Title>
      <VisitsContainer>
        {visits!.length === 0 ? (
          <NoneVisits>No active visits</NoneVisits>
        ) : (
          visits.map((visit, i) => (
            <VisitBox key={i}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <VisitItem>{visit!.id}</VisitItem>
                <VisitItem>{visit!.site_id}</VisitItem>
                <VisitItem>{getWorkInitialLetters(visit!.work)}</VisitItem>
                <VisitItem>
                  Started at:{' '}
                  {getMonthDayYearDateWithTime(visit!.actual_start_datetime)}
                </VisitItem>
              </div>
              {canEdit && (
                <div>
                  <Button
                    onClick={() => setEndDateHandler(visit!)}
                    size="small"
                  >
                    Add end
                  </Button>
                </div>
              )}
            </VisitBox>
          ))
        )}
      </VisitsContainer>
    </Container>
  )
}

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})
const Title = styled('div')({
  width: '100%',
  fontSize: 22,
  fontWeight: 'bold',
  marginBottom: 10,
})
const VisitsContainer = styled('div')({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})
const VisitBox = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 15,
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 14,
  borderRadius: 10,
}))
const VisitItem = styled('div')(({ theme }) => ({
  padding: 6,
  borderRadius: 6,
  background: theme.palette.background.paper,
  fontWeight: 'bold',
}))
const NoneVisits = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}))

export default ActiveVisits
