import { LoadingButton } from '@mui/lab'
import { MenuItem, styled, TextField, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { QualityControl, Site } from 'nocapp-types'
import {
  qualityControlConcreteBaseItems,
  qualityControlEarthingSystem,
  qualityControlElectricalInstallation,
  qualityControlEquipment,
  qualityControlSolarStructureItems,
} from '../../../utils/qualityControlFormsData'
import { SITES_DB } from '../../../services/api/databases'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import LastFormsContainer from '../../../components/QualityControl/LastFormsContainer'
import PageComponent from '../../../components/PageComponents/PageComponent'
import GridCard from '../../../components/PageComponents/GridCard'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import ApiHandler from '../../../services/api/apiHandler'
import {
  createNewFormQuery,
  getAllQualityControlQuery,
  getQualityControlFormsByIdQuery,
} from './QualityControlDashboard.queries'

const QualityControlDashboard = () => {
  const { nocappUser } = useNocAppAuth()
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()
  const [formInfo, setFormInfo] = useState<QualityControl | null>(null)
  const [siteForms, setSiteForms] = useState<QualityControl[]>([])
  const [countries, setCountries] = useState<{ country: string }[]>([])
  const [sites, setSites] = useState<Site[]>([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedSite, setSelectedSite] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingNewForm, setLoadingNewForm] = useState(false)
  // Form controllers
  const [concreteBaseForm, setConcreteBaseForm] = useState<{
    completed: boolean
    date: string
  }>({ completed: false, date: '' })
  const [solarStructureForm, setSolarStructureForm] = useState<{
    completed: boolean
    date: string
  }>({ completed: false, date: '' })
  const [earthingSystemForm, setEarthingSystemForm] = useState<{
    completed: boolean
    date: string
  }>({ completed: false, date: '' })
  const [equipmentsForm, setEquipmentsForm] = useState<{
    completed: boolean
    date: string
  }>({ completed: false, date: '' })
  const [electricalInstallationForm, setElectricalInstallationForm] = useState<{
    completed: boolean
    date: string
  }>({ completed: false, date: '' })

  // Form info (length, completed, etc.)
  const formsItems = [
    {
      name: 'Concrete base',
      lenght: qualityControlConcreteBaseItems.length,
      link: `/quality-reports/${
        formInfo?.id ? formInfo!.id : 'new'
      }/concrete-base`,
      completed: concreteBaseForm.completed,
      completedDate: concreteBaseForm.date,
    },
    {
      name: 'Solar structure',
      lenght: qualityControlSolarStructureItems.length,
      link: `/quality-reports/${
        formInfo?.id ? formInfo!.id : 'new'
      }/solar-structure`,
      completed: solarStructureForm.completed,
      completedDate: solarStructureForm.date,
    },
    {
      name: 'Earthing system',
      lenght: qualityControlEarthingSystem.length,
      link: `/quality-reports/${
        formInfo?.id ? formInfo!.id : 'new'
      }/earthing-system`,
      completed: earthingSystemForm.completed,
      completedDate: earthingSystemForm.date,
    },
    {
      name: 'Equipments',
      lenght: qualityControlEquipment.length,
      link: `/quality-reports/${
        formInfo?.id ? formInfo!.id : 'new'
      }/equipments`,
      completed: equipmentsForm.completed,
      completedDate: equipmentsForm.date,
    },
    {
      name: 'Electrical installation',
      lenght: qualityControlElectricalInstallation.length,
      link: `/quality-reports/${
        formInfo?.id ? formInfo!.id : 'new'
      }/electrical-installation`,
      completed: electricalInstallationForm.completed,
      completedDate: electricalInstallationForm.date,
    },
  ]

  const getAllSiteForms = useCallback(async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    //
    const query = getAllQualityControlQuery(selectedSite)
    //
    const sitesForms = await apiHandler.getList(query)
    setSiteForms(sitesForms)
    setLoading(false)
  }, [selectedSite])

  const getFormInfoById = useCallback(async (id: string) => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Get Queries
    const queries = getQualityControlFormsByIdQuery(id)
    // Get data
    const [
      form,
      concreteBase,
      solarStructure,
      earthingSystem,
      equipments,
      electricalInstallation,
    ] = await Promise.all([
      apiHandler.getObject(queries.formInfo),
      apiHandler.getObject(queries.concreteBase),
      apiHandler.getObject(queries.solarStructure),
      apiHandler.getObject(queries.earthingSystem),
      apiHandler.getObject(queries.equipments),
      apiHandler.getObject(queries.electricalInstallation),
    ])
    setFormInfo(form)
    setSelectedSite(form.site_id)
    setSelectedCountry(form.country)
    // Set forms info
    setConcreteBaseForm({
      completed: concreteBase !== '',
      date: concreteBase !== '' ? concreteBase.created_at : '',
    })
    setSolarStructureForm({
      completed: solarStructure !== '',
      date: solarStructure !== '' ? solarStructure.created_at : '',
    })
    setEarthingSystemForm({
      completed: earthingSystem !== '',
      date: earthingSystem !== '' ? earthingSystem.created_at : '',
    })
    setEquipmentsForm({
      completed: equipments !== '',
      date: equipments !== '' ? equipments.created_at : '',
    })
    setElectricalInstallationForm({
      completed: electricalInstallation !== '',
      date:
        electricalInstallation !== '' ? electricalInstallation.created_at : '',
    })
    setLoading(false)
  }, [])

  // Get if form info
  useEffect(() => {
    const formId = searchParams.get('id')
    if (formId) {
      getFormInfoById(formId)
    }
  }, [searchParams, getFormInfoById])

  // Get countries
  useEffect(() => {
    const getCountries = async () => {
      setLoading(true)
      const apiHandler = new ApiHandler()
      const data = await apiHandler.getList(
        `SELECT DISTINCT country FROM ${SITES_DB} ORDER BY country`
      )
      setCountries(data)
      setLoading(false)
    }

    getCountries()
  }, [nocappUser])

  // Get sites
  useEffect(() => {
    const getSites = async () => {
      setLoading(true)
      const apiHandler = new ApiHandler()
      const data = await apiHandler.getList(
        `SELECT * FROM ${SITES_DB} WHERE country = '${selectedCountry}' ORDER BY caban_id`
      )
      setSites(data)
      setLoading(false)
    }

    if (selectedCountry !== '') {
      getSites()
    }
  }, [selectedCountry])

  // Get form info
  useEffect(() => {
    if (selectedSite !== '') {
      getAllSiteForms()
    }
  }, [selectedSite, nocappUser, getAllSiteForms])

  const startForm = async () => {
    setLoadingNewForm(true)
    const date = new Date()
    const dateString = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    const apiHandler = new ApiHandler()
    // Insert query
    const insertQuery = createNewFormQuery(
      selectedSite,
      selectedCountry,
      dateString
    )
    // Insert
    const formId = await apiHandler.insertAndGetId(insertQuery)
    //
    setSearchParams({ id: formId.id.toString() })
    await getAllSiteForms()
    setLoadingNewForm(false)
  }

  return (
    <PageComponent title="Quality control dashboard" loading={loading}>
      <GridContainer>
        <FormItemsContainer>
          {/* eslint-disable-next-line no-nested-ternary */}
          {selectedSite !== '' ? (
            formInfo ? (
              <>
                <FormTitleContainer>
                  <QualityControlTitle>
                    Quality control for {formInfo?.site_id} #{formInfo?.id}
                  </QualityControlTitle>
                  <QualityControlTitle
                    style={{
                      fontSize: 14,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    Last update at: {formInfo!.last_update_timestamp}
                  </QualityControlTitle>
                </FormTitleContainer>
                <FormItemsWrap>
                  {formsItems.map((form, i) =>
                    form.completed ? (
                      <CompletedFormItem to={form.link} key={i}>
                        <div>
                          <FormTitle>{form.name}</FormTitle>
                          <FormLength style={{ color: 'white' }}>
                            {form.lenght} items
                          </FormLength>
                        </div>
                        <FormCompletedDate>
                          Completed at {form.completedDate!}
                        </FormCompletedDate>
                      </CompletedFormItem>
                    ) : (
                      <EmptyFormItem to={form.link} key={i}>
                        <div>
                          <FormTitle>{form.name}</FormTitle>
                          <FormLength>{form.lenght} items</FormLength>
                        </div>
                        <div />
                      </EmptyFormItem>
                    )
                  )}
                </FormItemsWrap>
              </>
            ) : (
              <NoFormSelectedContainer>
                Select a form or create a new one
              </NoFormSelectedContainer>
            )
          ) : (
            <NoFormSelectedContainer>No selected site</NoFormSelectedContainer>
          )}
        </FormItemsContainer>
        <SelectSiteContainer>
          <TextField
            select
            label="Select a country"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <MenuItem value="" />
            {countries.map((country, i) => (
              <MenuItem key={i} value={country.country}>
                {country.country}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Select a site"
            value={selectedSite}
            onChange={(e) => setSelectedSite(e.target.value)}
          >
            <MenuItem value="" />
            {sites.map((site, i) => (
              <MenuItem key={i} value={site.caban_id}>
                {site.caban_id} | {site.site_name}
              </MenuItem>
            ))}
          </TextField>
          <LoadingButton
            loading={loadingNewForm}
            onClick={startForm}
            variant="contained"
          >
            Create new form
          </LoadingButton>
        </SelectSiteContainer>
        <SiteFormsHistoryContainer>
          <LastFormsContainer
            forms={siteForms}
            selectForm={(id: number) => {
              setSearchParams({ id: id.toString() })
            }}
          />
        </SiteFormsHistoryContainer>
      </GridContainer>
    </PageComponent>
  )
}

const GridContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gridTemplateRows: 'repeat(6, 1fr)',
  gap: GLOBAL_PAGE_GAP,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}))
const FormItemsContainer = styled(GridCard)(() => ({
  gridColumnStart: 1,
  gridColumnEnd: 6,
  gridRowStart: 1,
  gridRowEnd: 7,
  display: 'flex',
  flexDirection: 'column',
}))
const FormTitleContainer = styled('div')({
  width: '100%',
  display: 'flex',
  marginBottom: 10,
  alignItems: 'center',
  justifyContent: 'space-between',
})
const QualityControlTitle = styled('span')({
  fontSize: 20,
  fontWeight: 'bold',
})
const FormItemsWrap = styled('div')({
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: GLOBAL_PAGE_GAP,
  alignContent: 'flex-start',
})
const SelectSiteContainer = styled(GridCard)(() => ({
  gridColumnStart: 6,
  gridColumnEnd: 7,
  gridRowStart: 1,
  gridRowEnd: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: GLOBAL_PAGE_GAP,
}))
const SiteFormsHistoryContainer = styled(GridCard)(() => ({
  gridColumnStart: 6,
  gridColumnEnd: 7,
  gridRowStart: 2,
  gridRowEnd: 7,
  display: 'flex',
}))
const EmptyFormItem = styled(Link)(({ theme }) => ({
  width: 350,
  height: 150,
  background: theme.palette.background.default,
  borderRadius: 15,
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: '200ms ease-in-out',
  boxShadow: '0px 5px 30px rgba(32, 52, 89, 0.08)',
  ':hover': {
    marginTop: -5,
    boxShadow: '0px 5px 30px rgba(32, 52, 89, 0.11)',
  },
}))
const CompletedFormItem = styled(EmptyFormItem)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
}))
const FormTitle = styled('div')({
  fontSize: 22,
  fontWeight: 'bold',
})
const FormLength = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}))
const FormCompletedDate = styled('div')(() => ({
  fontSize: 14,
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
}))
const NoFormSelectedContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}))

export default QualityControlDashboard
