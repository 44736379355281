import { Dialog, DialogContent, DialogTitle, styled } from '@mui/material'
import { LlaReportKpis } from 'nocapp-types'

interface BasicReportProps {
  open: boolean
  setOpen: Function
  kpi: LlaReportKpis
}

const LlaKPI = ({ open, setOpen, kpi }: BasicReportProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 22 }}>
        Site KPI
      </DialogTitle>
      <DialogContent>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <CellStyle>Load Average [kW]</CellStyle>
              <CellStyle>{kpi.loadAvg.toFixed(3)}</CellStyle>
            </tr>
            <tr>
              <CellStyle>CO2 Eq [kgCO2 / kWh]</CellStyle>
              <CellStyle>{kpi.CO2Eq.toFixed(3)}</CellStyle>
            </tr>
            <tr>
              <CellStyle>Expected Grid Usage [%]</CellStyle>
              <CellStyle>{(kpi.expectedGridUsage * 100).toFixed(3)}%</CellStyle>
            </tr>
            <tr>
              <CellStyle>Grid Usage Reported [%]</CellStyle>
              <CellStyle>{(kpi.gridUsageReported * 100).toFixed(3)}%</CellStyle>
            </tr>
            <tr>
              <CellStyle>Grid Availability [%]</CellStyle>
              <CellStyle>{(kpi.gridAvailability * 100).toFixed(3)}%</CellStyle>
            </tr>
            <tr>
              <CellStyle>Battery Temp [°C]</CellStyle>
              <CellStyle>{kpi.batteryTemp.toFixed(3)}</CellStyle>
            </tr>
            <tr>
              <CellStyle>Ambient Temp [°C]</CellStyle>
              <CellStyle>{kpi.ambTemp.toFixed(3)}</CellStyle>
            </tr>
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  )
}

const CellStyle = styled('th')(({ theme }) => ({
  padding: 5,
  borderBottom: `1px solid ${theme.palette.background.default}`,
}))

export default LlaKPI
