import { SITES_DB, VISITS_DB } from '../../../services/api/databases'

export const getVisitsQuery = () => {
  const date = new Date()
  const currentDate = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`

  return {
    visits: `SELECT
      *
    FROM ${VISITS_DB}
    INNER JOIN
      ${SITES_DB}
    ON
      ${VISITS_DB}.site_id = ${SITES_DB}.caban_id`,
    //
    todayVisits: `SELECT
      *
    FROM ${VISITS_DB}
    LEFT JOIN
      ${SITES_DB}
    ON
      ${VISITS_DB}.site_id = ${SITES_DB}.caban_id
    WHERE
      DATE(${VISITS_DB}.start_datetime) = '${currentDate}'`,
    //
    activeVisits: `SELECT
      *
    FROM ${VISITS_DB}
    INNER JOIN
      ${SITES_DB}
    ON
      ${VISITS_DB}.site_id = ${SITES_DB}.caban_id
    WHERE
      ${VISITS_DB}.actual_start_datetime IS NOT NULL AND ${VISITS_DB}.actual_end_datetime IS NULL`,
  }
}

export const getVisitsByDateQuery = ({
  start,
  end,
}: {
  start: string
  end: string
}) =>
  `SELECT * FROM ${VISITS_DB} WHERE start_datetime >= '${start}' AND start_datetime <= '${end}'`
