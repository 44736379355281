import {
  QUALITY_CONTROL_CONCRETE_BASE_DB,
  QUALITY_CONTROL_DB,
  QUALITY_CONTROL_EARTHING_SYSTEM_DB,
  QUALITY_CONTROL_ELECTRICAL_INSTALLATION_DB,
  QUALITY_CONTROL_EQUIPMENT_DB,
  QUALITY_CONTROL_SOLAR_STRUCTURE_DB,
} from '../../../services/api/databases'

export const getAllQualityControlQuery = (site: string) =>
  `SELECT * FROM ${QUALITY_CONTROL_DB} WHERE site_id = '${site}' ORDER BY start_timestamp DESC`

export const getQualityControlFormsByIdQuery = (id: string) => {
  return {
    formInfo: `SELECT * FROM ${QUALITY_CONTROL_DB} WHERE id = '${id}'`,
    concreteBase: `SELECT * FROM ${QUALITY_CONTROL_CONCRETE_BASE_DB} WHERE form_id = '${id}'`,
    solarStructure: `SELECT * FROM ${QUALITY_CONTROL_SOLAR_STRUCTURE_DB} WHERE form_id = '${id}'`,
    earthingSystem: `SELECT * FROM ${QUALITY_CONTROL_EARTHING_SYSTEM_DB} WHERE form_id = '${id}'`,
    equipments: `SELECT * FROM ${QUALITY_CONTROL_EQUIPMENT_DB} WHERE form_id = '${id}'`,
    electricalInstallation: `SELECT * FROM ${QUALITY_CONTROL_ELECTRICAL_INSTALLATION_DB} WHERE form_id = '${id}'`,
  }
}

export const createNewFormQuery = (
  site: string,
  country: string,
  date: string
) =>
  `INSERT INTO ${QUALITY_CONTROL_DB}(
    site_id,
    country,
    start_timestamp
  ) VALUES (
    '${site}',
    '${country}',
    '${date}'
  )`
