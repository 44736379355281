import { styled, TextField, useTheme } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import { LoadingButton } from '@mui/lab'
import { Incident, IncidentMessageLog } from 'nocapp-types'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import { INCIDENTS_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'
import { MONTHS_LIST } from '../../../utils/datesHandle'

interface MessagesContainerProps {
  incident: Incident
}

const MessagesContainer = ({ incident }: MessagesContainerProps) => {
  const { nocappUser } = useNocAppAuth()
  const theme = useTheme()
  const [messages, setMessages] = useState<IncidentMessageLog[]>([])
  const [messageText, setMessageText] = useState('')
  const [loading, setLoading] = useState(false)
  const messageInput = useRef<HTMLInputElement>(null)
  const messagesBox = useRef<HTMLDivElement>(null)

  const scrollDownMessagesBox = useCallback(() => {
    messagesBox.current!.scrollTop = messagesBox.current!.scrollHeight
  }, [messagesBox])

  useEffect(() => {
    scrollDownMessagesBox()
  }, [scrollDownMessagesBox])

  useEffect(() => {
    setMessages(incident.messages_log)
    messageInput.current?.focus()
  }, [incident, messageInput])

  const getMonthDayYearDateWithTime = (string: string) => {
    const date = new Date(string)
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${date.getHours()}:${
      (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    }`
  }

  const addNewMessage = async () => {
    setLoading(true)
    if (messageText === '') {
      setLoading(false)
      return
    }
    // Format message
    const date = new Date()
    const message: IncidentMessageLog = {
      message: messageText,
      timestamp: `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
      fromName: nocappUser!.name,
      user: nocappUser!.username,
    }
    messages.push(message)
    //
    const apiHandler = new ApiHandler()
    try {
      await apiHandler.postItem(
        `UPDATE ${INCIDENTS_DB} SET messages_log = '${JSON.stringify(
          messages
        )}' WHERE id = '${incident.id}'`
      )
      setMessages(messages)
      setMessageText('')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
    setLoading(false)
    scrollDownMessagesBox()
  }

  // @ts-ignore
  const messageKeyDownHandler = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      addNewMessage()
    }
  }

  return (
    <Container>
      <MessagesBox ref={messagesBox}>
        {messages.map((message, i) => (
          <MessageBoxContainer
            key={i}
            style={{
              alignItems:
                message.user === nocappUser!.username
                  ? 'flex-end'
                  : 'flex-start',
            }}
          >
            <UserMessage
              style={{
                backgroundColor:
                  message.user === nocappUser!.username
                    ? theme.palette.primary.light
                    : theme.palette.background.default,
                color:
                  message.user === nocappUser!.username
                    ? 'white'
                    : theme.palette.text.primary,
              }}
            >
              {message.message}
            </UserMessage>
            <MessageInfoContainer>
              {message.user !== nocappUser!.username && (
                <>
                  <span>{message.fromName}</span>
                  <MessageInfoDivider />
                </>
              )}
              <span>{getMonthDayYearDateWithTime(message.timestamp)}</span>
            </MessageInfoContainer>
          </MessageBoxContainer>
        ))}
      </MessagesBox>
      <InputContainer>
        <TextField
          fullWidth
          ref={messageInput}
          size="small"
          disabled={!!incident.end_datetime}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyDown={(e) => messageKeyDownHandler(e)}
          variant="filled"
          label="Write your message here"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: 10,
            },
          }}
        />
        <LoadingButton
          loading={loading}
          disabled={!!incident.end_datetime}
          variant="contained"
          size="large"
          onClick={addNewMessage}
          startIcon={<SendRoundedIcon />}
          loadingPosition="end"
          style={{
            marginLeft: 15,
          }}
        >
          Send
        </LoadingButton>
      </InputContainer>
    </Container>
  )
}

const Container = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const InputContainer = styled('div')(() => ({
  width: '100%',
  height: 70,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const MessagesBox = styled('div')(() => ({
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
}))
const MessageBoxContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 'bold',
  marginTop: 5,
}))
const UserMessage = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 600,
  padding: 15,
  borderRadius: 20,
  overflowWrap: 'break-word',
  [theme.breakpoints.down(926)]: {
    minWidth: 250,
    maxWidth: 400,
  },
}))
const MessageInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  fontSize: 13,
}))
const MessageInfoDivider = styled('div')(({ theme }) => ({
  width: 7,
  height: 7,
  borderRadius: 100,
  background: theme.palette.text.secondary,
  marginLeft: 4,
  marginRight: 4,
}))

export default MessagesContainer
