import {
  Button,
  CircularProgress,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LlaReportKpis, SiteReport, Site } from 'nocapp-types'
import PageComponent from '../../../components/PageComponents/PageComponent'
import ReportsTopBar from '../../../components/Reports/ReportsTopBar'
import LlaKPI from '../../../components/Reports/LlaKPI'
import ApiHandler from '../../../services/api/apiHandler'
import {
  acEnergy,
  batteryEnergy,
  CO2Emission,
  excessEnergy,
  loadPowerComsuption,
  powerChanges,
  solarEnergyFunction,
} from '../../../utils/reportsFunctions'
import {
  dataForLlaReportExport,
  llarReportKpis,
} from './LlaReportsPage.functions'
import {
  getReportInfoFromSiteQuery,
  getAvailableSitesInfoForReportQueries,
} from '../reportPagesQueries'

const PADDING = 20
const PRECISION_LENGHT = 3
const TABLE_TITLES = [
  'Date',
  'Hour',
  'Rectifier Voltage [V]',
  'Rectifier Current [A]',
  'MPPT Voltage [V]',
  'ESS Voltage [V]',
  'ESS Current [A]',
  'MPPT Output Current [A]',
  'Load Current [A]',
  'Grid Availability',
  'Battery SOC [%]',
  'Battery Temperature [°C]',
  'System Temperature [°C]',
  'Battery Energy Throughput [kWh]',
  'Grid Energy Provided [kWh]',
  'Load Energy Consumption [kWh]',
  'Solar Energy Generation [kWh]',
  'Excess Energy for Recharge [kWh]',
  'Contracted Load [kW]',
  'Power Changes',
  'CO2eq Emissions [gCO2/kWh]',
]
const TITLES_FOR_REPORT = [
  'Date',
  'Hour',
  'MPPT Voltage [V]',
  'ESS Voltage [V]',
  'ESS Current [A]',
  'MPPT Output Current [A]',
  'Load Current [A]',
  'Grid Availability',
  'Battery SOC [%]',
  'Battery Temperature [°C]',
  'System Temperature [°C]',
  'Battery Energy Throughput [kWh]',
  'Grid Energy Provided [kWh]',
  'Load Energy Consumption [kWh]',
  'Solar Energy Generation [kWh]',
  'Excess Energy for Recharge [kWh]',
  'Contracted Load [kW]',
  'Power Changes',
  'CO2eq Emissions [gCO2/kWh]',
]

const LlaReportsPage = () => {
  const [sites, setSites] = useState<Site[]>([])
  const [availableSites, setAvailableSites] = useState<{ site_id: string }[]>(
    []
  )
  const [selectedSite, setSelectedSite] = useState('')
  const [siteData, setSiteData] = useState<Site | null>(null)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [loadingData, setLoadingData] = useState(false)
  const [reportData, setReportData] = useState<SiteReport[]>([])
  const [kpi, setKpi] = useState<LlaReportKpis | null>(null)
  const [showKpi, setShowKpi] = useState(false)

  const getSites = useCallback(async () => {
    setLoadingData(true)
    const apiHandler = new ApiHandler()
    // Get queries
    const queries = getAvailableSitesInfoForReportQueries()
    // Get
    const [sitesData, available] = await Promise.all([
      apiHandler.getList(queries.sitesInfo),
      apiHandler.getList(queries.availableSites),
    ])
    //
    setSites(sitesData)
    setAvailableSites(available)
    setLoadingData(false)
  }, [])

  useEffect(() => {
    getSites()
  }, [getSites])

  const getSiteReport = useCallback(async () => {
    if (selectedSite === '' || startDate === '' || endDate === '') {
      return
    }
    setLoadingData(true)
    const apiHandler = new ApiHandler()
    // Queries
    const query = getReportInfoFromSiteQuery(selectedSite, startDate, endDate)
    //
    const data = await apiHandler.getList(query)
    setReportData(data)
    //
    setLoadingData(false)
  }, [selectedSite, startDate, endDate])

  useEffect(() => {
    getSiteReport()
  }, [getSiteReport])

  const exportKPI = () => {
    const currentDate = new Date()
    const dateLog = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
    // Get KPIs
    const kpis = llarReportKpis(reportData, siteData!)
    //
    const csv = `Load Average [kW],${kpis.loadAvg.toFixed(
      PRECISION_LENGHT
    )},\nCO2 Eq [kgCO2 / kWh],${kpis.CO2Eq.toFixed(
      PRECISION_LENGHT
    )}\nExpected Grid Usage [%],${
      +kpis.expectedGridUsage! * 100
    }%\nGrid Usage Reported [%],${
      +kpis.gridUsageReported.toFixed(PRECISION_LENGHT) * 100
    }%\nGrid Availability [%],${
      +kpis.gridAvailability.toFixed(PRECISION_LENGHT) * 100
    }%\nBattery Temp [°C],${kpis.batteryTemp.toFixed(
      PRECISION_LENGHT
    )}\nAmbient Temp [°C],${kpis.ambTemp.toFixed(PRECISION_LENGHT)}`
    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`
    hiddenElement.target = '_blank'
    hiddenElement.download = `${siteData?.caban_id} kpi ${dateLog}.csv`
    hiddenElement.click()
  }

  const showKpiHandler = () => {
    // Get kpis
    const kpis = llarReportKpis(reportData, siteData!)
    //
    const kpiData: LlaReportKpis = {
      loadAvg: kpis.loadAvg,
      CO2Eq: kpis.CO2Eq,
      expectedGridUsage: kpis.expectedGridUsage ? +kpis.expectedGridUsage : 0,
      gridUsageReported: kpis.gridUsageReported,
      gridAvailability: kpis.gridAvailability,
      batteryTemp: kpis.batteryTemp,
      ambTemp: kpis.ambTemp,
    }
    setKpi(kpiData)
    setShowKpi(true)
  }

  const exportData = () => {
    const currentDate = new Date()
    const dateLog = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
    let csv = `${TITLES_FOR_REPORT.join(',')}\n`
    //
    reportData.map((data) => {
      const values = dataForLlaReportExport(data, siteData!, PRECISION_LENGHT)
      csv += `${values.join(',')}\n`
      return data
    })
    //
    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`
    hiddenElement.target = '_blank'
    hiddenElement.download = `${siteData?.caban_id} report ${dateLog}.csv`
    hiddenElement.click()
  }

  return (
    <PageComponent
      scrollable
      title="Reports"
      loading={loadingData}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TopBarContainer>
        <ReportsTopBar
          title="LLA reports"
          rightRender={
            <TextField
              select
              variant="filled"
              value={selectedSite}
              onChange={(e) => {
                const cabanId = e.target.value
                const siteInfo = sites.find((site) => site.caban_id === cabanId)
                setSelectedSite(cabanId)
                setSiteData(siteInfo!)
              }}
              size="small"
              label="Select a site"
              style={{ width: 150 }}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: 10,
                },
              }}
            >
              <MenuItem value="" />
              {availableSites.map((site, i) => (
                <MenuItem value={site.site_id} key={i}>
                  {site.site_id}
                </MenuItem>
              ))}
            </TextField>
          }
        />
      </TopBarContainer>
      <PageBody>
        <DataContainer>
          <DatesExportContainer>
            <DatesContainer>
              <span style={{ marginRight: 5 }}>From</span>
              <TextField
                style={{ marginRight: 10 }}
                size="small"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                type="datetime-local"
              />
              <span style={{ marginRight: 5 }}>To</span>
              <TextField
                size="small"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                type="datetime-local"
              />
              {loadingData && (
                <CircularProgress style={{ marginLeft: 10 }} size={25} />
              )}
            </DatesContainer>
            {siteData && startDate !== '' && endDate !== '' && (
              <div>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`${siteData?.caban_id}/charts?from=${startDate}&to=${endDate}`}
                >
                  <Button
                    variant="outlined"
                    style={{ marginRight: 5, color: 'black' }}
                  >
                    Show charts
                  </Button>
                </Link>
                <Button
                  variant="outlined"
                  style={{ marginRight: 5, color: 'black' }}
                  onClick={showKpiHandler}
                >
                  Show site KPI
                </Button>
                <Button style={{ marginRight: 5 }} onClick={exportKPI}>
                  Export KPI
                </Button>
                <Button onClick={exportData}>Export data</Button>
              </div>
            )}
          </DatesExportContainer>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {TABLE_TITLES.map((title, i) => (
                    <TableTitles key={i}>{title}</TableTitles>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.length !== 0 &&
                  reportData.map((data, i) => (
                    <TableRow hover key={i}>
                      <TableCell>{data.date}</TableCell>
                      <TableCell>{data.time}</TableCell>
                      <TableCell>
                        {data.tel_status_rect_measured_voltage}
                      </TableCell>
                      <TableCell>
                        {data.tel_status_rect_measured_current}
                      </TableCell>
                      <TableCell>{data.core_mppt_output_voltage}</TableCell>
                      <TableCell>{data.core_system_link_voltage}</TableCell>
                      <TableCell>{data.core_ess_current}</TableCell>
                      <TableCell>{data.core_mppt_output_current}</TableCell>
                      <TableCell>{data.metric_load_current_estimate}</TableCell>
                      <TableCell>
                        {data.info_grid_active_status * 100}%
                      </TableCell>
                      <TableCell>{data.core_ess_min_soc}</TableCell>
                      <TableCell>{data.core_ess_brick_max_temp}</TableCell>
                      <TableCell>{data.core_system_ambient_temp}</TableCell>
                      <TableCell>
                        {(
                          batteryEnergy(
                            data.core_system_link_voltage,
                            data.core_ess_current
                          ) / 1000
                        ).toFixed(PRECISION_LENGHT)}
                      </TableCell>
                      <TableCell>
                        {(
                          acEnergy(
                            data.tel_status_rect_measured_voltage,
                            data.tel_status_rect_measured_current
                          ) / 1000
                        ).toFixed(PRECISION_LENGHT)}
                      </TableCell>
                      <TableCell>
                        {(
                          loadPowerComsuption(
                            data.core_system_link_voltage,
                            data.metric_load_current_estimate
                          ) / 1000
                        ).toFixed(PRECISION_LENGHT)}
                      </TableCell>
                      <TableCell>
                        {solarEnergyFunction(
                          data.core_mppt_output_voltage,
                          data.core_mppt_output_current
                        ).toFixed(PRECISION_LENGHT)}
                      </TableCell>
                      <TableCell>
                        {excessEnergy(
                          loadPowerComsuption(
                            data.core_system_link_voltage,
                            data.metric_load_current_estimate
                          ),
                          solarEnergyFunction(
                            data.core_mppt_output_voltage,
                            data.core_mppt_output_current
                          )
                        ).toFixed(PRECISION_LENGHT)}
                      </TableCell>
                      <TableCell>{siteData?.contracted_load}</TableCell>
                      <TableCell>
                        {(
                          powerChanges(
                            loadPowerComsuption(
                              data.core_system_link_voltage,
                              data.metric_load_current_estimate
                            ),
                            siteData?.contracted_load
                              ? siteData.contracted_load
                              : 1
                          ) * 100
                        ).toFixed(2)}
                        %
                      </TableCell>
                      <TableCell>
                        {CO2Emission(
                          solarEnergyFunction(
                            data.core_mppt_output_voltage,
                            data.core_mppt_output_current
                          )
                        ).toFixed(PRECISION_LENGHT)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DataContainer>
      </PageBody>
      {kpi && <LlaKPI open={showKpi} setOpen={setShowKpi} kpi={kpi} />}
    </PageComponent>
  )
}

const TopBarContainer = styled('div')(() => ({
  width: '100%',
  height: 80,
  display: 'flex',
}))
const PageBody = styled('div')({
  width: '100%',
  height: `calc(100vh - 80px - ${PADDING}px)`,
  display: 'flex',
  flexDirection: 'column',
})
const DataContainer = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 15,
  padding: 10,
}))
const DatesExportContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const DatesContainer = styled('div')(() => ({
  display: 'flex',
  fontWeight: 'bold',
  alignItems: 'center',
}))
const TableTitles = styled(TableCell)({
  fontWeight: 'bold',
})

export default LlaReportsPage
