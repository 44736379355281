import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormEvent, useEffect, useRef, useState } from 'react'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import {
  FormButton,
  FormContainer,
  FormSection,
  FormSectionContainer,
  FormSectionContainerRow,
  FormSectionContainerTitle,
} from '../../../components/FormComponents'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import { Android12Switch } from '../../../components/PageComponents/SwitchComponent'
import { SITES_DB } from '../../../services/api/databases'
import ApiHandler from '../../../services/api/apiHandler'
import {
  availableCustomersAndSupervisors,
  insertNewSiteQueries,
} from './CreateSitePage.queries'

const CreateSitePage = () => {
  const [loadingProps, setLoadingProps] = useState(true)
  const [country, setCountry] = useState<{ country: string }[]>([])
  const [customer, setCustomer] = useState<{ customer: string }[]>([])
  const [supervisor, setSupervisor] = useState<{ supervisor: string }[]>([])
  const [loading, setLoading] = useState(false)
  const siteFormRef = useRef<HTMLFormElement>(null)
  // FormControllers
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [selectedSupervisor, setSelectedSupervisor] = useState('')
  const [activeSite, setActiveSite] = useState(true)
  const [genset, setGenset] = useState(false)
  const [grid, setGrid] = useState(false)
  const [addSiteDistances, setAddSiteDistances] = useState(false)
  const [addSiteSeverity, setSiteSeverity] = useState(false)
  const [newCustomer, setNewCustomer] = useState(false)
  const [newSupervisor, setNewSupervisor] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const getData = async () => {
      setLoadingProps(true)
      const apiHandler = new ApiHandler()
      //
      const countries = await apiHandler.getList(
        `SELECT DISTINCT country FROM ${SITES_DB} ORDER BY country`
      )
      //
      setCountry(countries)
      setLoadingProps(false)
    }

    getData()
  }, [])

  const getCustomersAndSupervisors = async (value: string) => {
    setLoadingProps(true)
    const apiHandler = new ApiHandler()
    // Queries
    const queries = availableCustomersAndSupervisors(value)
    const [customers, supervisors] = await Promise.all([
      apiHandler.getList(queries.customers),
      apiHandler.getList(queries.supervisors),
    ])
    //
    setCustomer(customers)
    setSupervisor(supervisors)
    setSelectedCountry(value)
    setLoadingProps(false)
  }

  const createSite = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Transform data
    const body = apiHandler.transformFormDataToJson(event.currentTarget)
    // Queries
    const queries = insertNewSiteQueries(body, activeSite, genset, grid)
    // Insert
    try {
      await Promise.all([
        apiHandler.postItem(queries.dates),
        apiHandler.postItem(queries.sites),
        apiHandler.postItem(queries.sitesInfo),
        apiHandler.postItem(queries.location),
        apiHandler.postItem(queries.devicesInfo),
        apiHandler.postItem(queries.devicesIds),
        apiHandler.postItem(queries.links),
      ])
      if (addSiteDistances) {
        await apiHandler.postItem(queries.distances)
      }
      if (addSiteSeverity) {
        await apiHandler.postItem(queries.severity)
      }
      setSeverity('success')
      setMessage('New site added')
      setOpenSnackbar(true)
      siteFormRef.current?.reset()
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageComponent
      title="Add new site"
      loading={loadingProps}
      scrollable
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormContainer onSubmit={createSite}>
        {/* Site */}
        <FormSection
          useBackButton
          title="Add new site"
          sections={[
            [
              <TextField
                label="Caban ID"
                required
                fullWidth
                placeholder="Obligatory field"
                name="caban_id"
              />,
              <TextField
                label="Modem ID"
                fullWidth
                placeholder="****"
                type="number"
                name="modem_id"
              />,
            ],
            [
              <TextField
                label="Site name"
                required
                fullWidth
                name="site_name"
              />,
              <TextField
                required
                fullWidth
                select
                value={selectedCountry}
                onChange={(e) => getCustomersAndSupervisors(e.target.value)}
                label="Country"
                name="country"
              >
                <MenuItem value="" />
                {country.map((value, i) => (
                  <MenuItem key={i} value={value.country}>
                    {value.country}
                  </MenuItem>
                ))}
              </TextField>,
            ],
            [
              <ActiveRow>
                <FormControlLabel
                  control={
                    <Android12Switch
                      defaultChecked
                      value={activeSite}
                      onChange={() => setActiveSite(!activeSite)}
                    />
                  }
                  label="Active site"
                />
              </ActiveRow>,
            ],
          ]}
        />
        {/* Site info */}
        <FormSection
          title="Site info."
          sections={[
            [
              // New customer inputs
              newCustomer ? (
                <TextField
                  fullWidth
                  label="Customer"
                  name="customer"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewCustomer(!newCustomer)}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Customer"
                  name="customer"
                  required
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                  value={selectedCustomer}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewCustomer(!newCustomer)}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {customer.map((value, i) => (
                    <MenuItem key={i} value={value.customer}>
                      {value.customer}
                    </MenuItem>
                  ))}
                </TextField>
              ),
              //
              <TextField label="Customer ID" fullWidth name="customer_id" />,
            ],
            [
              <TextField label="Owner" fullWidth name="owner" />,
              <TextField label="Owner ID" fullWidth name="owner_id" />,
            ],
            [
              <TextField
                label="Contracted load"
                fullWidth
                placeholder=""
                name="contracted_load"
              />,
              <TextField label="Site type" fullWidth name="type" />,
            ],
            [
              // New customer inputs
              newSupervisor ? (
                <TextField
                  fullWidth
                  label="Supervisor"
                  name="supervisor"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewSupervisor(!newSupervisor)}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  required
                  onChange={(e) => setSelectedSupervisor(e.target.value)}
                  value={selectedSupervisor}
                  label="Supervisor"
                  name="supervisor"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewSupervisor(!newSupervisor)}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {supervisor.map((sup, i) => (
                    <MenuItem key={i} value={sup.supervisor}>
                      {sup.supervisor}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            ],
          ]}
        />
        {/* Location */}
        <FormSection
          title="Location"
          sections={[
            [
              <TextField label="Latitude" fullWidth name="latitude" />,
              <TextField label="Longitude" fullWidth name="longitude" />,
            ],
            [<TextField label="Zone" fullWidth name="zone" />],
            [
              <TextField
                label="Address"
                fullWidth
                multiline
                rows={4}
                name="address"
              />,
            ],
          ]}
        />
        {/* Devices info */}
        <FormSection
          title="Devices info."
          sections={[
            [
              <TextField
                label="Solar panels"
                fullWidth
                type="number"
                name="solar_panels"
              />,
              <TextField
                label="Solar structures"
                fullWidth
                type="number"
                name="solar_structures"
              />,
              <TextField
                label="Grid percentage"
                placeholder="0 - 1"
                fullWidth
                name="grid_percentage"
              />,
            ],
            [
              <TextField label="Packs" fullWidth type="number" name="packs" />,
              <TextField label="MPPT" fullWidth type="number" name="mppts" />,
              <TextField
                label="Rectifiers"
                fullWidth
                type="number"
                name="rectifiers"
              />,
            ],
            [
              <FormControlLabel
                control={
                  <Android12Switch
                    value={grid}
                    onChange={() => setGrid(!grid)}
                  />
                }
                label="Grid"
              />,
              <FormControlLabel
                control={
                  <Android12Switch
                    value={genset}
                    onChange={() => setGenset(!genset)}
                  />
                }
                label="Genset"
              />,
            ],
          ]}
        />
        {/* Devices Ids */}
        <FormSection
          title="Devices IDs"
          sections={[
            [
              <TextField label="Cabinet SN" fullWidth name="cabinet_id" />,
              <TextField
                label="Configuration"
                fullWidth
                name="configuration"
              />,
            ],
            [
              <TextField label="Cabinet type" fullWidth name="cabinet_type" />,
              <TextField
                label="Battery model"
                fullWidth
                name="battery_model"
              />,
            ],
          ]}
        />
        {/* Dates */}
        <FormSection
          title="Dates"
          sections={[
            [
              <TextField
                label="Turn on date"
                fullWidth
                focused
                type="date"
                name="turn_on_date"
              />,
              <TextField
                label="Alerts activation"
                fullWidth
                focused
                type="date"
                name="alerts_activation_date"
              />,
            ],
            [
              <TextField
                label="Migration date"
                fullWidth
                focused
                type="date"
                name="migration_date"
              />,
              <TextField
                label="Commencement date"
                fullWidth
                focused
                type="date"
                name="commencement_date"
              />,
            ],
          ]}
        />
        {/* Links */}
        <FormSection
          title="Links"
          sections={[
            [
              <TextField
                label="Site information link"
                fullWidth
                name="report_link"
              />,
            ],
          ]}
        />
        {/* Commentaries */}
        <FormSection
          title="Commentaries"
          sections={[
            [
              <TextField
                label="Commentaries"
                placeholder="Place here your comments"
                multiline
                rows={5}
                fullWidth
                name="commentaries"
              />,
            ],
          ]}
        />
        {/* Site distances */}
        <FormSectionContainer>
          <FormSectionContainerTitle>
            <span>Site Distances</span>
            <FormControlLabel
              control={
                <Android12Switch
                  value={addSiteDistances}
                  onChange={() => setAddSiteDistances(!addSiteDistances)}
                />
              }
              label="Add site distances"
            />
          </FormSectionContainerTitle>
          {addSiteDistances && (
            <FormSectionContainerRow>
              <TextField
                label="Distance"
                placeholder="Distance in km"
                fullWidth
                name="distance"
              />
              <TextField
                label="Travel time"
                fullWidth
                placeholder="Travel time in H:m"
                name="travel_time"
              />
            </FormSectionContainerRow>
          )}
        </FormSectionContainer>
        {/* Severity */}
        <FormSectionContainer>
          <FormSectionContainerTitle>
            <span>Site Severity</span>
            <FormControlLabel
              control={
                <Android12Switch
                  value={addSiteSeverity}
                  onChange={() => setSiteSeverity(!addSiteSeverity)}
                />
              }
              label="Add site severity"
            />
          </FormSectionContainerTitle>
          {addSiteSeverity && (
            <>
              <FormSectionContainerRow>
                <TextField label="Severity" fullWidth name="severity" />
              </FormSectionContainerRow>
              <FormSectionContainerRow>
                <TextField
                  label="Repair time with total loss of service"
                  fullWidth
                  name="max_repair_time_total_loss"
                />
                <TextField
                  label="Repair time with partial loss of service"
                  fullWidth
                  name="max_repair_time_partial_loss"
                />
                <TextField
                  label="Repair time without service affection"
                  fullWidth
                  name="max_repair_time_without_service_affection"
                />
              </FormSectionContainerRow>
            </>
          )}
        </FormSectionContainer>
        {/* Send */}
        <FormButton loading={loading} title="Add new site" />
      </FormContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

const ActiveRow = styled('div')({
  width: '100%',
  display: 'flex',
  marginTop: 8,
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontWeight: 'bold',
})

export default CreateSitePage
